import _ from "lodash";
import BaseViewModel from "../../../infraestructure/BaseViewModel";
import Course from "../models/Course";
import CourseSection from "../models/CourseSection";
import CourseSubject from "../models/CourseSubject";
import CourseSubjectActivity from "../models/CourseSubjectActivity";
import CourseSubjectActivityAnswer from "../models/CourseSubjectActivityAnswer";
import CourseSubjectTestActivity from "../models/CourseSubjectTestActivity";
import CourseSubjectTestQuestion from "../models/CourseSubjectTestQuestion";
import CourseSubjectTestQuestionAnswer from "../models/CourseSubjectTestQuestionAnswer";
import Instructor from "../../instructors/models/Instructor";

export default class CourseDetailViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  static HOUR_IN_MINUTES = 60;
  static ACTIVITY_TYPE_LECTURE = 1;
  static ACTIVITY_TYPE_TEST = 2;

  onSearchDetail(courseId) {
    this.api.courses
      .findDetails(courseId)
      .then((response) => {
        let course = new Course(response.data.data);
        course.instructors = this.mapInstructors(course.instructors);
        course.courseSections = this.mapCourseSections(course.courseSections);
        this.view.onSearchDetailResponse(course);
        return this.api.courseSubjectActivityTypes.find();
      })
      .then((response) => {
        this.view.onSearchCourseSubjecActivityTypesResponse(response.data);
        if (this.view.courseDetails.courseUserSocial) {
          this.view.isFavorite = this.view.courseDetails.courseUserSocial.isFavorite;
          this.view.isLiked = this.view.courseDetails.courseUserSocial.liked;
          this.view.isDisliked = this.view.courseDetails.courseUserSocial.disliked;
          this.view.totalLiked = this.view.courseDetails.totalLiked;
          this.view.totalDisliked = this.view.courseDetails.totalDisliked;
        }
        return this.api.courses.doViewed(this.view.courseDetails.id);
      })
      .then((response) => {
        this.view.onDoViewedResponse(response.data);
        return this.api.careers.getByStudentUniversity()
      })
      .then((response) => {
        this.view.careers = response.data.data
        return this.api.courses.getCurrentCourse(courseId);
      })
      .then((response) => {
        this.view.$set(this.view.courseDetails, "specialty", response.data.careerId);
        this.view.$set(this.view.courseDetails, "currentSpecialty", response.data.careerId);
        this.view.$set(this.view.courseDetails, "specialtySelected", response.data.careerId);
        setTimeout(() => {
          this.view.fullLoader = false;
        }, 200);
      })
      .catch(this.view.onSearchDetailErrorResponse);
  }

  onBindProfile() {
    this.view.$root.$on("update-session", (data) => {
      this.view.isSubscribed = data.user.isSubscribed;
    });
  }

  doFavorite(courseId) {
    this.api.courses
      .doFavorite(courseId)
      .then((response) => this.view.onDoFavoriteResponse(response.data))
      .catch(this.view.onError);
  }

  doLike(courseId) {
    this.api.courses
      .doLike(courseId)
      .then((response) => this.view.onDoLikeResponse(response.data))
      .catch(this.view.onError);
  }

  doDislike(courseId) {
    this.api.courses
      .doDislike(courseId)
      .then((response) => this.view.onDoDislikeResponse(response.data))
      .catch(this.view.onError);
  }

  removeCourseSpecialty(courseId, specialtyId) {
    this.api.courses
      .removeCourseSpecialty(courseId, specialtyId)
      .then((response) => {
        this.view.onRemoveCourseSpecialtyResponse(response.data)
      })
      .catch(this.view.onError);
  }

  doStartCourse(courseId, careerId) {
    this.api.courses
      .doStartCourse(courseId, careerId)
      .then((response) => this.view.onDoStartCourseResponse(response.data))
      .catch(this.view.onError);
  }

  isValidActivityType(collection, activityType, keyId) {
    let item = _.find(collection, { id: activityType });
    return !_.isNil(item) && item.id === keyId;
  }

  mapInstructors(collection) {
    return _.map(collection, (item) => {
      return new Instructor(item);
    });
  }

  mapCourseSections(collection) {
    return _.map(collection, (item) => {
      item.courseSubjects = this.mapCourseSubjects(item.courseSubjects);
      return new CourseSection(item);
    });
  }

  mapCourseSubjects(collection) {
    return _.map(collection, (item) => {
      item.courseSubjectActivities = this.mapCourseSubjectActivities(
        item.courseSubjectActivities
      );
      return new CourseSubject(item);
    });
  }

  mapCourseSubjectActivities(collection) {
    return _.map(collection, (item) => {
      item.courseSubjectActivityAnswers = this.mapCourseSubjectActivityAnswers(
        item.courseSubjectActivityAnswers
      );
      item.courseSubjectTestActivities = this.mapCourseSubjectTestActivities(
        item.courseSubjectTestActivities
      );
      return new CourseSubjectActivity(item);
    });
  }

  mapCourseSubjectActivityAnswers(collection) {
    return _.map(collection, (item) => {
      return new CourseSubjectActivityAnswer(item);
    });
  }

  mapCourseSubjectTestActivities(collection) {
    return _.map(collection, (item) => {
      item.courseSubjectTestQuestions = this.mapCourseSubjectTestQuestions(
        item.courseSubjectTestQuestions
      );
      return new CourseSubjectTestActivity(item);
    });
  }

  mapCourseSubjectTestQuestions(collection) {
    return _.map(collection, (item) => {
      item.courseSubjectTestQuestionAnswers = this.mapCourseSubjectTestQuestionAnswers(
        item.courseSubjectTestQuestionAnswers
      );
      return new CourseSubjectTestQuestion(item);
    });
  }

  mapCourseSubjectTestQuestionAnswers(collection) {
    return _.map(collection, (item) => {
      return new CourseSubjectTestQuestionAnswer(item);
    });
  }
}
