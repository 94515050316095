import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SimulatorViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            isListLoading: null,
            listLoadingMessage: null,
            simulators: [],
            viewModel: null,
            filters: [],
            collectionHistory: [],
            permissions: null,
            currentPage: 1,
            limit: 20,
            pagination: {
                totalItems: 0,
                totalPages: 0
            },
            formData: {
                rangeDate: '',
                simulator: '',
            },
            modalSimulatorSolved: {
                isVisible: false,
                isLoading: false,
                simulatorId: null,
                studentId: null,
                title: 'RESULTADOS DEL SIMULADOR',
                onAcceptResult: 'onAcceptSimulatorSolved'
            },
            rules: {
                rangeDate: [
                    { required: true, message: 'Rango de Fecha requerido.' }
                ],
                simulator: [
                    { required: true, message: 'Cupon requerido.' }
                ],
            }
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
    },
    mounted() {
        EventBus.$on('onAcceptSimulatorSolved', this.onAcceptResult);
      },
      beforeDestroy() {
        EventBus.$off('onAcceptSimulatorSolved', this.onAcceptResult);
      },
    created() {
        this.viewModel = new SimulatorViewModel(this)
        //this.onActive();
    },
    methods: {
        onSearch() {
            this.$refs['formData'].validate((valid) => {
                if (valid) {
                    this.showLoading(true, 'Actualizando historial...');
                    this.viewModel.findHistoryCollection();
                }
            });
        },
        onFindHistoryCollectionResponse(response) {
            setTimeout(() => {
                this.showLoading(false, '');
                this.collectionHistory = response;
            }, 500);
        },
        mapProfileImage(userProfile) {
            return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
        },
        sizeCareers(careers) {
            return _.size(careers) != 0;
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
            if (!data) {
                this.formData.rangeDate = null;
                this.viewModel.removeFilter('StartDate');
                this.viewModel.removeFilter('EndDate');
            }
            this.onSearch();
        },
        onRangeDate(data, startDate, endDate) {
            if (data) {
                this.onFilterChange(data[0], startDate);
                this.onFilterChange(data[1], endDate);
                this.onSearch();
            } else {
                this.viewModel.removeFilter(startDate);
                this.viewModel.removeFilter(endDate);
                this.onSearch();
            }
        },
        onSearchSimulators(data) {
            if (data) {
                this.viewModel.findAllSimulators(`Name=${data}`)
            } else {
                this.simulators = []
            }
        },
        onSearchResponse(response, headers) {
            this.pagination = this.viewModel.getPagination(headers)
            this.simulators = response.data;
        },
        onAccept() {
            this.formData = null;
            this.simulators = [];
            EventBus.$emit(this.conf.onAcceptHistory, null);
        },
        onFilterChange(data, field) {
            this.viewModel.updateFilters(data, field, '=');
        },
        onShowSimulatorSolved(studentId, simulatorId) {
            this.modalSimulatorSolved.studentId = studentId;
            this.modalSimulatorSolved.simulatorId = simulatorId;

            this.viewModel.showModal(this.modalSimulatorSolved, true);
        },
        onPageChange(data) {
            this.currentPage = data;
            this.onSearch();
        },
        onAcceptResult() {
            this.viewModel.showModal(this.modalSimulatorSolved, false);
        },
    }
}