<script>
import FooterView from '../../shared/views/MainFooterView';
export default {
  name: 'AboutPageView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse  justify-content-center">
                    <a class="nav-item nav-link" href="/">Inicio</a>
                    <a class="nav-item nav-link active" href="/acerca"
                      >Acerca</a
                    >
                    <a class="nav-item nav-link" href="/cursos">Cursos</a>
                    <a class="nav-item nav-link" href="/noticias"
                      >Noticias</a
                    >
                    <a class="nav-item nav-link" href="/contacto">Contacto</a>
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>Expandiendo Oportunidades de Aprendizaje</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215zd5">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="title484">
                <h2>Nuestros Origenes</h2>
                <img class="line-title" src="images/line.svg" alt="" />
                <p>
                  Es una institución impulsada por el experimentado académico Dr. Erick
                  Gastélum quien cuenta con más de 12 años de trayectoria en el
                  ámbito educativo, preprarando a los aspirantes de diferentes
                  carreras en la universidad, contando con miles de casos de
                  éxito que hablan de la calidad y profesionalismo.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="story125">
                <img src="images/professor.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="_215td5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Nuestas Oficinas</h2>
                <p>Tenemos oficinas cerca de tí</p>
                <img class="line-title" src="images/line.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="branches_all">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="blogbg_1 mt-30">
                  <a href="#" class="cpy_img">
                    <img src="images/about/company-1.jpg" alt="" />
                    <div class="course-overlay"></div>
                  </a>
                  <div class="cpy_content">
                    <a href="#" class="cmpy14s title900">Mexicali, Baja California</a>
                    <p class="blog_des">
                      Calzada Macristy de Hermosillo #881, República Mexicana,
                      Mexicali, B.C., M&eacute;xico<br />
                      <a href="#" class="cmpylink"
                        ><i class="uil uil-phone"></i> 686 125 3218</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="blogbg_1 mt-30">
                  <a href="#" class="cpy_img">
                    <img src="images/about/company-2.jpg" alt="" />
                    <div class="course-overlay"></div>
                  </a>
                  <div class="cpy_content">
                    <a href="#" class="cmpy14s title900">Tijuana, Baja California</a>
                    <p class="blog_des">
                      Av. Revolución #1785, Zona Centro,<br />
                      Tijuana, B.C. (Sala ACDA), M&eacute;xico<br />
                      <a href="#" class="cmpylink"
                        ><i class="uil uil-phone"></i> 686 125 3218</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="blogbg_1 mt-30">
                  <a href="#" class="cpy_img">
                    <img src="images/about/company-3.jpg" alt="" />
                    <div class="course-overlay"></div>
                  </a>
                  <div class="cpy_content">
                    <a href="#" class="cmpy14s title900">Ensenada, Baja California</a>
                    <p class="blog_des">
                      Av. Rayon 294, Col. Obrera, Ensenada B.C<br />
                      Ensenada B.C, M&eacute;xico<br />
                      <a href="#" class="cmpylink"
                        ><i class="uil uil-phone"></i> 686-125-3218</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215xd5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Misi&oacute;n & Visi&oacute;n</h2>
                <p>Nuestras creencias, nuestros objetivos, nuestra formula!</p>
                <img class="line-title" src="images/line.svg" alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="jmio125">
                <p>
                  <strong>Nuestra Misi&oacute;n: </strong> Ofrecer una formación
                  educativa, a través de un servicio de calidad y
                  profesionalismo, permitiendo a nuestros alumnos el acceso a la
                  universidad logrando con ello la realización de sus proyectos
                  de vida.
                </p>

                <p>
                  <strong>Nuestra Visi&oacute;n: </strong>Ser una empresa líder
                  en asesorías educativas, a través de un servicio educativo de
                  calidad con un gran compromiso social pleno de valores y
                  principios.
                </p>
                <a href="/registrarse" class="crer_btn_link btn-primary"
                  >Unete a nuestra familia</a
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="story125">
                <img src="images/team-mision.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>
