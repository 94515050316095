import { EventBus } from '../../../core/shared/helpers/EventHelper';
import BaseComponent from '../../../core/infraestructure/BaseComponent';
import AccountProfileViewModel from '../../../core/features/authentication/viewmodels/AccountProfileViewModel';

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: '',
            isFormProcessing: false,
            session: null,
            item: null,
            sessionAbout: '',
            fullLoader: true,
            modalItem: {
                isVisible: false,
                isLoading: false,
            },
            formData: {
                email: ''
            },
            profileImageUrl: '/images/hd_dp.jpg',
            rules: {
                /* email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ], */
            }
        };
    },
    mounted() {
        EventBus.$on('onSubmitedInstructor', this.onReload);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedInstructor', this.onReload);
    },
    created() {
        this.viewModel = new AccountProfileViewModel(this);
        this.viewModel.onBindProfile();
    },
    methods: {
        onReload() {
            setTimeout(() => {
                window.location.reload();
            }, 400);
        },
        onEditProfile() {
            this.viewModel.onFindInstructor(this.session.instructor.id);
        },
        onFindInstructorResponse(response) {
            this.item = this.viewModel.onEditInstructorProfile(response.data);
            this.viewModel.showModalLoading(this.modalItem, true);
            this.viewModel.showModal(this.modalItem, true);
        }
    },
}