import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiCareers extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    createInStudent(data) {
        return this.client.post(`${this.endPoint}/in-student`, data)
    }

    findAllCareersByKnowledgeAreaIds(data) {
        return this.client.post(`${this.endPoint}/by-knowledge-areas`, data)
    }

    getByStudentUniversity() {
        return this.client.get(`${this.endPoint}/by-student-university`)
    }
}