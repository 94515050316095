import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import CourseSubjectViewModel from '../../../../core/features/course/viewmodels/CourseSubjectViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            itemCourseSubjectActivity: null,
            courseSubjectActivitySelectedIndex: null,
            courseSubjectActivityTypes: [],
            modalItemCourseSubjectActivity: {
                isVisible: false,
                isLoading: false
            },
            modalItemCourseSubjectTest: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Descripción requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectViewModel(this);
        this.onActive();
    },
    mounted() {
        EventBus.$on('onSubmitedCourseSubjectActivity', this.onUpsertCourseSubjectActivity);
        EventBus.$on('onCanceledCourseSubjectActivity', this.onCanceledCourseSubjectActivity);
        EventBus.$on('onCanceledCourseSubjectQuestion', this.onCanceledCourseSubjectQuestion);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSubjectActivity', this.onUpsertCourseSubjectActivity);
        EventBus.$off('onCanceledCourseSubjectActivity', this.onCanceledCourseSubjectActivity);
        EventBus.$off('onCanceledCourseSubjectQuestion', this.onCanceledCourseSubjectQuestion);
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onCancel() {
            EventBus.$emit('onCanceledCourseSubject', null);
        },
        onBindFormViewResponse() {
            // this.formData.courseSubjectActivityAnswers = this.viewModel.bindSubjectActivitiesUpdateState(this.formData.courseSubjectActivities);

        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.conf.isLoading = true
                    EventBus.$emit('onSubmitedCourseSubject', this.formData);
                }
            });
        },
        onCanceledCourseSubjectActivity() {
            this.courseSubjectActivitySelectedIndex = null;
            this.modalItemCourseSubjectActivity.isVisible = false
            this.modalItemCourseSubjectActivity.isLoading = false
        },
        onCanceledCourseSubjectQuestion() {
            this.courseSubjectActivitySelectedIndex = null;
            this.modalItemCourseSubjectTest.isLoading = false
            this.modalItemCourseSubjectTest.isVisible = false
        },
        onCreateCourseSubjectActivity() {
            this.itemCourseSubjectActivity = this.formData.createCourseSubjectActivity();
            this.modalItemCourseSubjectActivity.isLoading = true
            this.modalItemCourseSubjectActivity.isVisible = true
        },
        onCreateCourseSubjectTest() {
            this.itemCourseSubjectActivity = this.formData.createCourseSubjectActivityTest();
            this.modalItemCourseSubjectTest.isLoading = true
            this.modalItemCourseSubjectTest.isVisible = true
        },
        onEditCourseSubjectActivity(item) {
            this.itemCourseSubjectActivity = this.formData.editCourseSubjectActivity(item);
            if (this.itemCourseSubjectActivity.courseSubjectActivityType === 2) {
                // this.viewModel.showModal(this.modalItemCourseSubjectTest, true);
                this.modalItemCourseSubjectTest.isLoading = true
                this.modalItemCourseSubjectTest.isVisible = true
            } else {
                // this.viewModel.showModal(this.modalItemCourseSubjectActivity, true);
                this.modalItemCourseSubjectActivity.isLoading = true
                this.modalItemCourseSubjectActivity.isVisible = true
            }
        },
        onDeleteCourseSubjectActivity(index) {
            this.$confirm("¿Desea eliminar la actividad seleccionada?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.conf.isLoading = true
                    setTimeout(() => {
                        this.courseSubjectActivitySelectedIndex = null
                        this.formData.removeCourseSubjectActivity(index)
                        this.$forceUpdate()
                        this.formData.updateCourseSubjectActivityPosition()
                        this.conf.isLoading = false
                    }, 400);
                })
                .catch(() => { });
        },
        onUpsertCourseSubjectActivity(item) {
            setTimeout(() => {
                (this.courseSubjectActivitySelectedIndex != null)
                    ? this.formData.updateCourseSubjectActivity(this.courseSubjectActivitySelectedIndex, item)
                    : this.formData.addCourseSubjectActivity(item)
                this.$forceUpdate()
                this.formData.updateCourseSubjectActivityPosition()
                this.courseSubjectActivitySelectedIndex = null;
                this.modalItemCourseSubjectTest.isLoading = false;
                this.modalItemCourseSubjectTest.isVisible = false;
                this.modalItemCourseSubjectActivity.isLoading = false;
                this.modalItemCourseSubjectActivity.isVisible = false;
            }, 400);
        },
        onChangeItemOptions(option) {
            this.courseSubjectActivitySelectedIndex = option.index;
            if (option.command === 'edit') this.onEditCourseSubjectActivity(option.item);
            if (option.command === 'delete') this.onDeleteCourseSubjectActivity(option.index);
        },
        onSearchCourseSubjectActivityTypesResponse(response) {
            this.courseSubjectActivityTypes = response.data;
        },
        getActivityTypeName(courseSubjectActivityType) {
            return this.viewModel.getActivityTypeName(this.courseSubjectActivityTypes, courseSubjectActivityType);
        },
        getDurationTotal(totalHours, duration) {
            return (totalHours) ? totalHours : this.viewModel.getTimeFromMins(duration);
        },
        onChangeDrag() {
            this.formData.updateCourseSubjectActivityPosition()
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanCreateCourseSubjectActivity(permissions) {
            return this.canCreate(permissions) || this.canUpdate(permissions)
        },
        onCanReadCourseSubjectActivity(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSubjectActivity(permissions) {
            return this.canDelete(permissions);
        },
    }
}