import _ from 'lodash'
import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SimulatorOfficialSection from '../models/SimulatorOfficialSection'
import SimulatorOfficialQuestion from '../models/SimulatorOfficialQuestion'

export default class SimulatorOfficialSectionViewModel extends BaseViewModel {
	constructor(view) {
		super(view)
	}
	// INICIALIZADORES
	static UPDATE_STATES = {
		ADD: 1,
		EDIT: 2,
		REMOVE: 3,
	}

	//#region CRUD

	onCreate() {
		let item = new SimulatorOfficialSection({
			state: "create",
			questionAnswers: [{ description: '', success: false }, { description: '', success: false }, { description: '', success: false }, { description: '', success: false }],
			status: 1
		});
		return item;
	}

	onDelete(data) {
		let item = new SimulatorOfficialSection(data);
		item.state = "delete";
		return item;
	}

	onEdit(data) {
		let item = new SimulatorOfficialSection(data);
		item.state = "update";
		return item;
	}

	createQuestion(sections) {
		var item = new SimulatorOfficialQuestion({
			state: 'create',
			sort: _.size(sections) + 1,
			questionAnswers: [{ description: '', success: false }, { description: '', success: false }, { description: '', success: false }, { description: '', success: false }],
		});

		return item;
	}

	onSearchCollection() {
		return this.api.simulatorQuestions.find(this.getSearchQuery())
	}

	mapCollection(collection) {
		return _.map(collection, (data) => new SimulatorOfficialSection(data));
	}

	onSearchItem(id) {
		return this.api.simulatorQuestions.findOne(id)
	}

	onSave(data) {
		if (data.state === 'create')
			return this.api.simulatorQuestions.create(data.toSend())
		else
			return this.api.simulatorQuestions.update(data.id, data.toSend());
	}

	onDestroy(context, id) {
		context.api.simulatorQuestions.delete(id)
			.then(response => context.view.onSuccess(response.data))
			.catch(context.view.onError)
	}

	onCancel(context) {
		context.view.item = null;
	}

	import(file) {
		this.api.simulatorQuestions.import(file)
			.then(response => this.view.onImportResponse(response.data))
			.catch(this.view.onError)
	}

	exportToExcel() {
		this.api.simulatorQuestions.exportToExcel()
			.then(response => this.view.onExportResponse(response.data))
			.catch(this.view.onError)
	}

	addNewQuestions(questions, collection) {
		questions.splice(0,questions.length)
		collection.forEach(element => {
			questions.push(element)
		});
	}

	// #endregion

	// #region BINDING DATA
	bindQuestionFormView(formData) {
		setTimeout(() => {
/*
			this.view.editorDescription = QuillEditorHelper.buildEditor(`.description-editor`);

			this.view.editorDescription.on('text-change', (delta, oldDelta, source) => {
				var text = this.view.editorDescription.getText();
				this.view.$root.$emit('update-question-editor', (_.isEmpty(text)) ? null : text);
			});

			this.view.editorDescription.root.innerHTML = formData.description || "";*/
			this.bindFormView();
			this.bindAnswerEditors(formData.questionAnswers);
			this.findCareers(`UniversityId=${formData.universityId}`);
			setTimeout(() => {
				this.view.conf.isLoading = false;
			}, 400);
		}, 300);
	}

	bindFormView() {
		if (this.view.formData.state === 'update') {

			this.view.formData.totalPartialQuestion = this.view.formData.totalPartialQuestion - this.view.formData.questionQuantity;

			if (!_.isEmpty(this.view.formData.courseCategory)) {
				this.view.courseCategories = [this.view.formData.courseCategory]
			}
			
			if(!_.isUndefined(this.view.formData.simulatorSectionQuestions)) {
				this.view.formData.simulatorQuestions = this.mapSimulatorQuestions(this.view.formData.simulatorSectionQuestions);
			}
		}
	}

	bindAnswerEditors(answers) {
		_.forEach(answers, (answer, index) => {

			var currentAnswer = {
				selector: `answer_editor_${index}`,
				component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
			};
			currentAnswer.component.root.innerHTML = answer.description;
			this.view.editors.push(currentAnswer);
		});
	}

	saveQuestion(formData) {
		formData.updateState = (formData.id) ? SimulatorOfficialSectionViewModel.UPDATE_STATES.EDIT : SimulatorOfficialSectionViewModel.UPDATE_STATES.ADD;
		return formData.toSend(this.view.editors);
	}

	// #endregion

	// #region QUERIES
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findKnowledgeAreas(data) {
		this.api.knowledgeAreas.find(data)
			.then((response) => {
				this.view.onFindKnowledgeAreasResponse(response.data.data)
			})
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findSimulatorExamTypes(data) {
		this.api.simulatorExamnTypes.find(data)
			.then((response) => this.view.onFindSimulatorExamTypesResponse(response.data.data))
			.catch(this.view.onError)
	}

	findCareers(query) {
		this.api.careers.find(query)
			.then((response) => this.view.onFindCareersResponse(response.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar tipo de simulador
	 */
	getSimulatorType(data, type) {
		return _.find(data, function (o) {
			return o.id == type;
		});
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	getRandomQuestions(data) {
		this.api.simulators.findRandomQuestions(data)
			.then((response) => this.view.onFindRandomQuestionsResponse(response.data.data))
			.catch(this.view.onError)
	}

	// #endregion

	//#region MAPPING DATA
	addNewAnswer(answers) {

		answers.push({
			description: '', success: false,
			description: '',
		});

		setTimeout(() => {
			var nextIndex = _.size(answers) - 1;
			this.view.editors.push({
				selector: `answer_editor_${nextIndex}`,
				component: QuillEditorHelper.buildEditor(`.answer_editor_${nextIndex}`)
			});
		}, 300);

	}

	removeAnswer(answers, index) {
		answers.splice(index, 1);
		this.view.editors.splice(index, 1);
	}

	filterRemovedSectionQuestions(collection) {
		return _.filter(collection, (item) => {
			return item.updateState != SimulatorOfficialSectionViewModel.UPDATE_STATES.REMOVE
		})
	}

	filterAndSortSectionQuestions(collection) {
		let filteredResults = this.filterRemovedSectionQuestions(collection)
		return _.sortBy(filteredResults, 'sort');
	}

	changeDrag(collection) {
		var questions = this.filterRemovedSectionQuestions(collection)
		for (let index = 0; index < questions.length; index++) {
			const element = questions[index];
			element.sort = index + 1;
			element.updateState = (element.updateState == SimulatorOfficialSectionViewModel.UPDATE_STATES.ADD) ? element.updateState : SimulatorOfficialSectionViewModel.UPDATE_STATES.EDIT;
		}
	}

	mapSimulatorQuestions(collection) {
		let simulatorQuestions = []
		_.find(collection, function (o) {
			simulatorQuestions.push(o.simulatorQuestion)
		});
		return simulatorQuestions;
	}

	mapSpecialties(collection) {
		let specialties = "";
		_.find(collection, function (o, index) {
			if (index == 0) {
				specialties += o.id;
			} else { 
				specialties += `,${o.id}`;
			}
		});
		return specialties;
	}

	// #endregion

	// #region VALIDATIONS
	isDescriptionValid(rule, value, callback) {
		if (!_.isNil(value) || !_.isEmpty(value)) {
			callback();
		} else {
			callback(new Error("Descripción requerido."));
		}
	}

	isQuestionQuantityValid(rule, value, callback, totalPartialQuestion, limitQuestion) {
		if (!_.isNil(value) || !_.isEmpty(value)) {

			let partial = parseInt(totalPartialQuestion) + parseInt(value);

			if( partial > limitQuestion) {
				callback(new Error("No. de Preguntas excedido."));
			} else {
				callback();
			}
		} else {
			callback(new Error("Numero de Preguntas requerido."));
		}
	}

	isKnowledgeAreaValid(formData) {
		return (formData.knowledgeArea && formData.knowledgeArea.id);
	}

	isQuestionsQuantityValid(formData) {
		return (formData.questionQuantity && (formData.questionQuantity > 0));
	}

	/**
   * Metodo para validación de formulario de recepcion para verificar que vengan kits.
   */
	 validateForm(valid) {
		var isValid = false

		if (_.size(this.view.formData.getQuestions()) != 0) {

			if(this.view.formData.questionQuantity == (_.size(this.view.formData.getQuestions()))) {
				if (valid) isValid = true
			} else {
				this.view.onError(new Error(
					'Ocupa agregar el numero de preguntas indicado'
				))
			}
		} else {
			if (valid) {
				this.view.onError(new Error(
					'Ocupa al menos agregar una pregunta'
				))
			}
		}
		return isValid
	}
	/**
   * Metodo para validación de formulario para verificar que venga el numero de preguntas.
   */
	 validateFormQuestion(quantity) {
		var isValid = false
		if (!_.isUndefined(quantity) && quantity > 0) {
			isValid = true
		} else {
			this.view.onError(new Error(
				'Ocupa agregar el Numero de Preguntas'
			))
		}
		return isValid
	}

	// #endregion
}
