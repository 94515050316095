import BaseViewModel from '../../../infraestructure/BaseViewModel';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';


export default class ContactPageViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD
  createMapContactMexicali(iconUrl, center) {
    var mapDiv = L.map('mapMain').setView(center, 17);
    
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mapDiv);

    var appIcon = L.icon({
        iconUrl: iconUrl,
        //shadowUrl: shadowUrl,
    
        iconSize:     [50, 30], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        //shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    L.marker(center, {icon: appIcon}).addTo(mapDiv).bindPopup("AppMisionate.");
    
    
}
  // #endregion

  // #region BINDING DATA

 
  // #endregion

  // #region QUERIES
  

  // #endregion

  //#region MAPPING DATA

  
  // #endregion

  // #region VALIDATIONS
  
  // #endregion
}