import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import KnowledgeAreaViewModel from '../../../../core/features/setting/viewmodels/KnowledgeAreaViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Settings-KnowledgeAreas',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      universities: [],
      filterOptions: {
        universityFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR ÁREAS DE CONOCIMIENTO',
        onUploadImport: 'onUploadImportKnowledgeArea',
        onCancelImport: 'onCancelImportKnowledgeArea',
      },
    };
  },
  mounted() {
    EventBus.$on('onSubmitedKnowledgeArea', this.onSearch);
    EventBus.$on('onUploadImportKnowledgeArea', this.onUploadImport);
    EventBus.$on('onCancelImportKnowledgeArea', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedKnowledgeArea', this.onSearch);
    EventBus.$off('onUploadImportKnowledgeArea', this.onUploadImport);
    EventBus.$off('onCancelImportKnowledgeArea', this.onCancelImport);
  },
  created() {
    this.viewModel = new KnowledgeAreaViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file) {
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport() {
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    onFindUniversity(data) {
      if (data) {
        this.viewModel.findUniversities(`Name=${data}`)
      } else {
        this.universities = []
      }
    },
    onFindUniversitiesResponse(response) {
      this.universities = response;
    },
  },
  computed: {}
}