import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SimulatorQuestionCategory from '../models/SimulatorQuestionCategory'

export default class SimulatorQuestionCategoryViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new SimulatorQuestionCategory({
      state: "create",
      status: 1
    });
    return item;
  }

  onDelete(data) {
    let item = new SimulatorQuestionCategory(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new SimulatorQuestionCategory(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.simulatorQuestionCategories.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new SimulatorQuestionCategory(data));
  }

  onSearchItem(id) {
    return this.api.simulatorQuestionCategories.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.simulatorQuestionCategories.create(data.toSend())
    else
      return this.api.simulatorQuestionCategories.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.simulatorQuestionCategories.delete(id)
        .then(response => context.view.onSuccess(response.data))
        .catch(context.view.onError)
}

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.simulatorQuestionCategories.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.simulatorQuestionCategories.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView(formData) {
    if (formData.state === 'update') {
      
    }
    setTimeout(() => {
      this.showModalLoading(this.view.conf, false);
    }, 400);
  }

  // #endregion

  // #region QUERIES
  

  // #endregion

  //#region MAPPING DATA

  
  // #endregion

  // #region VALIDATIONS

  // #endregion
}
