import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SimulatorViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            simulatorResult: null,
            modalShowResults: {
                isVisible: false,
                isLoading: false,
            },
            resultAnswers: null,
            colors: [
                { color: '#c70831', percentage: 69 },
                { color: '#dfbf0a', percentage: 89 },
                { color: '#10af38', percentage: 100 }
            ],
            isLoading: true,
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
    },
    created() {
        this.viewModel = new SimulatorViewModel(this)
        this.onActive();
    },
    methods: {
        onActive() {
            console.log(this.conf)
            this.viewModel.findResultCollection(`SimulatorId=${this.conf.simulatorId}&StudentId=${this.conf.studentId}`);
        },
        onFindResultCollectionResponse(response) {
            setTimeout(() => {
                this.isLoading = false
                this.simulatorResult = response;
                console.log(response)
            }, 500);
        },
        getStatusTag(percentage) {
            return this.viewModel.getStatusTag(percentage)
        },
        onGetAnswersResult(simulator) {
            this.isLoading = true
            console.log(simulator)
            this.viewModel.getResultAnswersBySimulatorSectionAndStudent(this.conf.simulatorId, simulator.id, this.conf.studentId,)  
        },
        onGetResultAnswersBySimulatorSectionAndStudentResponse(response) {
            setTimeout(() => {
                this.resultAnswers = response.data
                this.isLoading = false
                this.modalShowResults.isVisible = true
            }, 400);
        },
        onAccept() {
            this.formData = null;
            this.simulatorResult = [];
            EventBus.$emit(this.conf.onAcceptResult, null);
        },
    }
}