export default class KnowledgeArea {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            description: this.description,
            universityId: this.university.id,
        }
    }
}