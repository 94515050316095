import _ from 'lodash'
import QuillEditorHelper from '../../../../core/shared/helpers/QuillEditorHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import ResponseActivityAnswer from '../models/ResponseActivityAnswer';

export default class ResponseActivityAnswerViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new ResponseActivityAnswer({
      state: "create",
      status: 1,
      courseSubjects: [],
    });
    return item;
  }

  onDelete(data) {
    let item = new ResponseActivityAnswer(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new ResponseActivityAnswer(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.responseActivityAnswers.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new ResponseActivityAnswer(data));
  }

  onSearchItem(id) {
    return this.api.responseActivityAnswers.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.responseActivityAnswers.create(data.toSend())
    else
      return this.api.responseActivityAnswers.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.responseActivityAnswers.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.responseActivityAnswers.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.responseActivityAnswers.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  onBindEditor(selector) {
    this.view.editorDescription = QuillEditorHelper.buildEditor(selector);
  }

  onBindFormView() {
    if (this.view.formData.state === 'update') {
      this.view.editorDescription.root.innerHTML = this.view.formData.contentObservation;
      setTimeout(() => {
        this.view.conf.isLoading = false;
      }, 400);
    }
  }



  // #endregion

  // #region QUERIES

  /**
   * Metodo para hacer la revisión del curso
   * @param {String} data Query string para parametros de búsqueda
   */
  
  doReview(data) {
    this.api.responseActivityAnswers.doReview(data.id, data.toSend())
      .then((response) => this.view.onSaveResponse(response.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar autores
   * @param {String} data Query string para parametros de búsqueda
   */
  findStudents(data) {
    this.api.users.find(data)
      .then((response) => this.view.onSearchStudentsResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findCourses(data) {
    this.api.courses.find(data)
      .then((response) => this.view.onSearchCoursesResponse(response.data.data))
      .catch(this.view.onError);
  }

  // #endregion

  //#region MAPPING DATA

  

  // #endregion

  // #region VALIDATIONS


  // #endregion
}
