import BaseViewModel from '../../../infraestructure/BaseViewModel'
import CourseSubject from '../models/CourseSubject'

export default class CourseSectionViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    bindFormView(formData) {
        this.api.careers.find(`UniversityId=${formData.universityId}&Skip=0&Limit=100000&IsActive=true`)
        .then((response) => {
            this.view.careers = response.data.data
            setTimeout(() => {
                this.view.conf.isLoading = false
            }, 400);
        })
        .catch(this.view.onError)
    }
}