<script>
import ListResponseActivityAnswersComponent from '../../features/lists/ListResponseActivityAnswersComponent';
import ModalResponseActivityAnswerView from '../modals/ModalResponseActivityAnswerView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListResponseActivityAnswersComponent,
  name: 'ListResponseActivityAnswersView',
  components: {
    'modal-response-activity-answer-view': ModalResponseActivityAnswerView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl course-categories">
      <el-col :xs="24" :sm="9" :md="11" :lg="6" :xl="4" class="create-section">
        <el-button-group class="btn-grp-drp">
          <!-- <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Curso</el-button
          > -->
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> M&aacute;s Opciones
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Cursos</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <!--<el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> Filtrar Por <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.courseFilter" filterable remote clearable placeholder="Curso..."
                :remote-method="onSearchCourse" @change="onAllFilterChange(filterOptions.courseFilter.id, 'CourseId')" @focus="onFocusCourse"
                value-key="id">
                  <el-option v-for="item in courses" :key="item.id" :label="item.title" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <!--
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.studentFilter" filterable remote clearable placeholder="Estudiante..."
                :remote-method="onSearchStudent" @change="onAllFilterChange(filterOptions.studentFilter.id, 'StudentId')" @focus="onFocusStudent"
                value-key="id">
                  <el-option v-for="item in students" :key="item.id" :label="item.firstName +' '+ item.lastName" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              -->
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="6" :xl="5">
        <el-tooltip class="item" effect="dark" content="Buscar por Tema." placement="top">
          <el-input size="small" placeholder="Buscar Tema..." icon="search" v-model='filterOptions.courseSubjectFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.courseSubjectFilter, 'CourseSubjectTitle')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="6" :xl="5">
        <el-tooltip class="item" effect="dark" content="Buscar por Actividad." placement="top">
          <el-input size="small" placeholder="Buscar Actividad..." icon="search" v-model='filterOptions.courseSubjectActivityFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.courseSubjectActivityFilter, 'CourseSubjectActivityTitle')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="" width="50">
        <template slot-scope="scope">
         <div>
            <el-avatar :src="mapProfileImage(scope.row.userProfile)"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ESTUDIANTE" min-width="300">
        <template slot-scope="scope">
         <div>
            {{ scope.row.userProfile.firstName }} {{ scope.row.userProfile.lastName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ACTIVIDAD" min-width="320">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.courseSubjectActivity.title }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="TEMA" align="center" min-width="250">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.courseSubjectActivity.courseSubject.title }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CURSO" align="center" min-width="250">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.courseSubjectActivity.courseSubject.courseSection.course.title }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-response-activity-answer-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-response-activity-answer-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
