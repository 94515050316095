import ItemComponent from '../../../../core/infraestructure/ItemComponent';

export default {
    extends: ItemComponent,
    data() {
        return {
            rules: {
                
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.onActive();
    },
    methods: {
        onActive() { },
        isAnswerSuccess(selectedAnswers, successAnswers) {
            return (selectedAnswers.filter(item => item.isSelected).length === successAnswers.length) && selectedAnswers.filter(item => item.isSelected).every(item => item.success);
        }
    }
}