
<script>

import ListRoleComponent from '../../features/lists/ListRolesComponent';
import ModalRoleView from '../modals/ModalRoleView';

export default {
  extends: ListRoleComponent,
  name: 'ListRoleView',
  components: {
    'modal-role': ModalRoleView,
  },
};
</script>

<template>
  <div>
     <el-row :gutter="20" class="section-ctrl roles">
      <!--
      <el-col :sm='6' :lg='6' :xl='4' class="create-section">
        <el-button-group>
          <el-button type="primary" size="small" class="btn-red" icon="el-icon-circle-plus" @click="onCreate" >Crear Rol</el-button>
          <el-dropdown>
            <el-button size="small" class="btn-white">
              <i class="fa fa-ellipsis-v"></i> M&aacute;s Opciones <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button size="small" class="btn-white" icon="el-icon-upload" @click="onImport" >Importar Roles</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="small" class="btn-white" @click="onExportToExcel()">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar Excel
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      -->
      <el-col :sm='8' :lg='8' :xl='8'>
        <el-tooltip class="item" effect="dark" content="Buscar por nombre." placement="top">
          <el-input size="small" placeholder="Buscar nombre...." v-model="searchFullName" @keyup.enter.native="onAllFilterChange(searchFullName, 'Name')" icon="search" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :sm='6' :lg='5' :xl='4' class="filters-section">
        <el-button-group>
          <!-- <el-button type="primary" size="small" class="btn-gold" @click="onExportExcel">
            <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar Excel
          </el-button>
          <el-button type="primary" size="small" class="btn-gold" @click="onExportPdf">
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
          </el-button>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-gold">
              <i class="fa fa-filter" aria-hidden="true"></i> Filtrar Por <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </el-dropdown> -->
        </el-button-group>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading"
      element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="NOMBRE" :min-width="400">
        <template slot-scope="scope">
          <div>
            <el-tag type="default" effect="plain"
              ><i class="uil uil-users-alt"></i>
              {{ scope.row.listItemName() }}</el-tag
            ></div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="OPCIONES" :width="160" align="center" text-align="center">
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{'command':'edit', 'item':scope.row}" :disabled="!onCanRead(modulePermissions)">
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item :command="{'command':'delete', 'item':scope.row}" :disabled="!onCanDelete(modulePermissions)">
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination small layout="prev, pager, next" :page-count="pagination.totalPages" :current-page.sync="currentPage" @current-change="onPageChange"></el-pagination>
    <modal-role :formData="item" :conf="modalItem" v-if="modalItem.isVisible"></modal-role>
  </div>
</template>
<style>

</style>

