import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import CourseSubjectActivityViewModel from '../../../../core/features/course/viewmodels/CourseSubjectActivityViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            defaulAnswersQty: 4,
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            courseSubjectActivityTypes: [],
            hasFile: false,
            hasVideo: false,
            editorDescription: null,
            activityFile: null,
            activityFileUrl: null,
            isActivityFileTemp: false,
            answerEditors: [],
            isAnswerLoading: false,
            isFileLoading: false,
            rules: {
                courseSubjectActivityType: [
                    { required: true, message: 'Tipo de actividad requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ]
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectActivityViewModel(this);
        this.onActive();
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onBindFormViewResponse() {
            this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getCourseSubjectActivityAnswers(), this.answerEditors)
            setTimeout(() => {
                this.conf.isLoading = false;
            }, 400);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.conf.isLoading = true
                    this.formData.bindFile(this.activityFile)
                    EventBus.$emit('onSubmitedCourseSubjectActivity', this.formData);
                }
            });
        },
        onCancel() {
            EventBus.$emit('onCanceledCourseSubjectActivity', null);
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedCourseSubjectActivity', null);
        },
        onSearchCourseSubjectActivityTypesResponse(response) {
            this.courseSubjectActivityTypes = response.data;
        },
        onActivityTypeChange(activityType) {
            console.log('on change activity type: ', activityType);
        },
        onCreateCourseSubjectActivityAnswer() {
            this.isAnswerLoading = true
            this.formData.addCourseSubjectActivityAnswer(this.formData.createCourseSubjectActivityAnswer())
            setTimeout(() => {
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getCourseSubjectActivityAnswers(), this.answerEditors)
                this.isAnswerLoading = false
            }, 400);
        },
        onDeleteCourseSubjectActivityAnswer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    this.isAnswerLoading = true
                    this.formData.removeCourseSubjectActivityAnswer(index)
                    this.viewModel.removeAnswerEditor(this.answerEditors, index)
                    this.$forceUpdate()
                    setTimeout(() => {
                        this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getCourseSubjectActivityAnswers(), this.answerEditors)
                        this.isAnswerLoading = false
                    }, 400);
                })
                .catch(() => {

                });
        },
        onSuccessUploadActivityFile(response) {
            this.$forceUpdate();
            // console.log('on success: ', response)
        },
        async onBeforeUploadActivityFile(file) {
            //console.log('on before', file)
            const isPdf = file.type === 'application/pdf';
            const isLt2M = file.size / 1024 / 1024 < 10;

            if (!isPdf) {
                this.$message.error('El Archivo debe estar en formato PDF!');
            }

            if (!isLt2M) {
                this.$message.error('El Archivo excede los 10MB!');
            }

            if (isPdf & isLt2M) {
                this.isFileLoading = true;
                setTimeout(() => {
                    // let dataUrl = await this.viewModel.convertFileToBase64(file)
                    // this.activityFileUrl = (dataUrl) ? dataUrl : '/images/courses/add_img.jpg'
                    // this.activityFile = file;
                    this.isActivityFileTemp = true;
                    this.formData.file = file
                    // this.formData.fileUrl = await this.viewModel.convertFileToBase64(file)
                    this.formData.fileName = file.uid
                    this.isFileLoading = false;
                }, 400);
            }
            return isPdf && isLt2M;
        },
        onRemoveActivityFile() {
            // this.activityFileUrl = '/images/courses/add_img.jpg'

            this.$confirm("¿Desea eliminar el archivo existente?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.isFileLoading = true;
                    setTimeout(() => {
                        this.formData.file = null;
                        this.isActivityFileTemp = false;
                        this.formData.fileUrl = null;
                        this.isFileLoading = false;
                    }, 400);
                })
                .catch(() => { });
            
        },
        onShowActivityFile(formData) {
            const fileUrl = (formData.fileUrl) ? formData.fileUrl : URL.createObjectURL(formData.file)
            this.isFileLoading = true;
            setTimeout(() => {
                var link = document.getElementById('someLink')
                if (link) {
                    link.id = 'someLink'; //give it an ID!
                    link.href = (fileUrl) ? fileUrl : this.activityFileUrl;
                    link.target = "_blank"
                } else {
                    link = document.createElement("a")
                    link.id = 'someLink'; //give it an ID!
                    link.href = (fileUrl) ? fileUrl : this.activityFileUrl;
                    link.target = "_blank"
                    document.body.appendChild(link);
                }
                document.getElementById('someLink').click();
                this.isFileLoading = false;
            }, 400);
        },
        onChangeMultipleResponse(isMultiple) {
            this.isAnswerLoading = true;
            if(this.formData.emptyAnswers() && isMultiple){
                this.formData.setDefaultAnwers(this.defaulAnswersQty)
            } else if(!isMultiple){
                this.formData.removeAllAnswers()
            }

            setTimeout(() => {
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getCourseSubjectActivityAnswers(), this.answerEditors)
                this.isAnswerLoading = false
            }, 400);

        },
        onUpdateAnswer(answer) {
            this.viewModel.updateAnswer(answer);
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
    }
}