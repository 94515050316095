import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import University from '../models/University'

export default class UniversityViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new University({
      state: "create",
      status: 1
    });
    return item;
  }

  onDelete(data) {
    let item = new University(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new University(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.universities.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new University(data));
  }

  onSearchItem(id) {
    return this.api.universities.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.universities.create(data.toSend())
    else
      return this.api.universities.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.universities.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(this.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.universities.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.universities.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView(formData) {
    if (formData.state === 'update') {
      
    }
    setTimeout(() => {
      this.showModalLoading(this.view.conf, false);
    }, 400);
  }

  // #endregion

  // #region QUERIES

  /**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findCourseCategories(data) {
    this.api.universities.find(data)
      .then((response) => this.view.onSearchCourseCategoriesResponse(response.data.data))
      .catch(this.view.onError)
  }
  

  // #endregion

  //#region MAPPING DATA

  
  // #endregion

  // #region VALIDATIONS

  // #endregion
}
