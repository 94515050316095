import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import CourseSubjectTestViewModel from '../../../../core/features/course/viewmodels/CourseSubjectTestViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            itemQuestion: null,
            testActivitySelectedIndex: null,
            questionSelectedIndex: null,
            instructionEditors: [],
            descriptionEditors: [],
            isFileTemp: false,
            file: null,
            fileUrl: null,
            modalItemQuestion: {
                isVisible: false,
                isLoading: false
            },
            timerTypes: [],
            isTestTimeType: false,
            isSectionTimeType: false,
            isSectionLoading: false,
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectTestViewModel(this);
        this.onActive();
    },
    mounted() {
        EventBus.$on('onSubmitedCourseSubjectTestQuestion', this.onUpsertCourseSubjectTestQuestion);
        EventBus.$on('onCanceledCourseSubjectTestQuestion', this.onCanceledCourseSubjectTestQuestion);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSubjectTestQuestion', this.onUpsertCourseSubjectTestQuestion);
        EventBus.$off('onCanceledCourseSubjectTestQuestion', this.onCanceledCourseSubjectTestQuestion);
    },
    methods: {
        onActive() {
            this.viewModel.bindTestFormView(this.formData);
        },
        onCancel() {
            EventBus.$emit('onCanceledCourseSubjectQuestion', null);
        },
        onCanceledCourseSubjectTestQuestion() {
            this.itemQuestion = null
            this.questionSelectedIndex = null
            this.modalItemQuestion.isVisible = false
            this.modalItemQuestion.isLoading = false
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.conf.isLoading = true
                    EventBus.$emit('onSubmitedCourseSubjectActivity', this.formData);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onAddTestActivity() {
            this.conf.isLoading = true
            setTimeout(() => {
                this.formData.createTestActivity()
            }, 300);
            setTimeout(() => {
                this.viewModel.clearEditorsView()
                this.instructionEditors = this.viewModel.renderEditors(this.formData.getTestActivities(), "instruction_editor")
                this.descriptionEditors = this.viewModel.renderEditors(this.formData.getTestActivities(), "description_editor")
                this.conf.isLoading = false
            }, 400);
        },
        onDeleteTestActivity(indexSection) {
            this.$confirm('¿Desea eliminar la sección seleccionada?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    this.conf.isLoading = true
                    
                    setTimeout(() => {
                        this.formData.removeTestActivity(indexSection)
                        this.$forceUpdate()
                        this.viewModel.removeEditor(this.instructionEditors, indexSection, "instruction_editor")
                        this.viewModel.removeEditor(this.descriptionEditors, indexSection, "description_editor")
                        this.conf.isLoading = false
                        this.testActivitySelectedIndex = null
                        this.questionSelectedIndex = null
                    }, 400);
                })
                .catch(() => {
                    this.testActivitySelectedIndex = null
                    this.questionSelectedIndex = null
                });
        },
        onAddQuestion(indexTextActivity) {
            this.testActivitySelectedIndex = indexTextActivity;
            let testActivity = this.formData.getTestActivity(indexTextActivity);
            this.formData.changeUpdateState(testActivity)
            this.itemQuestion = testActivity.createQuestion()
            this.modalItemQuestion.isLoading = true
            this.modalItemQuestion.isVisible = true
        },
        onUpsertCourseSubjectTestQuestion(item) {
            this.modalItemQuestion.isLoading = true
            let testActivity = this.formData.getTestActivity(this.testActivitySelectedIndex);
            setTimeout(() => {
                if (this.questionSelectedIndex != null) {
                    testActivity.updateQuestion(this.questionSelectedIndex, item)
                } else {
                    testActivity.addQuestion(item)
                }
                this.testActivitySelectedIndex = null;
                this.questionSelectedIndex = null;
                this.modalItemQuestion.isLoading = false
                this.modalItemQuestion.isVisible = false
            }, 400)
        },
        onChangeItemOptions(option) {
            this.testActivitySelectedIndex = option.sectionIndex;
            this.questionSelectedIndex = option.index;
            if (option.command === 'edit') this.onUpdateQuestion(option.sectionIndex, option.index, option.item);
            if (option.command === 'delete') this.onDeleteQuestion(option.sectionIndex, option.index);
        },
        onDeleteQuestion(sectionIndex, questionIndex) {
            this.$confirm('¿Desea eliminar la sección seleccionada?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    this.isSectionLoading = true
                    let testActivity = this.formData.getTestActivity(sectionIndex);
                    setTimeout(() => {
                        testActivity.removeQuestion(questionIndex)
                        this.formData.changeUpdateState(testActivity)
                        this.isSectionLoading = false
                        this.testActivitySelectedIndex = null;
                        this.questionSelectedIndex = null;
                    }, 400);
                })
                .catch(() => {
                    this.testActivitySelectedIndex = null;
                    this.questionSelectedIndex = null;
                });
        },
        onUpdateQuestion(sectionIndex, questionIndex, question) {
            this.testActivitySelectedIndex = sectionIndex;
            this.questionSelectedIndex = questionIndex;
            let testActivity = this.formData.getTestActivity(sectionIndex);
            this.formData.changeUpdateState(testActivity)
            this.itemQuestion = testActivity.editQuestion(question);
            this.modalItemQuestion.isLoading = true
            this.modalItemQuestion.isVisible = true
        },
        onCanReadCourseSubjectQuestion(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSubjectQuestion(permissions) {
            return this.canDelete(permissions);
        },
        onChangeDrag(section) {
            this.formData.changeUpdateState(section)
            section.updateQuestionsPosition()
        },
        isTimerChange(isTimer) {
            if (!isTimer) {
                this.formData.timerType = null;
                this.formData.duration = null;
                this.isTestTimeType = false;
                this.isSectionTimeType = false;
            }
        },
        isTimerTypeChange(type) {
            this.isTestTimeType = false;
            this.isSectionTimeType = false;
            if (type) {
                this.isTestTimeType = (type.id === 2);
                this.isSectionTimeType = (type.id === 3);
            }
            this.formData.duration = null;
        }
    }
}