<script>
import ModalSimulatorOfficialSectionComponent from '../../features/modals/ModalSimulatorOfficialSectionComponent';
import ModalSimulatorOfficialQuestionView from './ModalSimulatorOfficialQuestionView';
import draggable from 'vuedraggable';

export default {
  extends: ModalSimulatorOfficialSectionComponent,
  name: 'ModalSimulatorOfficialSectionView',
  components: {
      'modal-simulator-question-view': ModalSimulatorOfficialQuestionView,
      draggable,
  },
};
</script>

<template>
    <div>
        <el-dialog
        title="Sección"
        :visible.sync="conf.isVisible"
        width="55%"
        custom-class="simulator-official-section-modal"
        :before-close="onCancel"
        :close-on-click-modal="false"
        >
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                status-icon
                :label-position="'top'"
                v-loading="conf.isLoading"
            >
                <fieldset>
                    <legend>Información de Sección</legend>
                    <el-row class="row-bg" :gutter="20">
                        <el-col :xs="24" :sm="12 " :md="12" :lg="12" :xl="14">
                            <el-form-item label="Nombre" prop="name">
                                <el-input v-model="formData.name" maxlength="120" size="small"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="5">
                            <el-form-item label="Numero de Preguntas" prop="questionQuantity">
                                <el-input-number v-model="formData.questionQuantity" size="small"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                            <el-form-item label="Orden" prop="sort">
                                <el-input-number v-model="formData.sort" size="small" :disabled="true"></el-input-number>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="row-bg" :gutter="20">
                        <!--
                        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" align="center">
                            <el-form-item label="Especialidad" prop="isSpecialty">
                                <el-switch v-model="formData.isSpecialty" active-text="SI" inactive-text="NO" @change="onChangeIsSpecialty"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                            <el-form-item label="Carrera" prop="specialties">
                                <el-select v-model="formData.specialties" placeholder="Seleccionar" value-key="id" multiple clearable filterable size="medium" :disabled="!formData.isSpecialty">
                                    <el-option v-for="item in careers" :key="item.id" :label="item.name" :value="item" ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        -->
                    </el-row>
                    <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="Descripción" prop="description">
                                <el-input v-model="formData.description" type="textarea" :row="3" maxlength="255" size="small"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                            <el-button-group>
                                <el-button type="primary" size="small" class="btn-primary" icon="el-icon-circle-plus" @click="onAddQuestion">Agregar Pregunta</el-button>
                            </el-button-group>
                        </el-col>
                    </el-row>
                    <br />
                    <el-row v-loading="isQuestionsLoading">
                        <el-col :span="24">
                        <div style="overflow-x:auto; overflow-y: auto; height:260px;">
                            <div class="divTable redTable">
                            <div class="divTableHeading">
                                <div class="divTableRow">
                                <div class="divTableHead center" style="min-width:90px;">CÓDIGO</div>
                                <div class="divTableHead" style="min-width:400px;">TÍTULO</div>
                                <div class="divTableHead center" style="min-width:180px;">CATEGORIA</div>
                                <div class="divTableHead center" style="min-width:80px;">TIPO</div>
                                <div class="divTableHead center" style="min-width:90px;">OPCIONES</div>
                                </div>
                            </div>
                            </div>
                            <draggable v-model="formData.simulatorSectionQuestions" @change="onChangeDrag(formData.simulatorSectionQuestions)" draggable=".item">
                                <div v-for="(element, index) in formData.getQuestions()" :key="element.id" class="item">
                                    <div class="divTable redTable">
                                        <div class="divTableBody">
                                            <div class="divTableRow">
                                            <div class="divTableCell center" style="min-width:90px;">{{ element.simulatorQuestion.code }}</div>
                                            <div class="divTableCell" style="min-width:400px;">{{ element.simulatorQuestion.name }}</div>
                                            <div class="divTableCell center" style="min-width:180px;">
                                                <el-tag type="default" effect="plain">
                                                {{ element.simulatorQuestion.simulatorQuestionCategory.name }}
                                                </el-tag>
                                            </div>
                                            <div class="divTableCell center" style="min-width:80px;">
                                                <el-tag v-if="element.simulatorQuestion.type === 1" type="info" effect="plain">PUBLICO</el-tag>
                                                <el-tag v-if="element.simulatorQuestion.type === 2" type="success" effect="plain">PRIVADO</el-tag>
                                                <el-tag v-if="element.simulatorQuestion.type === 3" type="warning" effect="plain">AMBAS</el-tag>
                                            </div>
                                            <div class="divTableCell center" style="min-width:90px;">
                                                <el-button size="small" type="default" @click="onRemoveQuestion(index)" icon="el-icon-delete" ></el-button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        </el-col>
                    </el-row>
                </fieldset>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="onCancel" size="small" class="btn-cancel"
                >Cancelar</el-button
                >
                <el-button
                type="primary"
                @click="onSubmitForm('formData')"
                :disabled="!onCanSaveOrUpdate()"
                class="btn-primary"
                size="small"
                >Aceptar</el-button
                >
            </span>
        </el-dialog>
        <modal-simulator-question-view
        :formData="itemQuestion"
        :conf="modalItemQuestion"
        v-if="modalItemQuestion.isVisible">
        </modal-simulator-question-view>
    </div>
</template>