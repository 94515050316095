<script>
import ModalCoursePublicActivityComponent from '../features/modals/ModalCoursePublicActivityComponent';
export default {
  extends: ModalCoursePublicActivityComponent,
  name: 'ModalCoursePublicActivityView',
};
</script>

<template>
  <el-dialog
    :title="formData.title"
    :visible.sync="conf.isVisible"
    width="42%"
    custom-class="course-public-activity-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-row v-if="formData">
      <el-col :span="24" align="center">
        <div style="padding:20px;" v-html="formData.description"></div>
        <iframe
            v-if="formData.videoUrl"
            :src="formData.videoUrl"
            width="750"
            height="450"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen />
        <iframe
            v-if="currentPdfViewer"
            id="file-wrapper-viewer" :src="currentPdfViewer" @load="onBindFileContextMenu"
            width="750"
            height="450"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen />
      </el-col>
    </el-row>
  </el-dialog>
</template>
