import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent';
import AccountProfileViewModel from '../../../core/features/authentication/viewmodels/AccountProfileViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            permissions: null,
            modules: [],
            checkAll: false,
            isIndeterminate: true,
            universities: [],
            knowledgeAreas: [],
            checkedKnowledgeAreas: [],
            isKnowledgeAreas: true,
            collection: [],
            multipleSelection: [],
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Descripción requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new AccountProfileViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            //this.viewModel.onBindForm();
            this.viewModel.findUniversities(`Skip=0&Limit=100000`)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateFormSelectCarrer(valid)) {
                    EventBus.$emit('onSubmitedSelectCareer', this.multipleSelection);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedSelectCareer', null);
        },
        onFindUniversity(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onSearchUniversityResponse(response) {
            this.universities = response;
        },
        onSelectUniversity(data) {
            this.conf.isLoading = true;

            if (data) {
                this.viewModel.findKnowledgeAreas(`UniversityId=${data.id}`)
            } else {
                setTimeout(() => {
                    this.collection = [];
                    this.multipleSelection = []
                    this.checkedKnowledgeAreas = []
                    this.checkAll = false;
                    this.knowledgeAreas = []
                    this.isUniversity = true;
                    this.conf.isLoading = false;
                }, 300);
            }
        },
        onFindKnowledgeAreasResponse(response) {
            setTimeout(() => {
                this.knowledgeAreas = response.data;
                this.isUniversity = false;
                this.conf.isLoading = false;
            }, 300);
        },
        handleCheckAllChange(val) {
            var allKnowledgeAreas = this.viewModel.mapKnowledgeAreas(this.knowledgeAreas);
            this.checkedKnowledgeAreas = val ? allKnowledgeAreas : [];
            this.isIndeterminate = false;
            this.conf.isLoading = true;

            if (this.checkedKnowledgeAreas.length > 0) {
                this.viewModel.findAllCareersByKnowledgeAreaIds(this.checkedKnowledgeAreas)
            } else {
                setTimeout(() => {
                    this.collection = [];
                    this.conf.isLoading = false;
                }, 300);
            }
        },
        handleCheckedKnowledgeAreasChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.knowledgeAreas.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.knowledgeAreas.length;
            this.conf.isLoading = true;

            if (this.checkedKnowledgeAreas.length > 0) {
                this.viewModel.findAllCareersByKnowledgeAreaIds(this.checkedKnowledgeAreas)
            } else {
                setTimeout(() => {
                    this.collection = [];
                    this.conf.isLoading = false;
                }, 300);
            }
        },
        onFindAllCareersByKnowledgeAreaIds() {
            if (this.checkedKnowledgeAreas.length > 0) {
                this.viewModel.findAllCareersByKnowledgeAreaIds(this.checkedKnowledgeAreas)
            } else {
                // Mensaje de selecionar minimo un área
            }
        },
        onFindAllCareersByKnowledgeAreaIdsResponse(response) {
            setTimeout(() => {
                this.collection = response.data;
                this.conf.isLoading = false;
            }, 300);
        },
        clearSelection() {
            this.universities = []
            this.knowledgeAreas = []
            this.checkedKnowledgeAreas = []
            this.isKnowledgeAreas = true
            this.collection = []
            this.multipleSelection = []
            this.formData.university = null
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}