import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class CourseSubjectViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static ACTIVITY_TYPE_LECTURE = 1;
    static ACTIVITY_TYPE_TEST = 2;

    static UPDATE_STATES = {
        ADD: 2,
        EDIT: 3,
        REMOVE: 4,
    }

    bindFormView() {
        this.api.courseSubjectActivityTypes.find()
            .then((response) => {
                this.view.onSearchCourseSubjectActivityTypesResponse(response.data);
                setTimeout(() => {
                    this.view.conf.isLoading = false;
                }, 400);
            })
            .catch(this.view.onError)
    }

    getActivityTypeName(collection, activityType) {
        var item = _.find(collection, { id: activityType });
        return (_.isNil(item)) ? '' : item.name;
    }

    getTimeFromMins(mins) {
        return Math.floor(mins / 60) + ':' + (mins % 60)
    }

}