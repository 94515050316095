import QuillEditorHelper from '../../../../core/shared/helpers/QuillEditorHelper';
import SystemHelper from '../../../../core/shared/helpers/SystemHelper';
import FileHelper from '../../../../core/shared/helpers/FileHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel';

export default class CourseSubjectActivityViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }

  //#region CONSTANTS & VARIABLES

  static ACTIVITY_TYPE_LECTURE = 1;
  static ACTIVITY_TYPE_TEST = 2;

  //#endregion

  //#region  BINDERS

  bindFormView() {
    this.api.courseSubjectActivityTypes.find()
      .then(response => {
        this.view.onSearchCourseSubjectActivityTypesResponse(response.data)
        this.bindDescriptionEditor('.description-editor');
        if (this.view.formData.state === 'update') {
          this.view.isActivityFileTemp = (this.view.formData.fileUrl != null) ? true : false;
          // this.view.formData.courseSubjectActivityType = _.find(response.data.data, { id: this.view.formData.courseSubjectActivityType })
        }
        this.view.onBindFormViewResponse();
      })
      .catch(this.view.onError)
  }

  bindDescriptionEditor(selector) {
    this.view.editorDescription = QuillEditorHelper.buildEditor(selector);
    this.view.editorDescription.root.innerHTML = this.view.formData.description || "";
    this.view.editorDescription.on('text-change', () => {
      this.view.formData.description = this.view.editorDescription.root.innerHTML;
    })
  }

  removeAnswerEditor(answerEditors, index) {
    answerEditors.splice(index, 1);
    _.forEach(answerEditors, (answerEditor, index) => {
      answerEditor.selector = `answer_editor_${index}`;
    })
  }

  renderAnswerEditors(answers, editors) {

    if (!_.isEmpty(editors)) {
      this.clearAnswerEditorsView(answers, editors)
    }

    return (!_.isEmpty(answers)) ? _.map(answers, (answer, index) => {
      let currentAnswer = {
        selector: `answer_editor_${index}`,
        component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
        updateState: answer.updateState,
        index: index
      };
      currentAnswer.component.root.innerHTML = answer.title
      currentAnswer.component.on('text-change', () => {
        answers[currentAnswer.index].updateData(currentAnswer.component.root.innerHTML)
      })
      return currentAnswer
    }) : [];
  }

  clearAnswerEditorsView(answers, editors) {
    _.forEach(answers, (answer, index) => {
      let answerEditor = _.find(editors, { selector: `answer_editor_${index}` });
      answer.title = (answerEditor) ? answerEditor.component.root.innerHTML : '';
    })

    let answerEditors = document.querySelectorAll('[class^="answer_editor"]');
    let qlToolbars = document.querySelectorAll('.el-table__row .ql-toolbar');
    let qlContainers = document.querySelectorAll('[class^="answer_editor"] .ql-container');
    _.forEach(answerEditors, (answerEditor, index) => answerEditor.remove());
    _.forEach(qlToolbars, (toolBar, index) => toolBar.remove());
    _.forEach(qlContainers, (container, index) => container.remove());
  }

  //#endregion



  //#region VALIDATORS

  isValidActivityType(collection, activityType, keyId) {
    let item = _.find(collection, { id: activityType })
    return (!_.isNil(item) && item.id === keyId)
  }

  //#endregion

  //#region UTILERIAS

  convertFileToBase64(file) {
    return FileHelper.toBase64(file);
  }

  //#endregion


}