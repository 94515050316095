import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SessionHelper from '../../../shared/helpers/SessionHelper'
import PermissionHelper from '../../../shared/helpers/PermissionHelper'

export default class AuthViewModel extends BaseViewModel {
  constructor (view) {
    super(view)
  }

  login (data) {
    this.api.authentication.login(data)
      .then(response => this.view.onLoginResponse(response.data))
      .catch(this.view.onLoginError)
  }

  loginFacebook (data) {
    this.api.authentication.loginFacebook(data)
      .then(response => this.view.onLoginResponse(response.data))
      .catch(this.view.onLoginError)
  }

  logout () {
    this.api.authentication.logout()
      .then(response => this.view.onLogoutResponse(response.data))
      .catch(this.view.onError)
  }

  profile() {
    this.api.users.findProfile()
      .then(response => this.view.onFindProfileResponse(response.data))
      .catch(this.view.onError)
  }

  setSession (token) {
    SessionHelper.setSession(token)
  }

  setProfilePermissions (profile) {
    const permissions = _.map(profile.permissions, permission => permission.value)
    PermissionHelper.setPermissions(permissions)
  }
}
