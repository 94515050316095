import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper';
import FileHelper from '../../../shared/helpers/FileHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class CourseSubjectTestViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static UPDATE_STATES = {
        ADD: 2,
        EDIT: 3,
        REMOVE: 4,
    }

    bindTestFormView(formData) {
        setTimeout(() => {
            this.api.testTimerTypes.find()
                .then((response) => {
                    this.view.timerTypes = response.data.data;
                    // formData.courseSubjectTestActivities = this.bindSections(formData.courseSubjectTestActivities)
                    
                    if (formData.timerType) {
                        formData.timerType = _.find(response.data.data, { id: formData.timerType })
                        this.view.isTestTimeType = (formData.timerType.id === 2);
                        this.view.isSectionTimeType = (formData.timerType.id === 3)
                    } else {
                        formData.timerType = null;
                    }
                    this.clearEditorsView();
                    this.renderEditors(formData.courseSubjectTestActivities, 'instruction_editor');
                    this.renderEditors(formData.courseSubjectTestActivities, 'description_editor');
                    setTimeout(() => {
                        this.view.conf.isLoading = false
                    }, 400);
                })
                .catch(this.view.onError);
        }, 300);
    }

    removeEditor(editors, index, editorTagName) {
        if(_.isEmpty(editors)) return;
        editors.splice(index, 1);
        _.forEach(editors, (currentEditor, index) => {
            currentEditor.selector = `${editorTagName}_${index}`;
        })
    }

    renderEditors(sections, editorTagName) {
        return (!_.isEmpty(sections)) ? _.map(sections, (section, index) => {
            let currentSection = {
                selector: `${editorTagName}_${index}`,
                component: QuillEditorHelper.buildEditor(`.${editorTagName}_${index}`),
                updateState: section.updateState,
                index: index
            };
            
            if(editorTagName === 'instruction_editor') {
                currentSection.component.root.innerHTML = section.instruction || ""
            } else {
                currentSection.component.root.innerHTML = section.description || ""
            }
            
            currentSection.component.on('text-change', () => {
                if(editorTagName === 'instruction_editor') {
                    section.updateInstruction(currentSection.component.root.innerHTML)
                } else {
                    section.updateDescription(currentSection.component.root.innerHTML)
                }
            })
            return currentSection
        }) : [];
    }

    clearEditorsView() {
        let currentIntructionEditors = document.querySelectorAll(`[class^="instruction_editor"]`);
        let currentDescriptionEditors = document.querySelectorAll(`[class^="description_editor"]`);
        let qlToolbars = document.querySelectorAll('.el-collapse-item .ql-toolbar');
        let qlInstructionContainers = document.querySelectorAll(`[class^="instruction_editor"] .ql-container`);
        let qlDescriptionContainers = document.querySelectorAll(`[class^="description_editor"] .ql-container`);
        _.forEach(currentIntructionEditors, (editor, index) => editor.remove());
        _.forEach(currentDescriptionEditors, (editor, index) => editor.remove());
        _.forEach(qlToolbars, (toolBar, index) => toolBar.remove());
        _.forEach(qlInstructionContainers, (container, index) => container.remove());
        _.forEach(qlDescriptionContainers, (container, index) => container.remove());
    }

    bindQuestionFormView(formData) {
        setTimeout(() => {
            this.view.questionEditor = QuillEditorHelper.buildEditor(`.question_editor`)
            this.view.questionEditor.root.innerHTML = formData.description || ""
            this.view.questionEditor.on('text-change', (delta, oldDelta, source) => {
                this.view.$set(formData, 'description', this.view.questionEditor.root.innerHTML)
                formData.setStateToUpdate()
            });

            this.view.answerEditors = this.renderAnswerEditors(formData.getAnswers(), this.view.answerEditors)

            this.view.conf.isLoading = false
        }, 400);
    }

    isDescriptionValid(rule, value, callback) {
        if (!_.isNil(value) || !_.isEmpty(value)) {
            callback();
        } else {
            callback(new Error("Descripción requerido."));
        }
    }

    removeAnswerEditor(answerEditors, index) {
        answerEditors.splice(index, 1);
        _.forEach(answerEditors, (answerEditor, index) => {
            answerEditor.selector = `answer_editor_${index}`;
        })
    }

    renderAnswerEditors(answers, editors) {

        if (!_.isEmpty(editors)) {
            this.clearAnswerEditorsView(answers, editors)
        }

        return (!_.isEmpty(answers)) ? _.map(answers, (answer, index) => {
            let currentAnswer = {
              selector: `answer_editor_${index}`,
              component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
              updateState: answer.updateState,
              index: index
            };
            currentAnswer.component.root.innerHTML = answer.description || ""
            currentAnswer.component.on('text-change', () => {
              answers[currentAnswer.index].updateData(currentAnswer.component.root.innerHTML)

            })
            return currentAnswer
          }) : [];
    }

    clearAnswerEditorsView(answers, editors) {
        /* _.forEach(answers, (answer, index) => {
            let answerEditor = _.find(editors, { selector: `answer_editor_${index}` });
            answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
        }) */

        let answerEditors = document.querySelectorAll('[class^="answer_editor"]');
        let qlToolbars = document.querySelectorAll('.el-table__row .ql-toolbar');
        let qlContainers = document.querySelectorAll('[class^="answer_editor"] .ql-container');
        _.forEach(answerEditors, (answerEditor, index) => answerEditor.remove());
        _.forEach(qlToolbars, (toolBar, index) => toolBar.remove());
        _.forEach(qlContainers, (container, index) => container.remove());
    }

    convertFileToBase64(file) {
        return FileHelper.toBase64(file);
    }
}