import axios from 'axios'
import SessionHelper from '../shared/helpers/SessionHelper';
axios.interceptors.request.use(function(config) {
    const token = SessionHelper.getAccessToken();
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});
var port = 5000;
export default class ApiWrapper {

    constructor() {
        axios.defaults.baseURL = this.getBaseUrl();
     }
    
    get(url, config) {
        //axios.defaults.baseURL = this.getBaseUrl();
        return axios.get(url, config)
    }

    post(url, data, config) {
        //axios.defaults.baseURL = this.getBaseUrl();
        return axios.post(url, data, config)
    }

    put(url, data, config) {
        //axios.defaults.baseURL = this.getBaseUrl();
        return axios.put(url, data, config)
    }

    delete(url, config) {
        //axios.defaults.baseURL = this.getBaseUrl();
        return axios.delete(url, config)
    }

    all(requestsCollection) {
        //axios.defaults.baseURL = this.getBaseUrl();
        return axios.all(requestsCollection)
    }

    getBaseUrl() {
        if(process.env.NODE_ENV == 'development' && location.hostname == 'localhost') {
            return location.protocol + '//' + location.hostname + ':' + port;
        } 
        return location.protocol + '//' + location.hostname;
    }
}