<script>
import ModalSimulatorSolvedComponent from "../../features/modals/ModalSimulatorSolvedComponent";
import ModalSimulatorResultAnswers from "./ModalSimulatorResultAnswers";

export default {
  extends: ModalSimulatorSolvedComponent,
  name: "ModalSimulatorSolvedView",
  components: {
    "modal-simulator-result-answers-view": ModalSimulatorResultAnswers,
  },
};
</script>
<template>
  <el-dialog
    :title="conf.title"
    custom-class="simulator-solved-view"
    :visible.sync="conf.isVisible"
    :before-close="onAccept"
    :close-on-click-modal="false"
    append-to-body
  >
    <!--
    <div class="simulator-result-top" v-if="simulatorResult">
      <div class="container">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h3>ALUMNO:</h3>
            <h3>{{ simulatorResult.student }}</h3>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <div class="score-tag">
              <div class="inner">
                {{ simulatorResult.finalScore }}
              </div>
            </div>
            <div class="points-label">PUNTOS</div>
            <el-row type="flex" class="row-bg points-info" :gutter="20">
              <el-col :span="24" align="center">
                <h5>PUNTAJE</h5>
                <h6>
                  MIN. {{ simulatorResult.lowScore }} / MAX.
                  {{ simulatorResult.topScore }}
                </h6>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg points-info" :gutter="20">
              <el-col :span="24" align="center">
                <h5>POSICIONAMIENTO</h5>
                <h6>{{ simulatorResult.rank }}</h6>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    -->
    <div v-loading="isLoading">
      <div class="container simulator-result-content">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h3>Información del Alumno</h3>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <el-table
              :data="[simulatorResult]"
              empty-text="No se encontraron resultados."
              v-loading="isItemLoading"
              element-loading-spinner="el-icon-loading"
              :element-loading-text="itemLoadingMessage"
              height="120px"
            >
              <el-table-column label="POSICIONAMIENTO" min-width="150" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-tag type="info" effect="plain">{{ scope.row.rank }}</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="NOMBRE" width="200" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-tag type="info" effect="plain">{{ scope.row.student }}</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="PUNTAJE MINIMO" min-width="140" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-tag type="default" effect="plain">{{ scope.row.lowScore }}</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="PUNTAJE MAXIMO" min-width="150" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-tag type="default" effect="plain">{{ scope.row.topScore }}</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="PUNTAJE FINAL" min-width="140" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-tag type="default" effect="plain">
                        <i class="el-icon-circle-check"></i> {{ scope.row.finalScore }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div class="container simulator-result-content" v-if="simulatorResult">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h3>Resultados de {{ simulatorResult.simulatorName }}</h3>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-table :data="simulatorResult.sectionResults" empty-text="No se encontraron resultados." v-loading="isItemLoading" element-loading-spinner="el-icon-loading" :element-loading-text="itemLoadingMessage" height="300px">
              <el-table-column label="SECCIONES" min-width="300"  align="center">
                  <template slot-scope="scope">
                  <div>
                      <el-tag type="info" effect="plain">{{ scope.row.name }}</el-tag>
                  </div>
                  </template>
              </el-table-column>
              <el-table-column label="" width="150" align="center">
                  <template slot-scope="scope">
                  <div>
                      <el-button
                        type="primary"
                        class="btn-primary"
                        size="small"
                        @click="onGetAnswersResult(scope.row)"
                      >
                        <i class="el-icon-view" />
                        <span class="simulator-result-view-text">Ver Resultados</span>
                      </el-button>
                  </div>
                  </template>
              </el-table-column>
              <el-table-column label="ACIERTOS" width="100" align="center">
                  <template slot-scope="scope">
                  <div>
                      <el-tag type="info" effect="plain">{{ scope.row.totalSuccess }}</el-tag>
                  </div>
                  </template>
              </el-table-column>
              <el-table-column label="PREGUNTAS" width="100" align="center">
                  <template slot-scope="scope">
                  <div>
                      <el-tag type="info" effect="plain">{{ scope.row.totalQuestions }}</el-tag>
                  </div>
                  </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
    <modal-simulator-result-answers-view
      v-if="modalShowResults.isVisible"
      :conf="modalShowResults"
      :form-data="resultAnswers"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="onAccept" size="small" class="btn-cancel"
        >Aceptar</el-button
      >
    </span>
  </el-dialog>
</template>