import Vue from 'vue';
import Router from 'vue-router';

import ModuleAdminDashboardView from './components/home/views/modules/ModuleAdminDashboardView.vue';
import ModuleInstructorDashboardView from './components/home/views/modules/ModuleInstructorDashboardView.vue';
import ModuleStudentDashboardView from './components/home/views/modules/ModuleStudentDashboardView.vue';

// import ListLiveClassView from './components/liveclass/views/ListLiveClassView.vue';
// import ListCoursesView from './components/courses/views/ListCoursesView.vue';

import ModuleLiveClassView from './components/liveclass/views/modules/ModuleLiveClassView.vue';
import LiveClassDetailView from './components/liveclass/views/details/LiveClassDetailView.vue';
import LiveClassInstructorDetailView from './components/liveclass/views/details/LiveClassInstructorDetailView.vue';
import LiveClassExploreDetailView from './components/liveclass/views/details/LiveClassExploreDetailView.vue';


import ModuleInstructorLiveClassView from './components/liveclass/views/modules/ModuleInstructorLiveClassView.vue';
import ModuleExploreLiveClassView from './components/liveclass/views/modules/ModuleExploreLiveClassView.vue';
import ModuleInstructorCourseView from './components/courses/views/modules/ModuleInstructorCourseView.vue';

import ModuleCoursesView from './components/courses/views/modules/ModuleCoursesView.vue';
import ModuleCoursesExploreView from './components/courses/views/modules/ModuleCoursesExploreView.vue';
import CourseDetailView from './components/courses/views/details/CourseDetailView.vue';
import CourseLearnDetailView from './components/courses/views/details/CourseLearnDetailView.vue';
import ModuleStatementView from './components/statements/views/modules/ModuleStatementView.vue';
import ModuleStudentNotifyView from './components/notifications/views/modules/ModuleStudentNotificationsView.vue';
import ModuleStudentAccountView from './components/account/views/ModuleStudentAccountView.vue';
import ModuleInstructorAccountView from './components/account/views/ModuleInstructorAccountView.vue';
import ModuleInstructorNotificationsView from './components/notifications/views/modules/ModuleInstructorNotificationsView.vue';


import ModuleRolesView from './components/authorization/views/modules/ModuleRoleView.vue';
import ModuleUsersView from './components/authorization/views/modules/ModuleUserView.vue';
// import ModuleStudentsView from './components/students/views/ModuleStudentsView.vue';
import ModuleStudentsView from './components/students/views/modules/ModuleStudentsView.vue';
import ModuleInstructorsView from './components/instructors/views/modules/ModuleInstructorsView.vue';
// import ModuleInstructorsView from './components/instructors/views/ModuleInstructorsView.vue';
import ModuleNotificationsView from './components/notifications/views/modules/ModuleNotificationsView.vue';
import ModuleEvaluationsView from './components/evaluations/views/modules/ModuleEvaluationsView.vue';
import ModuleDiscountsView from './components/discounts/views/modules/ModuleDiscountsView.vue';
import ModuleSubscriptionsView from './components/subscriptions/views/modules/ModuleSubscriptionsView.vue';
import ModuleSubscriptionPlansView from './components/subscriptionplans/views/modules/ModuleSubscriptionPlansView.vue';
import ModuleSimulatorsView from './components/simulators/views/modules/ModuleSimulatorsView.vue';
import ModuleResponseActivityAnswerView from './components/responseactivityanswers/views/modules/ModuleResponseActivityAnswerView.vue';
import ModulePostsView from './components/posts/views/modules/ModulePostsView.vue';

import ModuleCourseCategoriesView from './components/settings/views/modules/ModuleCourseCategoriesView.vue';
import ModuleInstructorCourseCategoriesView from './components/settings/views/modules/ModuleInstructorCourseCategoriesView.vue';
import ModuleAdvisoryCitiesView from './components/settings/views/modules/ModuleAdvisoryCitiesView.vue';
import ModuleCareersView from './components/settings/views/modules/ModuleCareersView.vue';
import ModuleHighSchoolsView from './components/settings/views/modules/ModuleHighSchoolsView.vue';
import ModuleUniversitiesView from './components/settings/views/modules/ModuleUniversitiesView.vue';
import ModuleSimulatorConfigurationsView from './components/settings/views/modules/ModuleSimulatorConfigurationsView.vue';
import ModuleKnowledgeAreasView from './components/settings/views/modules/ModuleKnowledgeAreasView.vue';


import LoginView from './components/authentication/views/LoginView.vue';
import PasswordRecoveryView from './components/authentication/views/PasswordRecoveryView.vue';
import PasswordResetView from './components/authentication/views/PasswordResetView.vue';
import AccountRegisterView from './components/authentication/views/AccountRegisterView.vue';
import AccountConfirmationView from './components/authentication/views/AccountConfirmationView.vue';
import PageNotFoundView from './components/shared/views/PageNotFoundView.vue';
import ApiContext from './core/infraestructure/ApiContext';

import IndexPageView from './components/website/views/IndexPageView.vue';
import AboutPageView from './components/website/views/AboutPageView.vue';
import BlogCoursePageView from './components/website/views/BlogCoursePageView.vue';
import BlogCourseSinglePageView from './components/website/views/BlogCourseSinglePageView.vue';
import BlogPageView from './components/website/views/BlogPageView.vue';
import BlogSinglePageView from './components/website/views/BlogSinglePageView.vue';
import ContactPageView from './components/website/views/ContactPageView.vue';
import SiteMapPageView from './components/website/views/SiteMapPageView.vue';
import TermsPoliticsPageView from './components/website/views/TermsPoliticsPageView.vue';
import SupportPageView from './components/website/views/SupportPageView.vue';

import ModuleSimulatorExploreView from './components/simulators/views/modules/ModuleSimulatorExploreView.vue';
import SimulatorStudentDetailView from './components/account/details/SimulatorStudentDetailView.vue';
import SimulatorOfficialDetailView from './components/simulators/views/details/SimulatorOfficialDetailView.vue';


Vue.use(Router);

function guardRoute(to: any, from: any, next: any) {
  if (to.meta.auth) {
    ApiContext.users.findProfile()
    .then((response: any) => {
      if (response.data.isAuthenticated) {
        roleRedirection(response.data.profile.role.name, to, next);
      } else {
        next('/login');
      }
    })
    .catch((error: any) => {
      next('/login');
    });
  } else {
    next();
  }
}

function roleRedirection(roleName: any, to: any, next: any) {
  switch (roleName.toLowerCase()) {
    case 'root':
    case 'administrador':
      (to.path === '/login' || !to.path.includes('/admin')) ? next({ name: 'admin-dashboard' }) : next();
      break;
    case 'profesor':
      (to.path === '/login' || !to.path.includes('/profesor')) ? next({name: 'instructor-dashboard'}) : next();
      break;
    case 'estudiante':
      (to.path === '/login' || !to.path.includes('/estudiante')) ? next({name: 'student-dashboard'}) : next();
      break;
    default:
      next('/login');
      break;
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/index.html',
      name: 'index-page',
      beforeEnter: guardRoute,
      component: IndexPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/',
      name: 'index',
      beforeEnter: guardRoute,
      component: IndexPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/acerca',
      name: 'about',
      beforeEnter: guardRoute,
      component: AboutPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/noticias',
      name: 'blog',
      beforeEnter: guardRoute,
      component: BlogPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/noticias/:id',
      name: 'blog single',
      beforeEnter: guardRoute,
      component: BlogSinglePageView,
      meta: {
        auth: false,
        reload: true,
      },
    },
    {
      path: '/cursos',
      name: 'blog course',
      beforeEnter: guardRoute,
      component: BlogCoursePageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/cursos/:id',
      name: 'blog course single',
      beforeEnter: guardRoute,
      component: BlogCourseSinglePageView,
      meta: {
        auth: false,
        reload: true,
      },
    },
    {
      path: '/contacto',
      name: 'contact',
      beforeEnter: guardRoute,
      component: ContactPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/terminos-y-condiciones',
      name: 'terms-and-politics',
      beforeEnter: guardRoute,
      component: TermsPoliticsPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/soporte',
      name: 'support',
      beforeEnter: guardRoute,
      component: SupportPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/mapa-del-sitio',
      name: 'sitemap',
      beforeEnter: guardRoute,
      component: SiteMapPageView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: guardRoute,
      component: LoginView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/admin/resumen',
      name: 'admin-dashboard',
      beforeEnter: guardRoute,
      component: ModuleAdminDashboardView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/en-vivo',
      name: 'en-vivo',
      beforeEnter: guardRoute,
      component: ModuleLiveClassView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/en-vivo/:id/detalles',
      name: 'admin-live-details',
      beforeEnter: guardRoute,
      component: LiveClassDetailView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/cursos',
      name: 'cursos',
      beforeEnter: guardRoute,
      component: ModuleCoursesView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/estudiantes',
      name: 'estudiantes',
      beforeEnter: guardRoute,
      component: ModuleStudentsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/profesores',
      name: 'profesores',
      beforeEnter: guardRoute,
      component: ModuleInstructorsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/notificaciones',
      name: 'notificaciones',
      beforeEnter: guardRoute,
      component: ModuleNotificationsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/evaluaciones',
      name: 'evaluaciones',
      beforeEnter: guardRoute,
      component: ModuleEvaluationsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/descuentos',
      name: 'descuentos',
      beforeEnter: guardRoute,
      component: ModuleDiscountsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/planes',
      name: 'planes',
      beforeEnter: guardRoute,
      component: ModuleSubscriptionPlansView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/inscripciones',
      name: 'inscripciones',
      beforeEnter: guardRoute,
      component: ModuleSubscriptionsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/simuladores',
      name: 'simuladores',
      beforeEnter: guardRoute,
      component: ModuleSimulatorsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/publicaciones',
      name: 'publicaciones',
      beforeEnter: guardRoute,
      component: ModulePostsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/autorizacion/roles',
      name: 'roles',
      beforeEnter: guardRoute,
      component: ModuleRolesView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/autorizacion/usuarios',
      name: 'usuarios',
      beforeEnter: guardRoute,
      component: ModuleUsersView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/categorias-de-cursos',
      name: 'categorias-cursos',
      beforeEnter: guardRoute,
      component: ModuleCourseCategoriesView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/ciudades-de-asesorias',
      name: 'ciudades-de-asesorias',
      beforeEnter: guardRoute,
      component: ModuleAdvisoryCitiesView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/carreras',
      name: 'carreras',
      beforeEnter: guardRoute,
      component: ModuleCareersView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/universidades',
      name: 'universidades',
      beforeEnter: guardRoute,
      component: ModuleUniversitiesView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/areas-de-conocimiento',
      name: 'areas de conocimiento',
      beforeEnter: guardRoute,
      component: ModuleKnowledgeAreasView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/preparatorias',
      name: 'preparatorias',
      beforeEnter: guardRoute,
      component: ModuleHighSchoolsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/admin/configuraciones/simuladores',
      name: 'configuraciones de simuladores',
      beforeEnter: guardRoute,
      component: ModuleSimulatorConfigurationsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/resumen',
      name: 'instructor-dashboard',
      beforeEnter: guardRoute,
      component: ModuleInstructorDashboardView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/notificaciones',
      name: 'instructor-notifications',
      beforeEnter: guardRoute,
      component: ModuleInstructorNotificationsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/estudiantes',
      name: 'instructor-students',
      beforeEnter: guardRoute,
      component: ModuleStudentsView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/mi-cuenta',
      name: 'instructor-account',
      beforeEnter: guardRoute,
      component: ModuleInstructorAccountView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/en-vivo',
      name: 'instructor-live',
      beforeEnter: guardRoute,
      component: ModuleInstructorLiveClassView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/en-vivo/:id/detalles',
      name: 'professor-live-details',
      beforeEnter: guardRoute,
      component: LiveClassInstructorDetailView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/cursos',
      name: 'instructor-course',
      beforeEnter: guardRoute,
      component: ModuleInstructorCourseView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/actividades/:id?',
      name: 'instructor-activities',
      beforeEnter: guardRoute,
      component: ModuleResponseActivityAnswerView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/profesor/configuraciones/categorias-de-cursos',
      name: 'instructor-course-category',
      beforeEnter: guardRoute,
      component: ModuleInstructorCourseCategoriesView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/resumen',
      name: 'student-dashboard',
      beforeEnter: guardRoute,
      component: ModuleStudentDashboardView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/en-vivo',
      name: 'student-live',
      beforeEnter: guardRoute,
      component: ModuleExploreLiveClassView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/en-vivo/:id/detalles',
      name: 'student-live-details',
      beforeEnter: guardRoute,
      component: LiveClassExploreDetailView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/cursos',
      name: 'student-course',
      beforeEnter: guardRoute,
      component: ModuleCoursesExploreView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/cursos/:id/detalles',
      name: 'student-course-details',
      beforeEnter: guardRoute,
      component: CourseDetailView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/cursos/:id/aprender',
      name: 'student-course-learn',
      beforeEnter: guardRoute,
      component: CourseLearnDetailView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/simuladores',
      name: 'student-live',
      beforeEnter: guardRoute,
      component: ModuleSimulatorExploreView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/simuladores/:id/aplicar',
      name: 'student-live',
      beforeEnter: guardRoute,
      component: SimulatorOfficialDetailView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/simuladores/:id/prueba',
      name: 'simulator-student',
      beforeEnter: guardRoute,
      component: SimulatorStudentDetailView,
      meta: {
        auth: true,
      },
    },
    /* {
      path: '/estudiante/suscripcion',
      name: 'student-statements',
      beforeEnter: guardRoute,
      component: ModuleStatementView,
      meta: {
        auth: true,
      },
    },*/
    {
      path: '/estudiante/notificaciones',
      name: 'student-notifications',
      beforeEnter: guardRoute,
      component: ModuleStudentNotifyView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/actividades/:id',
      name: 'student-notifications',
      beforeEnter: guardRoute,
      component: ModuleStudentNotifyView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/estudiante/mi-cuenta',
      name: 'student-account',
      beforeEnter: guardRoute,
      component: ModuleStudentAccountView,
      meta: {
        auth: true,
      },
    },
    {
      path: '/recuperacion-password',
      name: 'recuperacion-password',
      beforeEnter: guardRoute,
      component: PasswordRecoveryView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/reiniciar-password',
      name: 'reiniciar-password',
      beforeEnter: guardRoute,
      component: PasswordResetView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/registrarse',
      name: 'registrarse',
      beforeEnter: guardRoute,
      component: AccountRegisterView,
      meta: {
        auth: false,
      },
    },
    {
      path: '/confirmar-cuenta',
      name: 'confirmar-cuenta',
      beforeEnter: guardRoute,
      component: AccountConfirmationView,
      meta: {
        auth: false,
      },
    },
    {
      path: '*',
      component: PageNotFoundView,
      beforeEnter: guardRoute,
      meta: {
        auth: false,
      },
    },
  ],
});
