import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SimulatorOfficial from '../models/SimulatorOfficial'

export default class SimulatorPublicViewModel extends BaseViewModel {
	constructor(view) {
		super(view)
	}
	// INICIALIZADORES
	static UPDATE_STATES = {
		ADD: 2,
		EDIT: 3,
		REMOVE: 4,
	}

	//#region CRUD

	createSection() {
		let item = new SimulatorOfficial({
			courseCategory: null,
			isSpecialty: false,
			quantityQuestion: 0,
		});
		return item;
	}

	onDelete(data) {
		let item = new SimulatorOfficial(data);
		item.state = "delete";
		return item;
	}

	onSearchCollection() {
		return this.api.simulators.find(this.getSearchQuery())
	}

	mapCollection(collection) {
		return _.map(collection, (data) => new SimulatorOfficial(data));
	}

	onSearchItem(id) {
		return this.api.simulators.findOne(id)
	}

	onSave(data) {
		if (data.state === 'create')
			return this.api.simulators.create(data.toSend())
		else
			return this.api.simulators.update(data.id, data.toSend());
	}

	onDestroy(context, id) {
		context.api.simulators.delete(id)
			.then(response => context.view.onSuccess(response.data))
			.catch(context.view.onError)
	}

	onCancel(context) {
		context.view.item = null;
	}

	generateTemporalSimulator(data) {
		this.api.simulators.generateTemporalSimulator(this.toSend(data))
            .then(response => this.view.onGenerateTemporalSimulatorResponse(response.data))
			.catch(this.view.onError)
	}

	toSend(item) {
        return {
            difficultyLevel: item.difficultyLevel.id,
            timerType: (item.timerType) ? item.timerType.id : null,
            isTimer: item.isTimer,
            duration: item.duration,
            sections: this.mapSections(item.simulatorSections)
        }
    }

	mapSections(collection) {
        return _.map(collection, (item) => {
            var current = {
                questionQuantity : item.questionQuantity,
				isSpecialty : item.isSpecialty,
                simulatorQuestionCategoryId: item.simulatorQuestionCategory.id,
            };
            return current;
        })
    }

	// #endregion

	// #region BINDING DATA
	bindFormView() {
		this.api.difficultyLevels.find()
		.then((response) => {
			this.view.difficultyLevels = response.data.data;
			/*return this.api.simulators.getQuestionCategoriesByUniversities()
		})
		.then((response) => {
			this.view.simulatorQuestionCategories = response.data.data;
			this.view.availebleCategories = response.data.data;*/
		})
		.catch(this.view.onError)
	}


	// #endregion

	// #region QUERIES
	/**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
	findSimulatorOfficialExamTypes(data) {
		this.api.simulatorExamnTypes.find(data)
			.then((response) => this.view.onFindSimulatorOfficialExamTypesResponse(response.data.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findCareers(data) {
		this.api.careers.find(data)
			.then((response) => this.view.onSearchCareersResponse(response.data.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	 findQuestionCategoriesByUniversities(data) {
		this.api.simulators.getQuestionCategoriesByUniversities(`DifficultyLevel=${data}`)
			.then((response) => {
				this.view.simulatorQuestionCategories = response.data.data;
				this.view.availebleCategories = response.data.data;
			})
			.catch(this.view.onError)
	}


	// #endregion

	//#region MAPPING DATA

	deletionSection(sectionIndex, simulatorSections) {
		simulatorSections.splice(sectionIndex, 1);
	}

	changeSimulatorQuestionCategory(selectedCategory, category) {
		if(!_.isEmpty(category)) {
			console.log(category)
			selectedCategory = selectedCategory.filter((item) => item.id !== category.id);

			selectedCategory.push(category);
			console.log(selectedCategory)
		}
	}

	updateCategoryIndex(selectedCategory, simulatorSections) {
		simulatorSections.forEach((item, index) => {
			
			if (item.simulatorQuestionCategory.id == selectedCategory[index].id) {
				selectedCategory[index].index = index
			}
		})
	}

	// #endregion

	// #region VALIDATIONS
	/**
	 * Metodo para validación de formulario de simulador para verificar que vengan secciones.
	 */
	validateForm(valid) {
		var isValid = false
		if (_.size(this.view.formData.simulatorSections) != 0) {
			if (valid) isValid = true
		} else {
			if (valid) {
				this.view.onError(new Error(
					'Ocupa al menos agregar una sección.'
				))
			}
		}
		return isValid
	}

	questionNumberValidator(rule, value, callback) {
        var regex = /(\d+)/g;

		setTimeout(() => {
			if (value) {
				if (value >= 1) {
					this.api.simulatorQuestions
						.find(`SimulatorQuestionCategoryId=${this.view.formData.simulatorSections[rule.field.match(regex)].simulatorQuestionCategory.id}&Skip=0&Limit=100000`)
						.then((response) => {						
							if (response.data.data.length >= value) {
								callback();
							} else {
								callback(new Error('No existen disponibles la cantidad de preguntas solicitadas para esta sección.'));
								this.view.onError(new Error(
									'No existen disponibles la cantidad de preguntas solicitadas para esta sección.'
								))
							}
						})
				} else {
					callback(new Error('Valor no válido.'));
				}
			} else {
				callback(new Error('No. de Preguntas requerido.'));
			}
			this.view.conf.isLoading = false;
		}, 500);
		
	}

	/**
   * Metodo para validación de formulario para verificar que venga el numero de preguntas.
   */
	 validateFormSection(formData) {
		var isValid = false
		if (!_.isUndefined(formData.difficultyLevel) && !_.isEmpty(formData.difficultyLevel)) {
			isValid = true
		} else {
			this.view.onError(new Error(
				'Ocupa Seleccionar la dificultad.'
			))
		}
		return isValid
	}

	// #endregion
}
