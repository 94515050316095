import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import RoleViewModel from '../../../../core/features/authorization/viewmodels/RoleViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'Authorization-Roles',
            viewModel: null,
            permissions: null,
            modules: [],
            permissions: [],
            modulesWithPermissions: [],
            claims: [],
            rules: {
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new RoleViewModel(this)
        this.onActive();
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData);
        },
        verifyRole(rule, value, callback) { 
            this.viewModel.verifyRoleName(this.formData.id, value, callback) 
        },
        handleCheckAllChange(sysModule) {
            sysModule.permissionSelected = sysModule.checkAll ? this.viewModel.onMapAllPermissions(sysModule.permissions) : [];
            sysModule.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value, sysModule) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === sysModule.permissions.length;
            sysModule.isIndeterminate = checkedCount > 0 && checkedCount < sysModule.permissions.length;
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formData.claims = this.claims // this.viewModel.onMapAllPermissionsToSend(this.modules);
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanCreate() {
            return this.canCreate(this.modulePermissions)
        },
        onCanUpdate() {
            return this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedRole', null);
        }
    }
}