import ItemComponent from "../../../../core/infraestructure/ItemComponent";
import CourseDetailViewModel from "../../../../core/features/course/viewmodels/CourseDetailViewModel";

export default {
  extends: ItemComponent,
  data() {
    return {
      viewModel: null,
      activityPreview: null,
      modalItemCoursePublicActivity: {
        isVisible: false,
        isLoading: false
      },
    };
  },
  props: {
    courseSubject: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CourseDetailViewModel(this);
  },
  methods: {
    orderActivities(collection) {
      return _.sortBy(collection, "sort");
    },
    onShowPublicActivity(activity) {
      this.activityPreview = activity;
      this.viewModel.showModal(this.modalItemCoursePublicActivity, true);
    },
    showIconActivityType(activity) {
      if (this.isActivityLecture(activity)) return "uil uil-file icon_142";
      if (this.isActivityVideo(activity)) return "uil uil-play-circle icon_142";
      if (this.isActivityFile(activity))
        return "uil uil-file-download-alt icon_142";
      if (this.isActivityMultipleChoicesQuestion(activity))
        return "uil uil-file-check-alt icon_142";
      if (this.isActivityOpenQuestion(activity))
        return "uil uil-file-alt icon_142";
      if (this.isActivityMathQuestion(activity))
        return "uil uil-file-alt icon_142";
    },
    isActivityLecture(activity) {
      return this.viewModel.isValidActivityType(
        this.courseSubjectActivityTypes,
        activity.courseSubjectActivityType,
        CourseDetailViewModel.ACTIVITY_TYPE_LECTURE
      );
    },
    isActivityVideo(activity) {
      return this.viewModel.isValidActivityType(
        this.courseSubjectActivityTypes,
        activity.courseSubjectActivityType,
        CourseDetailViewModel.ACTIVITY_TYPE_VIDEO
      );
    },
    isActivityFile(activity) {
      return this.viewModel.isValidActivityType(
        this.courseSubjectActivityTypes,
        activity.courseSubjectActivityType,
        CourseDetailViewModel.ACTIVITY_TYPE_FILE
      );
    },
    isActivityMultipleChoicesQuestion(activity) {
      return this.viewModel.isValidActivityType(
        this.courseSubjectActivityTypes,
        activity.courseSubjectActivityType,
        CourseDetailViewModel.ACTIVITY_TYPE_MULTIPLE_CHOICES_QUESTION
      );
    },
    isActivityOpenQuestion(activity) {
      return this.viewModel.isValidActivityType(
        this.courseSubjectActivityTypes,
        activity.courseSubjectActivityType,
        CourseDetailViewModel.ACTIVITY_TYPE_OPEN_QUESTION
      );
    },
    isActivityMathQuestion(activity) {
      return this.viewModel.isValidActivityType(
        this.courseSubjectActivityTypes,
        activity.courseSubjectActivityType,
        CourseDetailViewModel.ACTIVITY_TYPE_MATH_QUESTION
      );
    },
  },
};
