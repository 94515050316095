<script>
import HeaderView from '../../shared/views/HeaderView';
import MainMenuStudentView from '../../shared/views/MainMenuStudentView';
import MainFooterView from '../../shared/views/MainFooterView';
import ModuleStudentAccountComponent from '../features/ModuleStudentAccountComponent';
import ModalStudentView from '../../students/views/modals/ModalStudentView';
import ModalStudentAccountSubscriptionView from './ModalStudentAccountSubscriptionView';
import ModalStudentInfoView from './ModalStudentInfoView';

export default {
  extends: ModuleStudentAccountComponent,
  components: {
    'header-view': HeaderView,
    'main-menu-student-view': MainMenuStudentView,
    'main-footer-view': MainFooterView,
    'modal-student-view': ModalStudentView,
    'modal-student-account-subscription-view': ModalStudentAccountSubscriptionView,
    'modal-student-info-view': ModalStudentInfoView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <div>
    <header-view></header-view>
    <main-menu-student-view></main-menu-student-view>
    <!-- Body Start -->
    <!-- Body Start -->
    <div class="wrapper _bg4586">
      <div class="_216b01">
        <div class="container-fluid">
          <div class="row justify-content-md-center">
            <div class="col-md-10">
              <div class="section3125 rpt145">
                <div class="row">
                  <div class="col-lg-7">
                    <!-- <a href="#" class="_216b22">
                      <span><i class="uil uil-cog"></i></span>Setting
                    </a> -->
                    <div class="dp_dt150">
                      <div class="img148">
                        <img :src="profileImageUrl" alt="" />
                      </div>
                      <div class="prfledt1" v-if="session">
                        <h2>
                          {{ session.user.firstName }}
                          {{ session.user.lastName }}
                        </h2>
                        <span>{{ session.user.email }}</span>
                      </div>
                    </div>
                    <ul class="_ttl120" v-if="session && session.student">
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">Favoritos</div>
                          <div class="_ttl123">{{session.student.totalFavorites}}</div>
                        </div>
                      </li>
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">Te gustan</div>
                          <div class="_ttl123">{{session.student.totalLiked}}</div>
                        </div>
                      </li>
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">No te gustan</div>
                          <div class="_ttl123">{{session.student.totalDisliked}}</div>
                        </div>
                      </li>
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">En curso</div>
                          <div class="_ttl123">{{session.student.totalEnrolled}}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-5">
                    <!-- <a href="setting.html" class="_216b12">
                      <span><i class="uil uil-cog"></i></span>Setting
                    </a> -->
                    <div class="rgt-145">
                      <ul
                        class="tutor_social_links"
                        v-if="session && session.student"
                      >
                        <li v-if="session.student.pageUrlFacebook">
                          <a :href="session.student.pageUrlFacebook" class="fb" target="_blank"
                            ><i class="fab fa-facebook-f"></i
                          ></a>
                        </li>
                        <li v-if="session.student.pageUrlInstagram">
                          <a
                            :href="session.student.pageUrlInstagram"
                            target="_blank"
                            class="in"
                            ><i class="fab fa-instagram"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <ul class="_bty149">
                      <li>
                        <button
                          class="studio-link-btn btn500 btn-primary"
                          @click="onEditProfile"
                        >
                          Editar
                        </button>
                      </li>
                      <!-- <li>
                        <button
                          class="msg125 btn500"
                          onclick="window.location.href = 'setting.html';"
                        >
                          Edit
                        </button>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215b15">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div
                    class="nav nav-tabs tab_crse"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link active"
                      id="nav-about-tab"
                      data-toggle="tab"
                      href="#nav-about"
                      role="tab"
                      aria-selected="true"
                      >Acerca de mi</a
                    >

                    <a
                      class="nav-item nav-link"
                      id="nav-subscriptions-tab"
                      data-toggle="tab"
                      href="#nav-subscriptions"
                      role="tab"
                      aria-selected="false"
                      >Subscripci&oacute;n</a
                    >
                    <!-- <a
                      class="nav-item nav-link"
                      id="nav-reviews-tab"
                      data-toggle="tab"
                      href="#nav-reviews"
                      role="tab"
                      aria-selected="false"
                      >Reseñas</a
                    >-->
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215b17">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tab_content">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-about"
                    role="tabpanel"
                  >
                    <div class="_htg451">
                      <div class="_htg452">
                        <h3>Acerca de mi</h3>
                        <p>
                          {{ sessionAbout }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-subscriptions"
                    role="tabpanel"
                  >
                    <div class="_htg451">
                      <div class="_htg452">
                        <h3>Subscripci&oacute;n</h3>
                        <el-row :gutter="20" class="section-ctrl">
                          <el-col>
                            <el-button
                            type="primary"
                            size="small"
                            class="btn-primary"
                            @click="onUpdateSubscription">
                              Actualizar Inscripci&oacute;n
                            </el-button>
                          </el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col>
                            <el-table
                              :data="subscriptions"
                              style="width: 100%">
                              <el-table-column label="PLAN" min-width="230">
                                 <template slot-scope="scope">
                                  <div>
                                      {{ scope.row.subscriptionPlan }}
                                    </div>
                                  </template>
                              </el-table-column>
                              <!-- <el-table-column
                                label="DESCUENTO">
                                <template slot-scope="scope">
                                  <div v-if="scope.row.discount">
                                      {{ scope.row.discount }}
                                    </div>
                                  </template>
                              </el-table-column>-->
                              <el-table-column
                                label="PAGO" align="center"
                                width="140">
                                <template slot-scope="scope">
                                  <div>
                                      {{ formatMoney(scope.row.amount) }}
                                    </div>
                                  </template>
                              </el-table-column>
                              <el-table-column
                                label="FECHA DE PAGO" align="center" width="140">
                                <template slot-scope="scope">
                                  <div>
                                      {{ scope.row.subscriptionDate | formatDate}}
                                    </div>
                                  </template>
                              </el-table-column>
                              <el-table-column
                                label="FIN SUSCRIPCIÓN" align="center" width="150">
                                <template slot-scope="scope">
                                  <div>
                                      {{ scope.row.subscriptionEndDate | formatDate}}
                                    </div>
                                  </template>
                              </el-table-column>
                            
                            </el-table>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-reviews" role="tabpanel">
                    <div class="student_reviews">
                      <div class="row">
                        <div class="col-lg-12">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main-footer-view></main-footer-view>
    </div>
    <!-- Body End -->
    <!-- Body End -->

    <modal-student-view
      :formData="item"
      :conf="modalItem"
      :showStatus="false"
      v-if="modalItem.isVisible"
      :show-status="false"
    ></modal-student-view>
    <modal-student-account-subscription-view
    :formData="subscriptionItem"
    :conf="modalSubscriptionItem"
    v-if="modalSubscriptionItem.isVisible">
    </modal-student-account-subscription-view>
    <modal-student-info-view
    :formData="studentInfoItem"
    :conf="modalStudentInfoItem"
    v-if="modalStudentInfoItem.isVisible">
    </modal-student-info-view>
    <div v-loading.fullscreen.lock="fullLoader"></div>
  </div>
</template>
