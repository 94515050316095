import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent';
import AccountProfileViewModel from '../../../core/features/authentication/viewmodels/AccountProfileViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: '',
            isFormProcessing: false,
            session: null,
            item: null,
            sessionAbout: '',
            modalItem: {
                isVisible: false,
                isLoading: false,
            },
            universities: [],
            careers: [],
            careersToApply: [],
            highSchools: [],
            profileImageUrl: '/images/hd_dp.jpg',
            currentUniversity : null,
            currentCareer : null,
            currentHighSchool : null,
            isNewHighSchool: false,
            collection: [],
            modalItemCareers: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                careerToApply: [
                    { required: true, message: "Carrera requerida.", trigger: "blur" },
                ],
                highSchool: [
                    { required: true, message: "Preparatoria requerida.", trigger: "blur" },
                ],
                universitiesToApply: [
                    { validator: this.validateUnivesities },
                ]
            }
        };
    },
    created() {
        this.viewModel = new AccountProfileViewModel(this);
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedSelectCareer', this.onAddTrace);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSelectCareer', this.onAddTrace);
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    methods: {
        onActive() {
            this.viewModel.bindFormStudentInfoView();
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    if(_.isString(this.formData.highSchool)) this.formData.highSchool = this.currentHighSchool;
                    this.viewModel.updateStudentInfo(this.formData)
                }
            });
        },
        onSearchUniversities(data) {
            if(data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = [];
            }
        },
        onSearchUniversityResponse(response) {
            this.universities = response;
        },
        onChangeSelectUniversities(data) {
            if(_.isString(data)) {
                this.viewModel.createUniversities(data)
            }
        },
        onLoadUniversity(response) {
            this.currentUniversity = response;
        },
        onSearchHighSchools(data) {
            if(data) {
                this.viewModel.findHighSchools(`Name=${data}`)
            } else {
                this.highSchools = [];
            }
        },
        onSearchHighSchoolsResponse(response) {
            this.highSchools = response;
        },
        onChangeSelectHighSchools(data) {
            if(!_.isEmpty(data) && _.isString(data)) {
                this.viewModel.createHighSchools(data)
            }
        },
        onSearchCareers(data) {
            if(data) {
                this.viewModel.findCareers(`Name=${data}`)
            } else {
                this.careers = [];
            }
        },
        onSearchCareersResponse(response) {
            this.careers = response;
        },
        onChangeSelectCareers(data) {
            if(!_.isEmpty(data) && _.isString(data)) {
                this.viewModel.createCareers(data)
            }
        },
        onLoadCareer(response) {
            this.currentCareer = response;
        },
        onActiveNewHighSchool() {
            this.currentHighSchool = null;
            this.isNewHighSchool = true;
        },
        onAddHighSchool(data) {
            if(!_.isEmpty(data) && _.isString(data)) {
                this.conf.isLoading = true;
                this.viewModel.createHighSchools(data)
            }
        },
        onLoadHighSchool(response) {
            setTimeout(() => {
                //this.formData.highSchool = response;
                this.$set(this.formData, 'highSchool', response);
                this.highSchools.push(response);
                this.$forceUpdate()
                this.isNewHighSchool = false;
                this.currentHighSchool = null;
                this.conf.isLoading = false;
            }, 200);
        },
        onUpdateStudentInfoResponse(response) {
            this.$confirm(response.message, 'Información', {
                confirmButtonText: 'Aceptar',
                type: 'success',
                confirmButtonClass:'btn-primary',
                showCancelButton: false,
            })
            .then(() => {
                this.conf.isVisible = false;
                EventBus.$emit('onFinishStudentInfo', null)
            })
            .catch(() => {
                
            });
        },
        validateUnivesities(rule, value, callback) {
            if(_.isEmpty(this.universitiesToApply)) {
                callback(new Error('Seleccionar al menos una universidad'));
            } else {
                callback();
            }
        },
        onChangeUniveristy(data) {
            this.$set(this.formData, 'careersToApply', []);
            this.careers = []
            if(data)
                this.viewModel.findCareers(`UniversityId=${data.id}`)
        },
        onFindCareersResponse(response) {
            this.careers = response.data;
        },
        onSelectCareers(){
            this.itemCareers = new Object({
                state: "create",
            });
            this.viewModel.showModal(this.modalItemCareers, true);
        },
        onAddTrace(data) {
            this.viewModel.showModal(this.modalItemCareers, false);
            this.viewModel.addTrace(data)
        },
        onChangeItemOptions(option) {
            if (option.command === 'delete') this.onDeleteCareer(option.index);
        },
        onDeleteCareer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    this.showLoading(true, 'Eliminando Carrera');
                    this.viewModel.removeCareer(index);
                    this.$forceUpdate();
                })
                .catch(() => {});
        },
    },
}