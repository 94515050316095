import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SimulatorOfficialDetailViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialDetailViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            simulatorResult: null,
            modalShowResults: {
                isVisible: false,
                isLoading: false,
            },
            resultAnswers: null,
            colors: [
                { color: '#c70831', percentage: 69 },
                { color: '#dfbf0a', percentage: 89 },
                { color: '#10af38', percentage: 100 }
            ],
            isLoading: true,
        };
    },
    props: {
        simulatorId: {
            required: true,
            type: String
        }
    },
    mounted() {
        // EventBus.$on('onChangeQuestion', this.onChangeQuestion);
    },
    beforeDestroy() {
        // EventBus.$off('onChangeQuestion', this.onChangeQuestion);
    },
    created() {
        this.viewModel = new SimulatorOfficialDetailViewModel(this);
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.findSimulatorResult(this.simulatorId)
        },
        onFindSimulatorResultResponse(response) {
            setTimeout(() => {
                this.simulatorResult = response.data
            }, 400);
            setTimeout(() => {
                this.isLoading = false
            }, 400);
        },
        onFinish() {
            this.isLoading = true
            setTimeout(() => {
                this.isLoading = false
                this.simulatorResult = null
            }, 800);
            setTimeout(() => {
                this.$router.push('/estudiante/simuladores')
            }, 810);
        },
        onRepeat() {
            this.$confirm(`Todos tus resultados serán reiniciados, ¿Estas seguro de repetir el simulador?`, 'Alerta', {
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'No',
                type: 'warning',
                closeOnPressEscape: false,
                closeOnClickModal: false,
                confirmButtonClass: 'btn-primary',
                cancelButtonClass: 'btn-cancel',
                showCancelButton: true,
            }).then(() => {
                this.isLoading = true
                this.viewModel.repeatSimulator(this.simulatorId)
            }).catch((error) => {

            });
        },
        onRepeatSimulatorResponse() {
            window.location.reload()
        },
        getStatusTag(percentage) {
            return this.viewModel.getStatusTag(percentage)
        },
        onGetAnswersResult(simulator) {
            this.isLoading = true
            this.viewModel.getResultAnswersBySimulatorSection(this.simulatorId, simulator.id)  
        },
        onGetResultAnswersBySimulatorSectionResponse(response) {
            setTimeout(() => {
                this.resultAnswers = response.data
                this.isLoading = false
                this.modalShowResults.isVisible = true
            }, 400);
        }
    },
};