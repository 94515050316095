import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SubscriptionPlan from '../models/SubscriptionPlan'

export default class SubscriptionPlanViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new SubscriptionPlan({
      state: "create",
      status: 1
    });
    return item;
  }

  onDelete(data) {
    let item = new SubscriptionPlan(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new SubscriptionPlan(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.subscriptionPlans.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new SubscriptionPlan(data));
  }

  onSearchItem(id) {
    return this.api.subscriptionPlans.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.subscriptionPlans.create(data.toSend())
    else
      return this.api.subscriptionPlans.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.subscriptionPlans.delete(id)
        .then(response => context.view.onSuccess(response.data))
        .catch(context.view.onError)
}

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.subscriptionPlans.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.subscriptionPlans.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView(formData) {
    if (formData.state === 'update') {
      
    }
    setTimeout(() => {
      this.showModalLoading(this.view.conf, false);
    }, 400);
  }

  // #endregion

  // #region QUERIES
  

  // #endregion

  //#region MAPPING DATA
  /**
   * Metodo para regresar el type label del tipo de la inscripción
   */
  mapSubscriptionTypesLabel(type, subscriptionPlanConfigs) {
    let typeLabel;
    switch (type) {
      case subscriptionPlanConfigs.SUBSCRIPTION_PLANS.ON_SITE:
        typeLabel =
          subscriptionPlanConfigs.SUBSCRIPTION_PLANS_LABEL.ON_SITE;
        break;
        case subscriptionPlanConfigs.SUBSCRIPTION_PLANS.ON_LINE_ONE_MONTH:
        typeLabel =
          subscriptionPlanConfigs.SUBSCRIPTION_PLANS_LABEL.ON_LINE_ONE_MONTH;
        break;
        case subscriptionPlanConfigs.SUBSCRIPTION_PLANS.ON_LINE_THREE_MONTH:
        typeLabel =
          subscriptionPlanConfigs.SUBSCRIPTION_PLANS_LABEL.ON_LINE_THREE_MONTH;
        break;
        case subscriptionPlanConfigs.SUBSCRIPTION_PLANS.ON_LINE_SIX_MONTH:
        typeLabel = subscriptionPlanConfigs.SUBSCRIPTION_PLANS_LABEL.ON_LINE_SIX_MONTH;
        break;
        case subscriptionPlanConfigs.SUBSCRIPTION_PLANS.ON_SITE_DUE_DATE:
        typeLabel = subscriptionPlanConfigs.SUBSCRIPTION_PLANS_LABEL.ON_SITE_DUE_DATE;
        break;
      default:
        break;
    }
    return typeLabel;
  }
  
  // #endregion

  // #region VALIDATIONS

  // #endregion
}
