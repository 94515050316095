import moment from "moment";
import BaseModel from "../../../infraestructure/BaseModel"
import Instructor from "../../instructors/models/Instructor";
import CourseSection from "./CourseSection"

export default class Course extends BaseModel {

  constructor(options, state) {
    super(options, state)
  }

  toSend() {
    return {
      id: this.id,
      code: this.code,
      title: this.title,
      description: this.description,
      about: this.about,
      promotionalVideoUrl: this.promotionalVideoUrl,
      coverImageUrl: this.coverImageUrl,
      isPublished: this.isPublished,
      commentsAvailable: this.commentsAvailable,
      tags: this.tags,
      courseCategory: (this.courseCategory) ? this.courseCategory.id : null,
      universityId: (this.university) ? this.university.id : null,
      courseSections: this.courseSections.map(item => item.toSend()),
      instructors: this.instructors,
    }
  }

  //#region COURSE SECTIONS

  getCourseSections() {
    return _.sortBy(this.courseSections.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
  }

  getTotalSections() {
    return _.size(this.getCourseSections());
  }

  getTotalDuration() {
    return _.sumBy(this.getCourseSections(), (item) => item.getTotalDuration());
  }

  getDuration() {
    let totalDuration = this.getTotalDuration();
    return moment.utc(moment.duration(totalDuration, "minutes").asMilliseconds()).format("HH:mm")
}

  createCourseSection() {
    return new CourseSection({
      sort: this.getNextCourseSectionIndex(),
      updateState: this.UPDATE_STATUS.ADDED,
      courseSubjects: []
    }, 'create')
  }

  editCourseSection(data) {
    data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
    return new CourseSection(data, 'update');
  }

  addCourseSection(data) {
    this.courseSections.push(new CourseSection(data, 'create'));
  }

  updateCourseSection(selectedIndex, data) {
    let section = this.getCourseSections()[selectedIndex]
    var index = _.findIndex(this.courseSections, section)
    if (index != -1) {
      this.courseSections[index] = data
    } else {
      throw new Error("Sección no encontrada.")
    }
  }

  updateCourseSectionPosition() {
    this.courseSections.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
      item.sort = index + 1;
      item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
    });

  }

  removeCourseSection(selectedIndex) {
    let section = this.getCourseSections()[selectedIndex]
    var index = _.findIndex(this.courseSections, section)
    if (index != -1) {
      if (section.id) {
        this.courseSections[index].updateState = this.UPDATE_STATUS.REMOVED;
      } else {
        this.courseSections.splice(index, 1);
      }
    } else {
      throw new Error("Sección no encontrada.")
    }
  }

  getNextCourseSectionIndex() {
    return _.size(this.getCourseSections()) + 1;
  }

  //#endregion

  //#region INSTRUCTORS

  getInstructors() {
    return _.sortBy(this.instructors.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
  }

  addInstructor(data) {
    var item = _.find(this.getInstructors(), { id: data.id })
    if (!item) {
      data.updateState = this.UPDATE_STATUS.ADDED;
      this.instructors.push(new Instructor(data));
    } else {
      throw new Error('Instructor existente');
    }
  }

  removeInstructor(index) {
    let item = this.instructors[index];
    if (item.id) {
      this.instructors[index].updateStatus = this.UPDATE_STATUS.REMOVED;
    } else {
      this.instructors.splice(index, 1);
    }
  }

  //#endregion
}
