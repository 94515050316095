<script>
import MainMenuComponent from '../features/MainMenuComponent';

export default {
  name: 'MainMenuView',
  mixins: [MainMenuComponent],
};
</script>
<template>
  <!-- Left Sidebar Start -->
  <nav class="vertical_nav">
    <div class="left_section menu_left" id="js-menu">
      <div class="left_section">
        <ul>
          <li class="menu--item">
            <router-link :to="'/admin/resumen'" class="menu--link" title="Resumen">
              <i class="uil uil-apps menu--icon"></i>
              <span class="menu--label">Resumen</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/en-vivo'"
              class="menu--link"
              title="Clases en vivo"
            >
              <i class="uil uil-play menu--icon"></i>
              <span class="menu--label">En Vivo</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link :to="'/admin/cursos'" class="menu--link" title="Cursos">
              <i class="uil uil-book menu--icon"></i>
              <span class="menu--label">Cursos</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/estudiantes'"
              class="menu--link"
              title="Estudiantes"
            >
              <i class="fas fa-graduation-cap menu--icon"></i>
              <span class="menu--label">Estudiantes</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/profesores'"
              class="menu--link"
              title="Profesores"
            >
              <i class="uil uil-presentation menu--icon"></i>
              <span class="menu--label">Profesores</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/simuladores'"
              class="menu--link"
              title="Simuladores"
            >
              <i class="fas fa-building menu--icon"></i>
              <span class="menu--label">Simuladores</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/evaluaciones'"
              class="menu--link"
              title="Evaluaciones"
            >
              <i class="uil uil-star menu--icon"></i>
              <span class="menu--label">Evaluaciones</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/inscripciones'"
              class="menu--link"
              title="Inscripciones"
            >
              <i class="uil uil-bill menu--icon"></i>
              <span class="menu--label">Inscripciones</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/planes'"
              class="menu--link"
              title="Inscripciones"
            >
              <i class="uil uil-file-alt menu--icon"></i>
              <span class="menu--label">Planes</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/descuentos'"
              class="menu--link"
              title="Descuentos"
            >
              <i class="fas fa-tags menu--icon"></i>
              <span class="menu--label">Descuentos</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/notificaciones'"
              class="menu--link"
              title="Notificaciones"
            >
              <i class="uil uil-bell menu--icon"></i>
              <span class="menu--label">Notificaciones</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/admin/publicaciones'"
              class="menu--link"
              title="Publicaciones"
            >
              <i class="uil uil-layers menu--icon"></i>
              <span class="menu--label">Publicaciones</span>
            </router-link>
          </li>
          <li class="menu--item menu--item__has_sub_menu">
            <label class="menu--link" title="Authorization">
              <i class="fas fa-user-shield menu--icon"></i>
              <span class="menu--label">Autorizaci&oacute;n</span>
            </label>
            <ul class="sub_menu">
              <li class="sub_menu--item">
                <router-link :to="'/admin/autorizacion/usuarios'" class="sub_menu--link">
                  Usuarios
                </router-link>
              </li>
              <li class="sub_menu--item">
                <router-link :to="'/admin/autorizacion/roles'" class="sub_menu--link">
                  Roles
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="left_section pt-2">
        <ul>
          <li class="menu--item menu--item__has_sub_menu">
            <label class="menu--link" title="Configuraciones">
              <i class="uil uil-cog menu--icon"></i>
              <span class="menu--label">Configuraciones</span>
            </label>
            <ul class="sub_menu">
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/universidades'" class="sub_menu--link">
                  Universidades
                </router-link>
              </li>
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/areas-de-conocimiento'" class="sub_menu--link">
                  Áreas de Conocimiento
                </router-link>
              </li>
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/carreras'" class="sub_menu--link">
                  Carreras
                </router-link>
              </li>
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/categorias-de-cursos'" class="sub_menu--link">
                  Categorias de Cursos
                </router-link>
              </li>
              <!--
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/ciudades-de-asesorias'" class="sub_menu--link">
                  Ciudades de Asesorias
                </router-link>
              </li>
              -->
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/preparatorias'" class="sub_menu--link">
                  Preparatorias
                </router-link>
              </li>
              <li class="sub_menu--item">
                <router-link :to="'/admin/configuraciones/simuladores'" class="sub_menu--link">
                  Simuladores
                </router-link>
              </li>
            </ul>
          </li>
          <!-- <li class="menu--item">
            <a href="help.html" class="menu--link" title="Help">
              <i class="uil uil-question-circle menu--icon"></i>
              <span class="menu--label">Ayuda</span>
            </a>
          </li>-->
        </ul>
      </div>
      <div class="left_footer">
        <ul>
          <li><a href="/">Inicio</a></li>
          <li><a href="/acerca">Acerca</a></li>
          <li><a href="/noticias">Noticias</a></li>
          <li><a href="/contacto">Contacto</a></li>
          <!-- <li><a href="terms_of_use.html">Políticas de Privacidad</a></li>
          <li><a href="terms_of_use.html">Terminos de Uso</a></li> -->
        </ul>
        <div class="left_footer_content">
          <p>
            © 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.
          </p>
        </div>
      </div>
    </div>
  </nav>
  <!-- Left Sidebar End -->
</template>
