import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiDiscounts extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findHistory(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/all-students-by-used-coupon${parameters}`)
    }
}