import ItemComponent from "../../../../core/infraestructure/ItemComponent";
import CourseDetailViewModel from "../../../../core/features/course/viewmodels/CourseDetailViewModel";

export default {
  extends: ItemComponent,
  data() {
    return {
      viewModel: null,
    };
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CourseDetailViewModel(this);
  },
  methods: {
    orderSubjects(collection) {
      return _.sortBy(collection, "sort");
    }
  },
};
