<script>
import ListInstructorsComponent from '../../features/lists/ListInstructorsComponent';
import ModalInstructorView from '../modals/ModalInstructorView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListInstructorsComponent,
  name: 'ListInstructorView',
  components: {
    'modal-instructor-view': ModalInstructorView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl instructors">
      <el-col :xs="24" :sm="13" :md="13" :lg="9" :xl="6" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Profesor</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Profesores</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <!--<el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!--<el-dropdown-item>
                <el-select size="small" v-model="parentFilter" filterable remote clearable placeholder="Padre..."
                :remote-method="onSearchInstructor" @change="onAllFilterChange(parentFilter.id, 'Parent')" @focus="onFocusInstructor"
                value-key="id">
                  <el-option v-for="item in parents" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>-->
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.status" clearable placeholder="Estado..." @change="onAllFilterChange(filterOptions.status, 'Status')" value-key="id">
                  <el-option v-for="item in userStatuses" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por Nombre." placement="top">
          <el-input size="small" placeholder="Buscar Nombre..." icon="search" v-model='filterOptions.fullNameFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.fullNameFilter, 'FullName')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por correo electronico." placement="top">
          <el-input size="small" placeholder="Buscar Correo..." icon="search" v-model='filterOptions.emailFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.emailFilter, 'Email')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>

    <el-table
      :data="collection"
      empty-text="No se encontraron resultados."
      v-loading="isListLoading"
      element-loading-spinner="el-icon-loading"
      :element-loading-text="listLoadingMessage"
    >
      <el-table-column label="" width="70">
        <template slot-scope="scope">
          <div>
            <el-avatar :src="mapProfileImage(scope.row.userProfile)"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="NOMBRE" min-width="350">
        <template slot-scope="scope">
          <div>
            {{ scope.row.userProfile.firstName }} {{ scope.row.userProfile.lastName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CORREO" min-width="350">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.userProfile.email }}</el-tag>
          </div>
        </template>
      </el-table-column>
       <el-table-column label="REDES SOCIALES" width="160" align="center">
        <template slot-scope="scope">
          <ul class="tutor_social_links">
            <li>
              <a v-if="hasFacebookPage(scope.row)" :href="scope.row.pageUrlFacebook" class="fb" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <!-- <li>
              <a href="#" class="tw"><i class="fab fa-twitter"></i></a>
            </li> -->
            <li>
              <a v-if="hasInstagramPage(scope.row)" :href="scope.row.pageUrlInstagram" class="in" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a v-if="hasLinkedinPage(scope.row)" :href="scope.row.pageUrlLinkedIn" class="ln" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column label="CURSOS" width="120" align="center">
        <template slot-scope="scope">
          <el-tag type="default" effect="plain">
            {{ mapTotalCourses(scope.row) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="ACTIVO" min-width="120" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.userProfile.status"
            active-color="#13ce66"
            active-text="SI"
            inactive-text="NO"
            size="small"
            :inactive-value="1"
            :active-value="2"
            @change="onChangeStatus(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <!--
      <el-table-column label="CURSO" min-width="250">
        <template slot-scope="scope">
          <div>
            {{ scope.row.curse }}
          </div>
        </template>
      </el-table-column>
      -->

      
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="140"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-instructor-view
      :formData="item"
      :conf="modalItem"
      :showStatus="true"
      v-if="modalItem.isVisible"
    ></modal-instructor-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
