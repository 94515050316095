import BaseModel from "../../../infraestructure/BaseModel"
export default class CourseSubjectActivityAnswer extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            sort: this.sort,
            success: this.success,
            updateState: this.updateState,
        }
    }

    updateData(title) {
        this.title = title
        this.updateState = (this.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
    }
}