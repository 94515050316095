import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import DiscountViewModel from '../../../../core/features/discount/viewmodels/DiscountViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'DiscountContent-Discounts',
      item: null,
      searchName: null,
      searchCode: null,
      searchMax: null,
      searchAmount: null,
      reportDate: null,
      subscriptionPlans: [],
      viewModel: null,
      filterOptions: {
        subscriptionPlanFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR DESCUENTOS',
        onUploadImport: 'onUploadImportDiscount',
        onCancelImport: 'onCancelImportDiscount'
      },
      modalHistory: {
        isVisible: false,
        isLoading: false,
        title: 'HISTORIAL CUPONES USADOS',
        onAcceptHistory: 'onAcceptHistoryDiscount'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedDiscount', this.onSearch);
    EventBus.$on('onUploadImportDiscount', this.onUploadImport);
    EventBus.$on('onCancelImportDiscount', this.onCancelImport);
    EventBus.$on('onCancelImportDiscount', this.onCancelImport);
    EventBus.$on('onAcceptHistoryDiscount', this.onAcceptHistory);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedDiscount', this.onSearch);
    EventBus.$off('onUploadImportDiscount', this.onUploadImport);
    EventBus.$off('onCancelImportDiscount', this.onCancelImport);
    EventBus.$off('onCancelImportDiscount', this.onCancelImport);
    EventBus.$off('onAcceptHistoryDiscount', this.onAcceptHistory);
  },
  created() {
    this.viewModel = new DiscountViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onFilterDate(data, field) {
      this.viewModel.updateFilters(data, field, '=');
    },
    onRangeDate(data, startDate, endDate) {
      if(data) {
        this.onFilterDate(data[0], startDate);
        this.onFilterDate(data[1], endDate);
        this.onSearch();
      } else {
        this.viewModel.removeFilter(startDate);
        this.viewModel.removeFilter(endDate);
        this.onSearch();
      }
    },
    onSearchSubscriptionPlans(data) {
      if(data) {
          this.viewModel.onSearchSubscriptionPlans(data)
      } else {
          this.subscriptionPlans = []
      }
    },
    onSearchSubscriptionPlansResponse(response) {
        this.subscriptionPlans = response.data;
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onShowHistory() {
      this.viewModel.showModal(this.modalHistory, true);
    },
    onAcceptHistory(){
      this.viewModel.showModal(this.modalHistory, false);
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}