export default {
  // BASE API
  API: '/api',
  AUTH: '/auth',

  // MODULO AUTHENTICACIÓN
  MODULES: '/auth/modules',
  USERS: '/auth/users',
  ROLES: '/auth/roles',
  AUTH_GROUPS: '/auth/groups',
  PERMISSIONS: '/auth/permissions',

  // MODULO CONFIGURACIONES
  COURSE_CATEGORIES: '/settings/course-categories',
  HIGH_SCHOOLS: '/settings/highschools',
  UNIVERSITIES: '/settings/universities',
  ADVISORY_CITIES: '/settings/advisory-cities',
  CAREERS: '/settings/careers',
  COURSE_SUBJECT_ACTIVITY_TYPES: '/settings/course-subject-activity-types',
  USER_STATUSES: '/settings/user-statuses',
  FINISHED_STATUSES: '/settings/finished-statuses',
  PUBLISHED_STATUSES: '/settings/published-statuses',
  TEST_TIMER_TYPES: '/settings/test-timer-types',
  SIMULATOR_QUESTIONS: '/settings/simulator-questions',
  SIMULATOR_QUESTION_CATEGORIES: '/settings/simulator-question-categories',
  SIMULATOR_EXAM_TYPES: '/settings/simulator-exam-types',
  DIFFICULTY_LEVELS: '/settings/difficulty-levels',
  KNOWLEDGE_AREAS: '/settings/knowledge-areas',
  
  // MODULO CURSOS
  COURSES: '/courses',
  RESPONSE_ACTIVITY_ANSWERS: '/courses/activities/answers',

  // MODULO DESCUENTOS
  DISCOUNTS: '/discounts',

  // MODULO INSTRUCTORES
  INSTRUCTORS: '/instructors',

  // MODULO ESTUDIANTES
  STUDENTS: '/students',

  // MODULO DE REVIEWS
  REVIEWS: '/reviews',

  // MODULO PLAN DE INSCRIPCIONES
  SUBSCRIPTION_PLANS: '/subscriptions/plans',

  // MODULO DE INSCRIPCIONES
  SUBSCRIPTIONS: '/subscriptions',

  // MODULO DE DECLARACIONES
  STATEMENTS: '/statements',

  // MODULO DE NOTIFICACIONES
  NOTIFICATIONS: '/notifications',

  // MODULO DE PUBLICACIONES
  POSTS: '/posts',

  // MODULO DE SIMULACIONES
  SIMULATORS: '/simulators',

  // MODULO CLASES EN VIVO
  LIVE_CLASSES: '/live-classes',
  LIVE_CHAT: '/live-classes/chat',

  // MODULO DE EVALUACIONES
  EVALUATIONS: '/evaluations',

  // MODULO DASHBOARD
  DASHBOARD_MAIN: '/dashboard',
  // MODULO ESTUDIANTES
  DASHBOARD_STUDENT: '/dashboard/students',
  // MODULO PROFESORES
  DASHBOARD_INSTRUCTOR: '/dashboard/instructors',
}