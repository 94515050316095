import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import CourseSectionViewModel from '../../../../core/features/course/viewmodels/CourseSectionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            itemCourseSubject: null,
            courseSubjectSelectedIndex: null,
            careers: [],
            modalItemCourseSubject: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Descripción requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSectionViewModel(this);
        this.onActive();
    },
    mounted() {
        EventBus.$on('onSubmitedCourseSubject', this.onUpsertCourseSubject);
        EventBus.$on('onCanceledCourseSubject', this.onCanceledCourseSubject);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSubject', this.onUpsertCourseSubject);
        EventBus.$off('onCanceledCourseSubject', this.onCanceledCourseSubject);
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData);
        },
        onCancel() {
            EventBus.$emit('onCanceledCourseSection', null);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.conf.isLoading = true
                    EventBus.$emit('onSubmitedCourseSection', this.formData);
                }
            });
        },
        onCanceledCourseSubject() {
            this.courseSubjectSelectedIndex = null
            this.modalItemCourseSubject.isLoading = false
            this.modalItemCourseSubject.isVisible = false
        },
        onCreateCourseSubject() {
            this.itemCourseSubject = this.formData.createCourseSubject();
            this.modalItemCourseSubject.isLoading = true
            this.modalItemCourseSubject.isVisible = true
            // this.viewModel.showModal(this.modalItemCourseSubject, true);
        },
        onEditCourseSubject(item) {
            this.itemCourseSubject = this.formData.editCourseSubject(item);
            this.modalItemCourseSubject.isLoading = true
            this.modalItemCourseSubject.isVisible = true
            // this.viewModel.showModal(this.modalItemCourseSubject, true);
        },
        onDeleteCourseSubject(index) {
            this.$confirm("¿Desea eliminar el tema seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.conf.isLoading = true
                    setTimeout(() => {
                        this.courseSubjectSelectedIndex = null
                        this.formData.removeCourseSubject(index)
                        this.$forceUpdate()
                        this.formData.updateCourseSubjectPosition()
                        this.conf.isLoading = false
                    }, 400);
                })
                .catch(() => { 
                    this.courseSubjectSelectedIndex = null
                });
        },
        onUpsertCourseSubject(item) {
            setTimeout(() => {
                (this.courseSubjectSelectedIndex != null)
                    ? this.formData.updateCourseSubject(this.courseSubjectSelectedIndex, item)
                    : this.formData.addCourseSubject(item)
                this.$forceUpdate()
                this.formData.updateCourseSubjectPosition()
                this.courseSubjectSelectedIndex = null;
                this.modalItemCourseSubject.isLoading = false;
                this.modalItemCourseSubject.isVisible = false;
            }, 400);
        },
       
        onChangeItemOptions(option) {
            this.courseSubjectSelectedIndex = option.index;
            if (option.command === 'edit') this.onEditCourseSubject(option.item);
            if (option.command === 'delete') this.onDeleteCourseSubject(option.index);
        },
        getDurationTotal(totalHours, duration) {
            return (totalHours) ? totalHours : this.viewModel.getTimeFromMins(duration);
        },
        onChangeDrag() {
            this.formData.updateCourseSubjectPosition()
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onChangeIsSpecialty() {
            this.$set(this.formData, 'specialties', '');
        },
        onChangeSpecialties(res) {
            console.log('onChangeSpecialties')
            console.log(res);
            console.log(this.formData)
        },
        onChangeRemoveSpecialties(res) {
            console.log('onChangeRemoveSpecialties')
            console.log(res);
            console.log(this.formData)
        },
        onCanCreateCourseSubject(permissions) {
            return this.canCreate(permissions) || this.canUpdate(permissions)
        },
        onCanReadCourseSubject(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSubject(permissions) {
            return this.canDelete(permissions);
        },
    }
}