<script>
import ModalSelectCareerComponent from '../features/ModalSelectCareerComponent';

export default {
  extends: ModalSelectCareerComponent,
  name: 'ModalSelectCareerView',
};
</script>

<template>
  <div>
    <el-dialog
      title="Educación"
      :visible.sync="conf.isVisible"
      width="60%"
      custom-class="select-careers-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información Educativa</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="Universidad" prop="university">
                <el-select
                  v-model="formData.university"
                  clearable
                  @change="onSelectUniversity"
                  placeholder="Seleccionar"
                  value-key="id"
                  size="small"
                >
                  <el-option
                    v-for="item in universities"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <p>Áreas de Conocimiento</p>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="24" :xl="24">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >Marcar todos</el-checkbox
              >
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="checkedKnowledgeAreas"
                @change="handleCheckedKnowledgeAreasChange"
              >
                <el-checkbox v-for="knowledgeArea in knowledgeAreas" :label="knowledgeArea.id" :key="knowledgeArea.id">
                  {{ knowledgeArea.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-col>
          </el-row>
          <!--
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-button-group>
                <el-button
                  type="primary"
                  @click="onFindAllCareersByKnowledgeAreaIds"
                  class="btn-primary"
                  size="small"
                  >Buscar</el-button
                >
                <el-button @click="clearSelection()" size="small" class="btn-cancel">Limpiar</el-button>
              </el-button-group>
            </el-col>
          </el-row>
          -->
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-table :data="collection" width="100%" max-height="300" ref="multipleTable" @selection-change="handleSelectionChange">>
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="name" label="Carrera" min-width="300">
                </el-table-column>
                <el-table-column label="Área" min-width="300">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.knowledgeArea.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Universidad" min-width="300">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.university.name }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
