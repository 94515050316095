import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import KnowledgeAreaViewModel from '../../../../core/features/setting/viewmodels/KnowledgeAreaViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'Settings-KnowledgeAreas',
            universities: [],
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            rules: {
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                university: [
                    { required: true, message: 'Universidad requerida.', trigger: 'change' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new KnowledgeAreaViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView(this.formData);
        },
        onFindUniversity(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onFindUniversitiesResponse(response) {
            this.universities = response;
        },
        onFocus() {
            this.$forceUpdate()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedKnowledgeArea', null);
        }
    }
}