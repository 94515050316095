import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import PostViewModel from '../../../../core/features/website/viewmodels/PostViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      item: null,
      viewModel: null,
      fullLoader: false,
      filterOptions: {
        descriptionFilter: '',
        titleFilter: '',
      },
    }
  },
  mounted() {},
  beforeDestroy() {},
  created() {
    this.fullLoader = true;
    this.viewModel = new PostViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.fullLoader = true;
      this.onFilterChange(data, field);
    },
    mapPostImageUrl(item) {
      return (item.imageUrl) ? item.imageUrl : 'images/blog/img-1.jpg';
    },
    navigate(post) {     
      this.$router.push({
        name: "blog single",
        params:{
          id: post.id,
          title: post.title.replace(/ /g, '-').toLowerCase()
        }
      });
    },
    descriptionStrippedHtml(description) {
      let regex = /(<([^>]+)>)/ig;

      return description.replace(regex, "");
    }
  },
  computed: {}
}