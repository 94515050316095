import moment from "moment";
import BaseModel from "../../../infraestructure/BaseModel"
import CourseSubjectActivity from "./CourseSubjectActivity";
export default class CourseSubject extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    static ACTIVITY_TYPE_LECTURE = 1;
    static ACTIVITY_TYPE_TEST = 2;

    toSend() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            sort: this.sort,
            isSpecialty: this.isSpecialty,
            specialtyId: (this.specialty) ? this.specialty.id : null,
            updateState: this.updateState,
            courseSubjectActivities: this.courseSubjectActivities.map(item => item.toSend()),
        }
    }

    //#region COURSE SECTIONS

    getTotalDuration() {
        return _.sumBy(this.getCourseSubjectActivities(), 'duration');
    }

    getDuration() {
        let totalDuration = this.getTotalDuration();
        return moment.utc(moment.duration(totalDuration, "minutes").asMilliseconds()).format("HH:mm")
    }

    getTotalActivities() {
        return _.size(this.getCourseSubjectActivities());
    }

    getCourseSubjectActivities() {
        return _.sortBy(this.courseSubjectActivities.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    createCourseSubjectActivity() {
        return new CourseSubjectActivity({
            courseSubjectActivityType: CourseSubject.ACTIVITY_TYPE_LECTURE,
            sort: this.getNextCourseSubjectActivityIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            courseSubjectActivityAnswers: []
        }, 'create')
    }

    createCourseSubjectActivityTest() {
        return new CourseSubjectActivity({
            courseSubjectActivityType: CourseSubject.ACTIVITY_TYPE_TEST,
            sort: this.getNextCourseSubjectActivityIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            courseSubjectTestActivities: []
        }, 'create')
    }
    
    editCourseSubjectActivity(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new CourseSubjectActivity(data, 'update');
    }

    addCourseSubjectActivity(data) {
        this.courseSubjectActivities.push(new CourseSubjectActivity(data, 'create'));
    }

    updateCourseSubjectActivity(selectedIndex, data) {
        let section = this.getCourseSubjectActivities()[selectedIndex]
        var index = _.findIndex(this.courseSubjectActivities, section)
        if (index != -1) {
            this.courseSubjectActivities[index] = data
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    updateCourseSubjectActivityPosition() {
        this.courseSubjectActivities.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
            item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        });
    }

    removeCourseSubjectActivity(selectedIndex) {
        let section = this.getCourseSubjectActivities()[selectedIndex]
        var index = _.findIndex(this.courseSubjectActivities, section)
        if (index != -1) {
            if (section.id) {
                this.courseSubjectActivities[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.courseSubjectActivities.splice(index, 1);
            }
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    getNextCourseSubjectActivityIndex() {
        return _.size(this.getCourseSubjectActivities()) + 1;
    }

    //#endregion
}
