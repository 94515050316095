import _ from 'lodash';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import UrlRender from '../../../shared/helpers/UrlRender';
import Instructor from '../../../features/instructors/models/Instructor';
import Student from '../../../features/students/models/Student';
import Subscription from '../../subscription/models/Subscription';
import Career from '../../setting/models/Career';
import HighSchool from '../../setting/models/HighSchool';
import University from '../../setting/models/University';

export default class AccountProfileViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }

  getUrlParameter(key) {
    return UrlRender.getUrlParameter(key);
  }

  onBindProfile() {
    this.view.$root.$on('update-session', (data) => {
      this.view.session = data;
      this.view.profileImageUrl = (data.user.profileImageUrl) ? data.user.profileImageUrl : this.view.profileImageUrl;
      switch (data.role.name) {
        case 'profesor':
          this.onBindInstructorProfile(data)
          break;
        case 'estudiante':
          this.onBindStudentProfile(data)
          break;
        default:
          this.view.onError(new Error('Role no existente.'))
          break;
      }
    });
  }

  onBindStudentProfile(data) {
    this.api.students.findByAccount(data.user.id)
      .then((response) => {
        this.view.session.student = response.data.data;
        this.view.sessionAbout = response.data.data.about;
        return this.api.subscriptions.findByAccount()
      })
      .then((response) => {
        this.view.subscriptions = response.data.data
        this.view.fullLoader = false;
      })
      .catch(this.view.onError)
  }

  onBindInstructorProfile(data) {
    this.api.instructors.findByAccount(data.user.id)
      .then((response) => {
        this.view.session.instructor = response.data.data;
        this.view.sessionAbout = response.data.data.about;
        this.view.fullLoader = false;
      })
      .catch(this.view.onError)
  }

  bindFormStudentInfoView() {
    if (this.view.formData.state === 'create') {
      this.api.universities.find()
        .then((response) => {
          this.view.universities = response.data.data;
          return this.api.highSchools.find()
        })
        .then((response) => {
          this.view.highSchools = response.data.data;
        })
    }
  }

  onFindInstructor(instructorId) {
    this.api.instructors.findOne(instructorId)
      .then((response) => this.view.onFindInstructorResponse(response.data))
      .catch(this.view.onError);
  }

  onEditInstructorProfile(data) {
    var item = new Instructor(data);
    item.state = 'update';
    return item;
  }

  onFindStudent(studentId) {
    this.api.students.findOne(studentId)
      .then((response) => this.view.onFindStudentResponse(response.data))
      .catch(this.view.onError);
  }

  onEditStudentProfile(data) {
    var item = new Student(data);
    item.state = 'update';
    return item;
  }

  onCreateSubscription(data) {
    var item = new Subscription();
    item.state = 'create';
    return item;
  }

  onCreateStudentInfo(data) {
    var item = new Student(data);
    item.state = 'create';
    return item
  }

  onCreateCareers() {
    let item = new ({
      state: "create",
      collectionCareers: []
    });
    return item;
  }

  formatMoney(amount) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount);
  }

  addTrace(data) {
    //if(_.isUndefined(this.view.formData.collection)) this.view.formData.collection = [];
    this.view.formData.collection = [];
    data.forEach(item => {
      this.view.formData.collection.push(item)
    });

  }
  /**
   * Metodo para eliminar un tema del curso actual
   * @param {*} index 
   */
  removeCareer(index) {
    this.view.formData.collection.splice(index, 1);
    this.view.showLoading(false, '');
  }

  // #region CREATES
  /**
   * Metodo para crear universidad
   * @param {String} data Query string para parametros de creación
   */

  createUniversities(data) {
    this.api.universities.createInStudent(new University({ name: data }))
      .then((response) => this.view.onLoadUniversity(response.data.data))
      .catch(this.view.onError)
  }
  /**
   * Metodo para crear preparatoria
   * @param {String} data Query string para parametros de creación
   */

  createHighSchools(data) {
    this.api.highSchools.createInStudent(new HighSchool({ name: data }))
      .then((response) => this.view.onLoadHighSchool(response.data.data))
      .catch(this.view.onError)
  }
  /**
   * Metodo para crear universidad
   * @param {String} data Query string para parametros de creación
   */

  createCareers(data) {
    this.api.careers.createInStudent(new Career({ name: data }))
      .then((response) => this.view.onLoadCareer(response.data.data))
      .catch(this.view.onError)
  }
  // #endregion

  findUniversities(criteria) {
    this.api.universities.find(criteria)
      .then((response) => this.view.onSearchUniversityResponse(response.data.data))
      .catch(this.view.onError)
  }

  findHighSchools(criteria) {
    this.api.highSchools.find(criteria)
      .then((response) => this.view.onSearchHighSchoolsResponse(response.data.data))
      .catch(this.view.onError)
  }

  findCareers(criteria) {
    this.api.careers.find(criteria)
      .then((response) => this.view.onSearchCareersResponse(response.data.data))
      .catch(this.view.onError)
  }
  /**
     * Metodo para buscar áreas de conocimiento
     * @param {String} data Query string para parametros de búsqueda
     */
  findKnowledgeAreas(data) {
    this.api.knowledgeAreas.find(data)
      .then((response) => this.view.onFindKnowledgeAreasResponse(response.data))
      .catch(this.view.onError)
  }
  /**
     * Metodo para buscar carreras dependiendo las áreas de conocimiento seleccionadas
     * @param {String} data Query string para parametros de búsqueda
     */
  findAllCareersByKnowledgeAreaIds(data) {
    this.api.careers.findAllCareersByKnowledgeAreaIds({ knowledgeAreaIds: data })
      .then((response) => this.view.onFindAllCareersByKnowledgeAreaIdsResponse(response.data))
      .catch(this.view.onError)
  }

  updateStudentInfo(data) {
    this.api.students.doRegister(data.toSendStudentInfo())
      .then((response) => this.view.onUpdateStudentInfoResponse(response.data))
      .catch(this.view.onError);
  }
  // #region Maping
  mapKnowledgeAreas(data) {
    let collection = _.map(data, 'id');
    return collection;
  }
  // endregion
  // #region VALIDATIONS
  /**
   * Metodo para validación de formulario de estudiante.
   */
   validateForm(valid) {
    var isValid = false
    if (_.size(this.view.formData.collection) >= 1) {
      if (valid) isValid = true
    } else {
      if (valid) {
        this.view.onError(new Error(
          'Ocupa seleccionar minimo 1 carrera.'
        ))
      }
    }
    return isValid
  }
  /**
   * Metodo para validación de formulario de select career.
   */
   validateFormSelectCarrer(valid) {
    var isValid = false
    if (_.size(this.view.multipleSelection) <= 3) {
      if (_.size(this.view.multipleSelection) >= 1) {
        if (valid) isValid = true
      } else {
        if (valid) {
          this.view.onError(new Error(
            'Ocupa seleccionar minimo 1 carrera.'
          ))
        }
      }
    } else {
      if (valid) {
        this.view.onError(new Error(
          'Ocupa seleccionar maximo 3 carreras.'
        ))
      }
    }
    return isValid
  }

  // #endregion
}