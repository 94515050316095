import { EventBus } from '../../../core/shared/helpers/EventHelper';
import BaseComponent from '../../../core/infraestructure/BaseComponent';
import AccountProfileViewModel from '../../../core/features/authentication/viewmodels/AccountProfileViewModel';

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: '',
            isFormProcessing: false,
            session: null,
            item: null,
            subscriptionItem: null,
            sessionAbout: '',
            subscriptions: [],
            fullLoader: true,
            modalItem: {
                isVisible: false,
                isLoading: false,
            },
            modalSubscriptionItem: {
                isVisible: false,
                isLoading: false,
            },
            formData: {
                email: ''
            },
            studentInfoItem: null,
            modalStudentInfoItem: {
                isVisible: false,
                isLoading: false,
            },
            profileImageUrl: '/images/hd_dp.jpg',
            rules: {
                /* email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ], */
            }
        };
    },
    mounted() {
        EventBus.$on('onSubmitedStudent', this.onReload);
        EventBus.$on('onFinishSubscriptionPayment', this.onFinishSubscriptionPayment);
        EventBus.$on('onFinishStudentInfo', this.onFinishStudentInfo);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedStudent', this.onReload);
        EventBus.$off('onFinishSubscriptionPayment', this.onFinishSubscriptionPayment);
        EventBus.$off('onFinishStudentInfo', this.onFinishStudentInfo);
    },
    created() {
        this.viewModel = new AccountProfileViewModel(this);
        this.onActive();
    },
    methods: {
        onActive(){
            this.viewModel.onBindProfile();
            this.$root.$on('update-session', this.onUpdateSession);
        },
        onReload() {
            setTimeout(() => {
                window.location.reload();
            }, 400);
        },
        onEditProfile() {
            this.viewModel.onFindStudent(this.session.student.id);
        },
        onFindStudentResponse(response) {
            this.item = this.viewModel.onEditStudentProfile(response.data);
            this.item.isStudent = true;
            this.viewModel.showModalLoading(this.modalItem, true);
            this.viewModel.showModal(this.modalItem, true);
        },
        onUpdateSubscription() {
            this.subscriptionItem = this.viewModel.onCreateSubscription()
            this.modalSubscriptionItem.isVisible = true;
        },
        formatMoney(amount) {
            return this.viewModel.formatMoney(amount)
        },
        onFinishSubscriptionPayment(subscriptionDetails) {
            if(this.session && this.session.user && !this.session.user.hasSubscriptions){
                this.studentInfoItem = this.viewModel.onCreateStudentInfo({
                    subscription: subscriptionDetails
                });
                this.modalStudentInfoItem.isVisible = true;
            } else {
                this.onReload();
            }
        },
        onFinishStudentInfo() {
            this.onReload();
        },
        onUpdateSession(data){
            if(data.user.hasSubscriptions && !data.user.hasSubscriptionInfo){
                this.studentInfoItem = this.viewModel.onCreateStudentInfo()
                this.viewModel.showModal(this.modalStudentInfoItem, true)
            }
        }
    }
}