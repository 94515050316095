<script>
import ModalSimulatorOfficialQuestionComponent from '../../features/modals/ModalSimulatorOfficialQuestionComponent';

export default {
  extends: ModalSimulatorOfficialQuestionComponent,
  name: 'ModalSimulatorOfficialQuestionView',
  components: {},
};
</script>

<template>
  <el-dialog
      title="Pregunta"
      :visible.sync="conf.isVisible"
      width="45%"
      custom-class="simulator-questions-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Filtros</legend>
          <br/>
          <!--
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-select size="small" v-model="filterOptions.universityFilter" filterable remote clearable placeholder="Universidad..."
                :remote-method="onFindUniversity" @change="onChangeUniversity" @focus="onFocus" value-key="id">
                  <el-option v-for="item in universities" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-select size="small" v-model="filterOptions.knowledgeAreaFilter" filterable clearable placeholder="Áreas de Conocimiento..." @change="onChangeKnowledgeArea" @focus="onFocus" value-key="id" :disabled="isUniversity">
                  <el-option v-for="item in knowledgeAreas" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
            </el-col>
          </el-row>
          <br/>
          -->
          <el-row class="row-bg" :gutter="20">
            <!--
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-select size="small" v-model="filterOptions.careerFilter" filterable clearable placeholder="Carrera..." @focus="onFocus" value-key="id" :disabled="isKnowledgeArea">
                  <el-option v-for="item in careers" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
            </el-col>
            -->
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-select size="small" v-model="filterOptions.simulatorQuestionCategoryFilter" filterable clearable placeholder="Categoria..." @focus="onFocus" value-key="id">
                  <el-option v-for="item in simulatorQuestionCategories" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-select size="small" v-model="filterOptions.difficultyLevelFilter" clearable placeholder="Dificultad..." @focus="onFocus" value-key="id">
                  <el-option v-for="item in difficultyLevels" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
            </el-col>
          </el-row>
          <br/>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-input size="small" placeholder="Pregunta..." v-model='filterOptions.nameFilter'></el-input>
            </el-col>
          </el-row>
          <br/>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="center">
              <el-button-group>
                <el-button type="primary" size="small" class="btn-primary" icon="el-icon-circle-plus" @click="onFindQuestions">Buscar Preguntas</el-button>
                <el-button type="primary" size="small" class="btn-primary" icon="el-icon-circle-plus" @click="onAddRandomQuestions">Preguntas Aleatorias</el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </fieldset>
        <br/>
        <fieldset>
          <legend>Preguntas</legend>
          <el-row type="flex" class="row-bg" :gutter="20" v-loading="isQuestionsLoading">
            <el-col :span="24">
              <br />
              <el-table ref="multipleTable" :data="formData.simulatorsQuestions" @selection-change="handleSelectionChange" empty-text="No se encontraron resultados." style="overflow-x:auto; overflow-y: auto; height:260px;">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="NOMBRE" min-width="250">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="CATEGORIA" min-width="170">
                  <template slot-scope="scope">
                    <div>
                      <el-tag type="info" effect="plain" v-if="scope.row.simulatorQuestionCategory">{{ scope.row.simulatorQuestionCategory.name }}</el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="DIFICULTAD" min-width="170">
                  <template slot-scope="scope">
                    <div>
                      <el-tag v-if="scope.row.difficultyLevel === 1" type="success" effect="plain">FACIL</el-tag>
                      <el-tag v-if="scope.row.difficultyLevel === 2" type="warning" effect="plain">INTERMEDIO</el-tag>
                      <el-tag v-if="scope.row.difficultyLevel === 3" type="danger" effect="plain">DIFICIL</el-tag>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
</template>