import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent'
import StudentViewModel from '../../../../core/features/students/viewmodels/StudentViewModel';
import StudentConfig from '../../../../core/features/students/config';
import University from '../../../../core/features/setting/models/University';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'StudentsContent-Students',
            viewModel: null,
            permissions: null,
            modules: [],
            updatePassword: false,
            activeName: 'account',
            universities: [],
            highSchools: [],
            careers: [],
            universitiesToApply: [],
            careersToApply: [],
            studentTypes: StudentConfig.STUDENT_TYPE,
            profileImageUrl: '/images/left-imgs/img-1.jpg',
            profileImageFile: null,
            isProfileImageTemp: false,
            currentUniversity : null,
            currentCareer : null,
            currentHighSchool : null,
            modalItemCareers: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                'userProfile.userName': [
                    { required: true, message: "Usuario requerido.", trigger: "blur" },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                'userProfile.email': [
                    { required: true, message: "Correo requerido." },
                    {
                        type: "email",
                        message: "Ingresar email valido",
                        trigger: "blur,change"
                    },
                ],
                'userProfile.firstName': [
                    { required: true, message: "Nombre(s) requerido(s).", trigger: "blur" }                    
                ],
                'userProfile.lastName': [
                    { required: true, message: "Apellido(s) requerido(s).", trigger: "blur" }
                ],
                'userProfile.password': [
                    {
                        required: this.isPasswordRequired(),
                        message: "Contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    }
                ],
                'userProfile.checkPass': [
                    {
                        required: this.isPasswordRequired(),
                        message: "Verifiación de contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Solo se aceptan valores alfabetico.",
                        trigger: "change"
                    },
                    { validator: this.validatePass }
                ],
                careerToApply: [
                    { required: true, message: "Carrera requerida.", trigger: "blur" },
                ],
                highSchool: [
                    { required: true, message: "Preparatoria requerida.", trigger: "blur" },
                ],
                /*
                universitiesToApply: [
                    { required: true, message: "Seleccionar al menos una universidad.", trigger: "blur" },
                ],
                
                universitiesToApply: [
                    { required: true, message: "Universidad requerida.", trigger: "blur" },
                ],
                */
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        showStatus: {
            type: Boolean,
            required: true,
        }
    },
    created() {
        this.viewModel = new StudentViewModel(this)
        this.onActive()
    },
    mounted() { 
        EventBus.$on('onSubmitedSelectCareer', this.onAddTrace);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSelectCareer', this.onAddTrace);
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    if(_.isString(this.formData.highSchool)) this.formData.highSchool = this.currentHighSchool;
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedStudent', null);
        },
        isPasswordRequired() {
            return this.updatePassword || !this.formData.id;
        },
        validatePass(rule, value, callback) {
            this.viewModel.verifyConfirmationPassword(value, callback)
        },
        onChangeUniversity(data) {
            this.$set(this.formData, 'careersToApply', [])
            this.careers = []
            if (data) {
                this.viewModel.findCareers(`UniversityId=${data.id}`)
            }
        },
        onSearchUniversities(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onSearchUniversitiesResponse(response) {
            this.universities = response;
        },
        onChangeSelectUniversities(data) {
            if(_.isString(data)) {
                this.viewModel.createUniversities(data)
            }
        },
        onLoadUniversity(response) {
            this.currentUniversity = response;
        },
        onFocusUniversity() {
            this.$forceUpdate()
        },
        onSearchCareers(data) {
            this.careers = []
            if (data) {
                this.viewModel.findCareers(`Name=${data}`)
            }
        },
        onSearchCareersResponse(response) {
            this.careers = response.data;
        },
        onChangeSelectCareers(data) {
            if(!_.isEmpty(data) && _.isString(data)) {
                this.viewModel.createCareers(data)
            }
        },
        onLoadCareer(response) {
            this.currentCareer = response;
        },
        onFocusCareer() {
            this.$forceUpdate()
        },
        onSearchHighSchools(data) {
            if (data) {
                this.viewModel.findHighSchools(`Name=${data}`)
            } else {
                this.highSchools = []
            }
        },
        onSearchHighSchoolsResponse(response) {
            this.highSchools = response;
        },
        onChangeSelectHighSchools(data) {
            if(!_.isEmpty(data) && _.isString(data)) {
                this.viewModel.createHighSchools(data)
            }
        },
        onLoadHighSchool(response) {
            this.currentHighSchool = response;
        },
        onFocusHighSchool() {
            this.$forceUpdate()
        },
        onSuccessHandlerProfilePicture(data) {

        },
        async onBeforeUploadHandlerProfilePicture(file) {
            let dataUrl = await this.viewModel.convertFileToBase64(file)
            this.profileImageUrl = (dataUrl) ? dataUrl : '/images/left-imgs/img-1.jpg'
            this.profileImageFile = file;
            this.isProfileImageTemp = true;
        },
        onChekedUniversity(university) {
            this.viewModel.findCareers(`UniversityId=${university.id}`, university.name);
        },
        mapChekedUniversity(universityName) {
            var item = _.find(this.universitiesToApply, function(o) { 
                if(o ==  universityName) {
                    return true;
                } else {
                    return false;
                }
             });

            return _.isNil(item);
        },
        mapCareers(universityName) {
            setTimeout(() => {
                console.log('mapCareers')
                //var item = _.map(this.careers, universityName);
                console.log(this.careers[universityName])
                var item = _.map(this.careers, (item) => {
                    console.log('dsd' + item)
                });
                //console.log('Array: ' + _.map(this.careers, universityName))
                //console.log('Name: ' + universityName)
                console.log(item)
                return this.careers[universityName];
            }, 300);
        },
        onChangeSelectCarrers(response) {console.log(response)},
        onSelectCareers(){
            this.itemCareers = new Object({
                state: "create",
            });
            this.viewModel.showModal(this.modalItemCareers, true);
        },
        onAddTrace(data) {
            this.viewModel.showModal(this.modalItemCareers, false);
            this.viewModel.addTrace(data)
        },
        onChangeItemOptions(option) {
            if (option.command === 'delete') this.onDeleteCareer(option.index);
        },
        onDeleteCareer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    this.showLoading(true, 'Eliminando Carrera');
                    this.viewModel.removeCareer(index);
                    this.$forceUpdate();
                })
                .catch(() => {});
        },
    }
}