import _ from "lodash";
import QuillEditorHelper from "../../../shared/helpers/QuillEditorHelper";
import BaseViewModel from "../../../infraestructure/BaseViewModel";
import SimulatorQuestion from "../models/SimulatorQuestion";
import SimulatorAnswer from "../models/SimulatorAnswer";

export default class SimulatorQuestionViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }
  // INICIALIZADORES

  static UPDATE_STATES = {
    ADD: 2,
    EDIT: 3,
    REMOVE: 4,
  };

  //#region CRUD

  onCreate() {
    let item = new SimulatorQuestion({
      state: "create",
      status: 1,
    });
    item.simulatorAnswers = item.initDefaultAnswers();
    return item;
  }

  onDelete(data) {
    let item = new SimulatorQuestion(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new SimulatorQuestion(data);
    item.simulatorAnswers = data.simulatorAnswers.map(item => new SimulatorAnswer(item))
    item.state = "update";
    return item;
  }

  createQuestion(sections) {
    var item = new SimulatorQuestion({
      state: "create",
      sort: _.size(sections) + 1,
      simulatorAnswers: this.initDefaultAnswers(),
    });

    return item;
  }

  onSearchCollection() {
    return this.api.simulatorQuestions.find(this.getSearchQuery());
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new SimulatorQuestion(data));
  }

  onSearchItem(id) {
    return this.api.simulatorQuestions.findOne(id);
  }

  onSave(data) {
    if (data.state === "create")
      return this.api.simulatorQuestions.create(
        data.toSend(this.view.answerEditors)
      );
    else
      return this.api.simulatorQuestions.update(
        data.id,
        data.toSend(this.view.answerEditors)
      );
  }

  onDestroy(context, id) {
    context.api.simulatorQuestions
      .delete(id)
      .then((response) => context.view.onSuccess(response.data))
      .catch(context.view.onError);
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.simulatorQuestions
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  exportToExcel() {
    this.api.simulatorQuestions
      .exportToExcel()
      .then((response) => this.view.onExportResponse(response.data))
      .catch(this.view.onError);
  }

  // #endregion

  // #region BINDING DATA
  bindQuestionFormView() {
    this.api.simulatorExamnTypes
      .find()
      .then((response) => {
        this.view.onFindSimulatorExamTypesResponse(response.data.data);

        if (this.view.formData.state === "update") {
          this.view.submitLabel = "Guardar";
          this.view.formData.type = this.getSimulatorType(
            response.data.data,
            this.view.formData.type
          );
          this.view.formData.difficultyLevel = this.getDifficultyLevel(
            this.view.difficultyLevels,
            this.view.formData.difficultyLevel
          );

          if (!_.isEmpty(this.view.formData.university)) {
            this.view.universities = [this.view.formData.university];
            this.view.isUniversity = false;
          }

          this.api.simulatorQuestionCategories.find()
            .then((response) => {
              this.view.simulatorQuestionCategories = response.data.data;
              return this.api.careers.find(`UniversityId=${this.view.formData.university.id}`);
            })
            .then((response) => {
              this.view.careers = response.data.data;

              if (
                this.view.formData.isSpecialty &&
                _.size(this.view.formData.specialties) ===
                _.size(this.view.careers)
              ) {
                this.view.formData.allSpecialities = true;
              }
              this.view.onBindFormViewResponse();
            });
        } else {
          setTimeout(() => {
            this.view.conf.isLoading = false;
          }, 400);
        }
      })
      .catch(this.view.onError);
  }

  bindDescriptionEditor(selector) {
    this.view.editorDescription = QuillEditorHelper.buildEditor(selector);
    this.view.editorDescription.root.innerHTML = this.view.formData.description || "";
    this.view.editorDescription.on("text-change", () => {
      this.view.formData.description = this.view.editorDescription.root.innerHTML;
    });
  }
  // #endregion

  // #region QUERIES
  /**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findKnowledgeAreas(data) {
    this.api.knowledgeAreas
      .find(data)
      .then((response) => {
        this.view.onFindKnowledgeAreasResponse(response.data.data);
      })
      .catch(this.view.onError);
  }
  /**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findSimulatorExamTypes(data) {
    this.api.simulatorExamnTypes
      .find(data)
      .then((response) =>
        this.view.onFindSimulatorExamTypesResponse(response.data.data)
      )
      .catch(this.view.onError);
  }
  /**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findSimulatorQuestionCategories(data) {
    this.api.simulatorQuestionCategories
      .find(data)
      .then((response) =>
        this.view.onFindSimulatorQuestionCategoriesResponse(response.data.data)
      )
      .catch(this.view.onError);
  }
  /**
   * Metodo para buscar dificultades
   * @param {String} data Query string para parametros de búsqueda
   */
  findDifficultyLevel(data) {
    this.api.difficultyLevels
      .find(data)
      .then((response) =>
        this.view.onFindDifficultyLevelsResponse(response.data)
      )
      .catch(this.view.onError);
  }
  /**
   * Metodo para buscar Universidades
   * @param {String} data Query string para parametros de búsqueda
   */
  findUniversities(data) {
    this.api.universities
      .find(data)
      .then((response) => {
        this.view.onFindUniversitiesResponse(response.data.data);
      })
      .catch(this.view.onError);
  }
  /**
   * Metodo para buscar Carreras
   * @param {String} data Query string para parametros de búsqueda
   */
  findCareers(data) {
    this.api.careers
      .find(data)
      .then((response) => {
        this.view.onFindCareersResponse(response.data.data);
      })
      .catch(this.view.onError);
  }
  /**
   * Metodo para buscar tipo de simulador
   */
  getSimulatorType(data, type) {
    return _.find(data, function (o) {
      return o.id == type;
    });
  }

  /**
   * Metodo para buscar tipo de simulador
   */
  getDifficultyLevel(data, type) {
    return _.find(data, function (o) {
      return o.id == type;
    });
  }

  // #endregion

  //#region MAPPING DATA

  removeAnswerEditor(answerEditors, index) {
    // answerEditors[index].updateState = SimulatorQuestionViewModel.UPDATE_STATES.REMOVE;
    answerEditors.splice(index, 1);
    _.forEach(answerEditors, (answerEditor, index) => {
      answerEditor.selector = `answer_editor_${index}`;
    });
  }

  renderAnswerEditors(answers, editors) {
    if (!_.isEmpty(editors)) {
      this.clearAnswerEditorsView(answers, editors)
    }

    return (!_.isEmpty(answers)) ? _.map(answers, (answer, index) => {
      let currentAnswer = {
        selector: `answer_editor_${index}`,
        component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
        updateState: answer.updateState,
        index: index
      };
      currentAnswer.component.root.innerHTML = answer.description
      currentAnswer.component.on('text-change', () => {
        answers[currentAnswer.index].updateData(currentAnswer.component.root.innerHTML, currentAnswer.title);
      })
      return currentAnswer
    }) : [];
  }

  clearAnswerEditorsView(answers, editors) {
    _.forEach(answers, (answer, index) => {
      let answerEditor = _.find(editors, {
        selector: `answer_editor_${index}`,
      });
      answer.description = answerEditor
        ? answerEditor.component.root.innerHTML
        : "";
    });

    let answerEditors = document.querySelectorAll('[class^="answer_editor"]');
    let qlToolbars = document.querySelectorAll(".el-table__row .ql-toolbar");
    let qlContainers = document.querySelectorAll(
      '[class^="answer_editor"] .ql-container'
    );
    _.forEach(answerEditors, (answerEditor, index) => answerEditor.remove());
    _.forEach(qlToolbars, (toolBar, index) => toolBar.remove());
    _.forEach(qlContainers, (container, index) => container.remove());
  }

  // #endregion

  // #region VALIDATIONS
  isAnswerValid(rule, value, callback, source, options) {
    if (value == "<p><br></p>") {
      return callback(new Error('Respuesta requerida'));
    } else {
      return callback();
    }
  }

  // #endregion
}
