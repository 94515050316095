<script>
import ModalCourseSubjectActivityComponent from '../../features/modals/ModalCourseSubjectActivityComponent';
export default {
  extends: ModalCourseSubjectActivityComponent,
  name: 'ModalCourseSubjectActivityView',
};
</script>

<template>
  <el-dialog
    title="Actividad"
    :visible.sync="conf.isVisible"
    width="42%"
    custom-class="course-subject-activity-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Actividad</legend>
        <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
            <el-form-item label="Título" prop="title">
              <el-input v-model="formData.title" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4" align="center">
            <el-form-item label="Orden" prop="sort">
              <el-input-number v-model="formData.sort" size="small" :disabled="true"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="5" :lg="5" :xl="5" align="center">
            <el-form-item label="Duración (Min.)" prop="duration">
              <el-input-number v-model.number="formData.duration" size="small"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5" align="center">
            <el-form-item label="¿Tiene Archivo?">
              <el-switch
                v-model="formData.hasFile"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5" align="center">
            <el-form-item label="¿Tiene Video?">
              <el-switch
                v-model="formData.hasVideo"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5" align="center">
            <el-form-item label="¿Respuesta Abierta?">
              <el-switch
                v-model="formData.hasOpenResponse"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4" align="center">
            <el-form-item label="¿Respuesta Multiple?">
              <el-switch
                v-model="formData.hasMultipleResponse"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
                @change="onChangeMultipleResponse"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4" align="center">
            <el-form-item label="¿Es Público?">
              <el-switch
                v-model="formData.isPublic"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descripción" prop="description">
              <el-input
                :row="3"
                v-model="formData.description"
                size="small"
                class="description-editor"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20" v-if="formData.hasFile">
          <el-col :span="24">
             <legend>Información de Archivo</legend>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20" v-if="formData.hasFile" v-loading="isFileLoading">
          <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14" align="center">
            <el-form-item prop="coverImage">
              <div  v-if="!formData.file && !formData.fileUrl" style="padding-top:30px;">
                <el-upload
                  class="file-uploader"
                  :name="'coverImageFileUploader'"
                  action
                  :on-success="onSuccessUploadActivityFile"
                  :before-upload="onBeforeUploadActivityFile"
                  :show-file-list="false"
                  :data="formData.file"
                >
                  <el-button size="medium" type="primary" class="btn-primary"
                    ><i class="el-icon-upload"></i> Click para subir archivo</el-button
                  >
                </el-upload>
              </div>
              <div v-if="isActivityFileTemp" style="padding-top:30px;">
                <el-button-group>
                  <el-button
                  size="medium"
                  type="primary"
                  class="btn-primary"
                  
                  @click="onShowActivityFile(formData)"
                  ><i class="el-icon-view"></i> Ver Archivo</el-button
                >
                  <el-button
                  size="medium"
                  type="danger"
                  class="btn-delete"
                  @click="onRemoveActivityFile"
                  ><i class="el-icon-circle-close"></i> Eliminar Archivo</el-button
                >
                </el-button-group>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10" align="center">
            <el-form-item label="¿Es Descargable?">
              <el-switch
                v-model="formData.isDownload"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20" v-if="formData.hasVideo">
          <el-col :span="24">
             <legend>Información de Video</legend>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-if="formData.hasVideo"
        >
          <el-col :span="24">
            <el-form-item label="Enlace Video" prop="videoUrl">
              <el-input v-model="formData.videoUrl" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20" v-if="formData.hasMultipleResponse">
          <el-col :span="24">
             <legend>Información de Respuestas</legend>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-if="formData.hasMultipleResponse" style="padding-top:10px;">
          <el-col :span="24">
            <el-button
              type="primary"
              size="medium"
              class="btn-primary"
              icon="el-icon-circle-plus"
              @click="onCreateCourseSubjectActivityAnswer"
            >
              Agregar Respuesta
            </el-button>
          </el-col>
        </el-row>
        <br />
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-loading="isAnswerLoading"
          v-if="formData.hasMultipleResponse"
        >
          <el-col :span="24">
            <el-table
              :data="formData.getCourseSubjectActivityAnswers()"
              empty-text="No se encontraron resultados."
              
            >
              <el-table-column label="RESPUESTA" min-width="300">
                <template slot-scope="scope">
                  <div>
                    <el-input v-model="scope.row.title" size="small" :class="'answer_editor_' + scope.$index + ' course_activity_answer'"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="CORRECTO" min-width="120" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-switch
                      v-model="scope.row.success"
                      active-color="#13ce66"
                      active-text="SI"
                      inactive-text="NO"
                      size="small"
                    >
                    </el-switch>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="OPCIONES"
                :width="90"
                align="center"
                text-align="center"
              >
                <template slot-scope="scope">
                  <el-button-group>
                    <el-button
                      type="default"
                      size="small"
                      icon="el-icon-delete"
                      @click="
                        onDeleteCourseSubjectActivityAnswer(scope.$index)
                      "
                    ></el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Aceptar</el-button
      >
    </span>
  </el-dialog>
</template>
