import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import CourseViewModel from '../../../../core/features/website/viewmodels/CourseViewModel';

export default {
	extends: ListComponent,
	data() {
		return {
			item: null,
			fullLoader: false,
			viewModel: null,
			isDisliked: true,
			isViewed: true,
			totalViewed: 0,
			modalItemCoursePromotionalVideo: {
				isVisible: false,
				isLoading: false,
			},
		}
	},
	props: {},
	mounted() { },
	beforeDestroy() { },
	created() {
		this.fullLoader = true;
		this.viewModel = new CourseViewModel(this);
		this.onActive();
	},
	methods: {
		onActive() {
			let courseId = this.$route.params.id;

			this.viewModel.onSearchItem(courseId);
		},
		onAllFilterChange(data, field) {
			this.onFilterChange(data, field);
		},
		mapCourseImageUrl(url) {
			console.log('mapCourseImageUrl')
			console.log((url) ? url : 'images/courses/add_img.jpg')
			return (url) ? url : '/images/courses/add_img.jpg';
		},
		getProfileImage(author) {
			return (author && author.profileImageUrl) ? author.profileImageUrl : '/images/left-imgs/img-1.jpg'
		},
		mapProfileImageUrl(userProfile) {
			return userProfile && userProfile.profileImageUrl
				? userProfile.profileImageUrl
				: "/images/left-imgs/img-2.jpg";
		},
		onShowPromotionalVideo() {
			this.viewModel.showModal(this.modalItemCoursePromotionalVideo, true);
		},
		hasPromotionalVideo() {
			return (this.item.current.promotionalVideoUrl)
		},
		mapTags(tags) {
			var collection = (tags) ? tags.split(',') : []
			return collection.join(' | ')
		},
		getSocialStatusClass(flag) {
			return (flag) ? 'lkcm152 active' : 'lkcm152'
		},
		hasFacebookPage(instructor) {
			return (
				!_.isNil(instructor.pageUrlFacebook) &&
				!_.isEmpty(instructor.pageUrlFacebook)
			);
		},
		hasInstagramPage(instructor) {
			return (
				!_.isNil(instructor.pageUrlInstagram) &&
				!_.isEmpty(instructor.pageUrlInstagram)
			);
		},
		hasLinkedinPage(instructor) {
			return (
				!_.isNil(instructor.pageUrlLinkedIn) &&
				!_.isEmpty(instructor.pageUrlLinkedIn)
			);
		},
		mapTotalCourses(instructor) {
			return instructor.totalCourse === 1
				? `${instructor.totalCourse} Curso`
				: `${instructor.totalCourse} Cursos`;
		},
		onShowPublicActivity(activity) {
			this.activityPreview = activity;
			this.viewModel.showModal(this.modalItemCoursePublicActivity, true);
		},
		orderCourseSubjects(collection) {
			return _.sortBy(collection, 'sort');
		},
		navigate(course) {
			this.$router.push({
				name: "blog course single",
				params: {
					id: course.id,
					title: course.title.replace(/ /g, '-').toLowerCase()
				}
			});
			location.reload();
		},
	},
	computed: {}
}