<script>
import CourseSubjectsComponent from '../../features/boxes/CourseSubjectsComponent';
import CourseActivitiesView from './CourseActivitiesView';

export default {
  extends: CourseSubjectsComponent,
  components: {
    'course-activities-view': CourseActivitiesView,
  },
};
</script>

<template>
  <div class="ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom">
    <el-collapse>
      <el-collapse-item :name="subjectIndex"
      v-for="(subject, subjectIndex) in orderSubjects(section.courseSubjects)" v-bind:key="subjectIndex">
      <template slot="title">
        <el-col :xs="18" :sm="18" :md="18" :lg="20" :xl="20">
          <div style="padding-left:30px;">
            <i class="uil uil-book-open"></i>
            {{ subject.title }}
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="3" :lg="4" :xl="3" class="hidden-xs-only hidden-sm-only" align="right">
          {{ subject.getTotalActivities() }} Actividades
        </el-col>
        <!-- <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2" align="center">
          {{ subject.getDuration() }}
        </el-col>-->
      </template>
      <course-activities-view :course-subject="subject" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
