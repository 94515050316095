<script>
import MainMenuComponent from '../features/MainMenuComponent';

export default {
  name: 'MainMenuView',
  mixins: [MainMenuComponent],
};
</script>
<template>
  <!-- Left Sidebar Start -->
  <nav class="vertical_nav">
    <div class="left_section menu_left" id="js-menu">
      <div class="left_section">
        <ul>
          <li class="menu--item" v-if="onCanShowModule('Dashboard-Module')">
            <router-link :to="'/profesor/resumen'" class="menu--link" title="Resumen">
              <i class="uil uil-apps menu--icon"></i>
              <span class="menu--label">Resumen</span>
            </router-link>
          </li>
          <li class="menu--item" v-if="onCanShowModule('LiveClassContent-Module')">
            <router-link
              :to="'/profesor/en-vivo'"
              class="menu--link"
              title="Clases en vivo"
            >
              <i class="uil uil-play menu--icon"></i>
              <span class="menu--label">En Vivo</span>
            </router-link>
          </li>
          <li class="menu--item" v-if="onCanShowModule('CourseContent-Module')">
            <router-link :to="'/profesor/cursos'" class="menu--link" title="Cursos">
              <i class="uil uil-book menu--icon"></i>
              <span class="menu--label">Cursos</span>
            </router-link>
          </li>
          <li class="menu--item" v-if="onCanShowModule('CourseContent-Module')">
            <router-link :to="'/profesor/actividades'" class="menu--link" title="Actividades">
              <i class="uil uil-notes menu--icon"></i>
              <span class="menu--label">Actividades</span>
            </router-link>
          </li>
          <!-- <li class="menu--item" v-if="onCanShowModule('StudentsContent-Module')">
            <router-link
              :to="'/profesor/estudiantes'"
              class="menu--link"
              title="Estudiantes"
            >
              <i class="fa fa-graduation-cap menu--icon"></i>
              <span class="menu--label">Estudiantes</span>
            </router-link>
          </li> -->
          <!-- <li class="menu--item" v-if="onCanShowModule('SimulatorsContent-Module')">
            <router-link
              :to="'/profesor/simuladores'"
              class="menu--link"
              title="Simuladores"
            >
              <i class="fas fa-building menu--icon"></i>
              <span class="menu--label">Simuladores</span>
            </router-link>
          </li> -->
          <li class="menu--item" v-if="onCanShowModule('NotificationsContent-Module')">
            <router-link
              :to="'/profesor/notificaciones'"
              class="menu--link"
              title="Notificaciones"
            >
              <i class="uil uil-bell menu--icon"></i>
              <span class="menu--label">Notificaciones</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/profesor/mi-cuenta'"
              class="menu--link"
              title="Notificaciones"
            >
              <i class="uil uil-user-check menu--icon"></i>
              <span class="menu--label">Mi Cuenta</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="left_section pt-2">
        <ul>
          <li class="menu--item menu--item__has_sub_menu">
            <label class="menu--link" title="Configuraciones">
              <i class="uil uil-cog menu--icon"></i>
              <span class="menu--label">Configuraciones</span>
            </label>
            <ul class="sub_menu">
              <li class="sub_menu--item">
                <router-link :to="'/profesor/configuraciones/categorias-de-cursos'" class="sub_menu--link">
                  Categorias de Cursos
                </router-link>
              </li>
            </ul>
          </li>
          <!-- <li class="menu--item">
            <a href="help.html" class="menu--link" title="Help">
              <i class="uil uil-question-circle menu--icon"></i>
              <span class="menu--label">Ayuda</span>
            </a>
          </li> -->
        </ul>
      </div>
      <div class="left_footer">
        <ul>
          <li><a href="/">Inicio</a></li>
          <li><a href="/acerca">Acerca</a></li>
          <li><a href="/noticias">Noticias</a></li>
          <li><a href="/contacto">Contacto</a></li>
        </ul>
        <div class="left_footer_content">
          <p>
            © 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.
          </p>
        </div>
      </div>
    </div>
  </nav>
  <!-- Left Sidebar End -->
</template>
