import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiSimulators extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findRandomQuestions(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/random-questions${parameters}`)
    }

    findDetails(id) {
        return this.client.get(`${this.endPoint}/${id}/details`)
    }

    generateTemporalSimulator(data) {
        return this.client.post(`${this.endPoint}/generate-temporal-simulator`, data)
    }

    findTemporalDetails(id) {
        return this.client.get(`${this.endPoint}/${id}/temporal-details`)
    }

    findQuestionProgress(data) {
        return this.client.post(`${this.endPoint}/question-answer-progress`, data)
    }

    findTemporalQuestionProgress(data) {
        return this.client.post(`${this.endPoint}/temporal-question-answer-progress`, data)
    }

    updateProgress(data) {
        return this.client.post(`${this.endPoint}/update-progress`, data)
    }

    updateTemporalProgress(data) {
        return this.client.post(`${this.endPoint}/update-temporal-progress`, data)
    }

    findSimulatorResult(simulatorId) {
        return this.client.get(`${this.endPoint}/${simulatorId}/result`)
    }

    getResultAnswersBySimulatorSection(simulatorId, simulatorSectionId) {
        return this.client.get(`${this.endPoint}/${simulatorId}/result-answers-by-section/${simulatorSectionId}`)
    }

    getSectionStatus(simulatorId, simulatorSectionId) {
        return this.client.get(`${this.endPoint}/${simulatorId}/section-status/${simulatorSectionId}`)
    }

    findTemporalSimulatorResult(simulatorId) {
        return this.client.get(`${this.endPoint}/${simulatorId}/temporal-result`)
    }
    
    findPublished(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/explorer${parameters}`)
    }

    getQuestionCategoriesByUniversities(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/question-categories-current-universities${parameters}`)
    }

    repeatSimulator(simulatorId) {
        return this.client.put(`${this.endPoint}/${simulatorId}/repeat-simulator`)
    }

    findHistory(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/all-students-by-simulator-solved${parameters}`)
    }

    findResultByStudent(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/result-by-student${parameters}`)
    }

    getResultAnswersBySimulatorSectionAndStudent(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/result-answers-by-section-and-student${parameters}`)
    }
}