<script>
import ModalSimulatorResultAnswersComponent from '../../features/modals/ModalSimulatorResultAnswersComponent';

export default {
  extends: ModalSimulatorResultAnswersComponent,
  name: 'ModalSimulatorResultAnswersView',
};
</script>

<template>
  <div>
    <el-dialog
      title="Resultados de Sección"
      :visible.sync="conf.isVisible"
      custom-class="simulator-modal simulator-result-expand-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <el-row class="row-bg" :gutter="20">
            <el-col :span="8" align="center">
              <i class="el-icon-edit-outline simulator-answer-icon selected"></i> <p class="simulator-answer-text">Seleccionado</p>
            </el-col>
            <el-col :span="8" align="center">
              <i class="el-icon-success simulator-answer-icon success" ></i> <p class="simulator-answer-text">Correcto</p>
            </el-col>
            <el-col :span="8" align="center">
              <i class="el-icon-error simulator-answer-icon error"></i> <p class="simulator-answer-text">Incorrecto</p>
            </el-col>
            
          </el-row>
          <el-table
                    :data="formData.simulatorQuestions"
                    style="width: 100%"
                    class="simulator-result-expand"
                    height="400">
                   
                   <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-row class="row-bg" :gutter="20">
                                <h4 style="padding:5px 7px;">Opciones</h4>
                                <el-col :span="24">
                                    <ul style="padding-top:10px;">
                                        <li v-for="(item, index) in props.row.selectedAnswers" v-bind:key="index" style="width: 100%; display:inline-block;">
                                            <el-col :span="21"><div v-html="item.answer"></div></el-col>
                                            <el-col :span="3" align="center">
                                                <i class="el-icon-edit-outline simulator-answer-icon selected" v-if="item.isSelected"></i>
                                                <!-- <i class="el-icon-circle-close simulator-answer-icon error" v-else></i> -->
                                            </el-col>
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                            <el-row class="row-bg" :gutter="20">
                                <h4 style="padding:5px 7px;">Opción Correcta</h4>
                                <el-col :span="24">
                                    <ul style="padding-top:10px;">
                                        <li v-for="(item, index) in props.row.successAnswers" v-bind:key="index">
                                            <el-col :span="21"><div v-html="item.answer"></div></el-col>
                                            <el-col :span="3" align="center">
                                                <i class="el-icon-success simulator-answer-icon success" v-if="item.success"></i>
                                                <i class="el-icon-error simulator-answer-icon error" v-else></i>
                                            </el-col>
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                   <el-table-column prop="question" label="Pregunta"></el-table-column>
                   <el-table-column label="¿Estado?" width="80" align="center">
                       <template slot-scope="scope">
                            <i class="el-icon-success simulator-answer-icon success" v-if="isAnswerSuccess(scope.row.selectedAnswers, scope.row.successAnswers)"></i>
                            <i class="el-icon-error simulator-answer-icon error" v-else></i>
                       </template>
                   </el-table-column>
                   </el-table>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>