import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import User from '../models/User'
import Role from '../models/Role'

export default class UserViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new User({
      state: "create",
      status: 1
    });
    return item;
  }

  onDelete(data) {
    let item = new User(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new User(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.users.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new User(data));
  }

  onSearchItem(id) {
    return this.api.users.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.users.create(data.toSend())
    else
      return this.api.users.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.users.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.users.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.users.export()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView(formData) {
    if (formData.state === 'update') {
      this.api.roles.find()
        .then((response) => {
          this.view.roles = response.data.data;
        })
        .catch(this.view.onError);
    }
    setTimeout(() => {
      this.showModalLoading(this.view.conf, false);
    }, 400);
  }

  // #endregion

  // #region QUERIES

  /**
   * Metodo para buscar roles de usuario
   * @param {String} data Query string para parametros de búsqueda
   */
  findRoles(data) {
    this.api.roles.find(data)
      .then((response) => this.view.onSearchRolesResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar estados de usuario
   * @param {String} data Query string para parametros de búsqueda
   */
  findUserStatuses() {
    this.api.userStatuses.find()
      .then((response) => this.view.onSearchUserStatusesResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  //#region MAPPING DATA

  mapRoles(collection) {
    return _.map(collection, item => new Role(item))
  }

  // #endregion

  // #region VALIDATIONS

  /**
   * Metodo para verificar si password con password de confirmación son iguales
   * @param {String} value Password a confirmar 
   * @param {*} callback Metodo de respuesta a la verificación
   */
  verifyConfirmationPassword(value, callback) {
    if (value === '') {
      callback(new Error('Favor de ingresar información.'))
    } else if (value !== this.view.formData.password) {
      callback(new Error('Las contraseñas no coinciden.'))
    } else {
      callback()
    }
  }

  // #endregion
}
