<script>
import ListSimulatorQuestionsComponent from '../../features/lists/ListSimulatorQuestionsComponent';
import ModalSimulatorQuestionView from '../modals/ModalSimulatorQuestionView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListSimulatorQuestionsComponent,
  name: 'ListSimulatorQuestionsView',
  components: {
    'modal-simulator-question-view': ModalSimulatorQuestionView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl course-categories">
      <el-col :xs="24" :sm="14" :md="14" :lg="9" :xl="6" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Pregunta</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Preguntas</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select :popper-append-to-body="true" size="small" v-model="filterOptions.courseCategoryFilter" filterable remote clearable placeholder="Categoria..."
                :remote-method="onSearchCourseCategory" @change="onAllFilterChange(filterOptions.courseCategoryFilter.id, 'SimulatorQuestionCategoryId')" @focus="onFocus"
                value-key="id">
                  <el-option v-for="item in courseCategories" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select :popper-append-to-body="true" size="small" v-model="filterOptions.difficultyLevelFilter" clearable placeholder="Dificultad..."
                @change="onAllFilterChange(filterOptions.difficultyLevelFilter.id, 'DifficultyLevel')" @focus="onFocus"
                value-key="id">
                  <el-option v-for="item in difficultyLevels" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select :popper-append-to-body="true" size="small" v-model="filterOptions.typeFilter" clearable placeholder="Tipo..."
                @change="onAllFilterChange(filterOptions.typeFilter.id, 'SimulatorExamType')" @focus="onFocus"
                value-key="id">
                  <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="4" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por Codigo." placement="top">
          <el-input size="small" placeholder="Buscar Codigo..." icon="search" v-model='filterOptions.codeFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.codeFilter, 'Code')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por nombre." placement="top">
          <el-input size="small" placeholder="Buscar nombre..." icon="search" v-model='filterOptions.nameFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.nameFilter, 'Name')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="CODIGO" min-width="100">
        <template slot-scope="scope">
         <div>
            {{ scope.row.code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="NOMBRE" min-width="350">
        <template slot-scope="scope">
          <div>
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CATEGORIA" min-width="200" align="center">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain" v-if="scope.row.simulatorQuestionCategory">{{ scope.row.simulatorQuestionCategory.name }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="TIPO" min-width="110" align="center">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.type === 1" type="info" effect="plain">PUBLICO</el-tag>
            <el-tag v-if="scope.row.type === 2" type="success" effect="plain">PRIVADO</el-tag>
            <el-tag v-if="scope.row.type === 3" type="warning" effect="plain">AMBAS</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="DIFICULTAD" min-width="110" align="center">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.difficultyLevel === 1" type="success" effect="plain">FACIL</el-tag>
            <el-tag v-if="scope.row.difficultyLevel === 2" type="warning" effect="plain">INTERMEDIO</el-tag>
            <el-tag v-if="scope.row.difficultyLevel === 3" type="danger" effect="plain">DIFICIL</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-simulator-question-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-simulator-question-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>