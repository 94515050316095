import _ from 'lodash'
import TimerHelper from '../../../../core/shared/helpers/TimerHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SimulatorQuestionLinkedList from  '../models/SimulatorQuestionLinkedList'

export default class SimulatorStudentDetailViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    onBindFormView(simulatorId) {
        this.api.simulators.findTemporalDetails(simulatorId)
            .then((response) => {
                let simulatorResponse = response.data;
                let alphabet = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
                
                simulatorResponse.data.simulatorSections.forEach(section => {
                    section.simulatorQuestions.forEach((question, index) => {
                        question.name = `${index + 1}) ${question.name} `

                        question.simulatorAnswers.forEach((answer, index) => {
                            answer.description = answer.description.replace('<p>',`<p> ${alphabet[index]}) `)
                        });
                    })

                });
                
                this.view.onFindSimulatorTemporalResponse(simulatorResponse)
            })
            .catch(this.view.onError)
    }
    
    initLinkedList(collection) {
        let questions = this.mapQuestions(collection)
        let result = new SimulatorQuestionLinkedList();
        _.forEach(questions, (question) => {
            result.push(question);
        })
        return result;
    }

    mapQuestions(collection) {
        return _.map(collection, (item) => {
            item.answers = []
            return item
        })
    }

    nextNode(currentNode) {
        return currentNode.next;
    }

    prevNode(currentNode) {
        return currentNode.prev;
    }

    changeQuestion(data) {
        this.api.simulators.findTemporalQuestionProgress(data)
            .then((response) => this.view.onChangeQuestionResponse(response.data))
            .catch(this.view.onError);
    }

    mapSelectedAnswers(collection) {
        return _.map(collection, item => item.simulatorAnswerId)
    }

    initTimer(options) {
        return TimerHelper.startCountDown(options)
    }
    
    updateProgress(data) {
        this.api.simulators.updateTemporalProgress(data)
            .then((response) => this.view.onUpdateProgressResponse(response.data))
            .catch(this.view.onError)
    }

    mapQuestionToUpdateProgress(currentQuestion, answers) {
        return _.map(answers, (answerId) => {
            let answer = _.find(currentQuestion.simulatorAnswers, { id: answerId })
            return {
                success: answer.success || false,
                simulatorAnswerId: answerId
            }
        })
    }

    getTime(simulator) {
        let duration = simulator.duration
        let timerType = simulator.timerType
        let sectionsQuantity = _.size(simulator.simulatorSections)
        let questionQuantity = simulator.questionQuantity
        var result = 0;
        switch (timerType) {
            case 1:
                result = duration;
                break;
            case 3:
                result = sectionsQuantity * duration;
                break;
            case 4:
                result = questionQuantity * duration;
                break;
            default:
                result = 0;
                break;
        }
        return result;
    }

    isSectionSelectable(isTimer, isTimerStarted) {
        if(!isTimer) return true
        if(isTimer && isTimerStarted) return true
        if(isTimer && !isTimerStarted) return false
    }

    findSimulatorResult(simulatorId) {
        this.api.simulators.findTemporalSimulatorResult(simulatorId)
            .then((response) => this.view.onFindSimulatorResultResponse(response.data))
            .catch(this.view.onError)
    }

    getStatusTag(percentage) {
        if (percentage >= 0 && percentage < 70) {
            return 'score-danger score-tag medium'
        }
        if (percentage >= 70 && percentage < 90) {
            return 'score-warning score-tag medium'
        }
        if (percentage > 90 && percentage <= 100) {
            return 'score-success score-tag medium'
        }
    }
}
