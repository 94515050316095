import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import StudentViewModel from '../../../../core/features/students/viewmodels/StudentViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'StudentsContent-Students',
            viewModel: null,
            permissions: null,
            modules: [],
            checkAll: false,
            isIndeterminate: true,
            universities: [],
            knowledgeAreas: [],
            checkedKnowledgeAreas: [],
            isKnowledgeAreas: true,
            collection: [],
            multipleSelection: [],
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Descripción requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new StudentViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            //this.viewModel.onBindForm();
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateFormSelectCarrer(valid)) {
                    EventBus.$emit('onSubmitedSelectCareer', this.multipleSelection);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedSelectCareer', null);
        },
        onFindUniversity(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onSearchUniversitiesResponse(response) {
            this.universities = response;
        },
        onSelectUniversity(data) {
            if (data) {
                this.viewModel.findKnowledgeAreas(`UniversityId=${data.id}`)
            } else {
                this.knowledgeAreas = []
                this.isUniversity = true;
            }
        },
        onFindKnowledgeAreasResponse(response) {
            this.knowledgeAreas = response.data;
            this.isUniversity = false;
        },
        handleCheckAllChange(val) {
            var allKnowledgeAreas = this.viewModel.mapKnowledgeAreas(this.knowledgeAreas);
            this.checkedKnowledgeAreas = val ? allKnowledgeAreas : [];
            this.isIndeterminate = false;
        },
        handleCheckedKnowledgeAreasChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.knowledgeAreas.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.knowledgeAreas.length;
        },
        onFindAllCareersByKnowledgeAreaIds() {
            if (this.checkedKnowledgeAreas.length > 0) {
                this.viewModel.findAllCareersByKnowledgeAreaIds(this.checkedKnowledgeAreas)
            } else {
                // Mensaje de selecionar minimo un área
            }
        },
        onFindAllCareersByKnowledgeAreaIdsResponse(response) {
            this.collection = response.data;
        },
        clearSelection() {
            this.universities = []
            this.knowledgeAreas = []
            this.checkedKnowledgeAreas = []
            this.isKnowledgeAreas = true
            this.collection = []
            this.multipleSelection = []
            this.formData.university = null
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}