<script>
import BlogCourseSinglePagesComponent from '../features/lists/BlogCourseSinglePagesComponent';
import CouseSectionsView from '../boxes/CourseSectionsView';
import ModalCoursePromotionalVideoView from '../../courses/views/modals/ModalCoursePromotionalVideoView';
import FooterView from '../../shared/views/MainFooterView';

export default {
  extends: BlogCourseSinglePagesComponent,
  name: 'BlogCourseSinglePageView',
  components: {
    'course-sections-view': CouseSectionsView,
    'modal-course-promotional-video-view': ModalCoursePromotionalVideoView,
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div class="blog-course-single-page">
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <div class="wrapper _bg4586 _new89" v-if="item">
      <div class="_215b15">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title125">
                <div class="titleleft">
                  <div class="ttl121">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        <li class="breadcrumb-item">
                          <a href="/cursos">Cursos</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          {{ item.current.title }}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div class="titleright">
                  <a href="/cursos" class="blog_link"
                    ><i class="uil uil-angle-double-left"></i>Regresar a
                    Cursos</a
                  >
                </div>
              </div>
              <div class="title126">
                <h2>{{ item.current.title }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89" v-if="item">
      <div class="faq1256" v-loading="fullLoader" element-loading-text="Cargando Curso...">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-lg-8">
              <div class="_215b01">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="section3125">
                        <div class="row justify-content-center">
                          <div class="col-xl-4 col-lg-5 col-md-6">
                            <div class="preview_video" v-if="item.current">
                              <a
                                href="#"
                                class="fcrse_img"
                                data-toggle="modal"
                                data-target="#videoModal"
                              >
                                <img :src="mapCourseImageUrl(item.current.coverImageUrl)" alt="" />
                                <div
                                  v-if="hasPromotionalVideo()"
                                  class="course-overlay"
                                  @click="onShowPromotionalVideo"
                                >
                                  <!-- <div class="badge_seller">Bestseller</div> -->
                                  <span class="play_btn1"
                                    ><i class="uil uil-play"></i
                                  ></span>
                                  <span class="_215b02">Ver Video</span>
                                </div>
                                <div v-else class="course-overlay"></div>
                              </a>
                            </div>
                          </div>
                          <div
                            class="col-xl-8 col-lg-7 col-md-6"
                            v-if="item.current"
                          >
                            <div class="_215b03">
                              <h2>{{ item.current.title }}</h2>
                              <span class="_215b04">{{
                                item.current.description
                              }}</span>
                            </div>
                            <div class="_215b05">
                              {{ item.current.totalEnrolled }} Estudiante(s) en
                              el curso
                            </div>

                            <div class="_215b05">
                              {{ mapTags(item.current.tags) }}
                            </div>

                            <div class="_215b05" v-if="item.current.lastUpdate">
                              Ultima actualizaci&oacute;n
                              {{ item.current.lastUpdate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_215b15 _byt1458">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="user_dt5">
                        <div
                          class="user_dt_left"
                          v-if="item.current && item.current.author"
                        >
                          <div class="live_user_dt">
                            <div class="user_img5">
                              <img
                                :src="getProfileImage(item.current.author)"
                                alt=""
                              />
                            </div>
                            <div class="user_cntnt">
                              <a href="#" class="_df7852"
                                >{{ item.current.author.firstName }}
                                {{ item.current.author.lastName }}</a
                              >
                              <span
                                class="subscribe-btn btn-primary btn-author"
                              >
                                Autor
                              </span>
                              <!-- <el-button slot="reference" class="subscribe-btn btn-primary">Ver Perfil</el-button> -->
                            </div>
                          </div>
                        </div>
                        <div class="user_dt_right">
                          <ul>
                            <!--
                            <li>
                              <div :class="getSocialStatusClass(isViewed)" disabled>
                                <i class="uil uil-eye"></i><span>{{totalViewed}}</span>
                              </div>
                            </li>
                            <li @click="onDoLike">
                              <span :class="getSocialStatusClass(isLiked)"
                                ><i class="uil uil-thumbs-up"></i
                                ><span>{{ totalLiked }}</span></span
                              >
                            </li>
                            <li @click="onDoDislike">
                              <span :class="getSocialStatusClass(isDisliked)"
                                ><i class="uil uil-thumbs-down"></i
                                ><span>{{ totalDisliked }}</span></span
                              >
                            </li>
                            -->
                          </ul>
                        </div>
                      </div>

                      <div class="course_tabs">
                        <nav>
                          <div
                            class="nav nav-tabs tab_crse justify-content-center"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              class="nav-item nav-link active"
                              id="nav-about-tab"
                              data-toggle="tab"
                              href="#nav-about"
                              role="tab"
                              aria-selected="true"
                              >Acerca del Curso</a
                            >
                            <a
                              class="nav-item nav-link"
                              id="nav-courses-tab"
                              data-toggle="tab"
                              href="#nav-courses"
                              role="tab"
                              aria-selected="false"
                              >Contenido de Curso</a
                            >
                            <a
                              class="nav-item nav-link"
                              id="nav-instructors-tab"
                              data-toggle="tab"
                              href="#nav-instructors"
                              role="tab"
                              aria-selected="false"
                              >Profesores</a
                            >
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_215b17">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="course_tab_content">
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="nav-about"
                            role="tabpanel"
                          >
                            <div class="container" v-if="item.current">
                              <div class="crse_content">
                                <h3>Acerca del Curso</h3>
                              </div>
                              <span v-html="item.current.about"></span>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="nav-courses"
                            role="tabpanel"
                            v-if="item.current"
                          >
                            <div class="crse_content">
                              <el-col
                                :xs="18"
                                :sm="18"
                                :md="18"
                                :lg="20"
                                :xl="20"
                              >
                                <h3>Contenido de Curso</h3>
                              </el-col>
                              <!--
                              <el-col :span="2" align="center">
                                <span class="_fgr123">
                                  {{ item.current.getTotalSections() }}
                                  Secciones
                                </span>
                              </el-col>
                              -->
                              <el-col
                                :xs="6"
                                :sm="6"
                                :md="4"
                                :lg="2"
                                :xl="2"
                                align="center"
                              >
                                <span class="_fgr123">
                                  {{ item.current.getDuration()
                                  }}<small>(Min)</small>
                                </span>
                              </el-col>
                              <course-sections-view
                                :course-details="item.current"
                              />
                              <!-- Finalidazión de Collapse -->
                              <!-- <a class="btn1458" href="#">20 More Sections</a> -->
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="nav-instructors"
                            role="tabpanel"
                            v-if="item.current"
                          >
                            <div class="container">
                              <div class="crse_content">
                                <h3>Profesores</h3>
                              </div>
                              <el-row>
                                <el-col
                                  :xs="24"
                                  :sm="12"
                                  :md="12"
                                  :lg="8"
                                  :xl="8"
                                  class="block"
                                  v-for="instructor in item.current.instructors"
                                  :key="instructor.id"
                                >
                                  <div style="margin: 5px">
                                    <div
                                      class="
                                        fcrse_1
                                        mt-30
                                        course-detail-instructor
                                      "
                                    >
                                      <div class="tutor_img">
                                        <img
                                          :src="
                                            mapProfileImageUrl(
                                              instructor.userProfile
                                            )
                                          "
                                          alt=""
                                        />
                                      </div>
                                      <div class="tutor_content_dt">
                                        <!--
                                        <div class="tutor150">
                                          <a href="instructor_profile_view.html" class="tutor_name">{{instructor.userProfile.firstName}} {{instructor.userProfile.lastName}}</a>
                                          <div class="mef78" title="Verify">
                                            <i class="uil uil-check-circle"></i>
                                          </div>
                                        </div> -->
                                        <div class="tutor150">
                                          <span class="tutor_name">{{
                                            instructor.userProfile.firstName
                                          }}</span>
                                        </div>
                                        <div class="tutor150">
                                          <span class="tutor_name">{{
                                            instructor.userProfile.lastName
                                          }}</span>
                                        </div>
                                        <div class="mef78" title="Verify">
                                          <i class="uil uil-check-circle"></i>
                                        </div>
                                        <el-popover
                                          width="400"
                                          placement="right"
                                          trigger="click"
                                        >
                                          <div>
                                            <h4>Acerca</h4>
                                            <p>{{ instructor.about }}</p>
                                          </div>
                                          <br />
                                          <div>
                                            <h4>Especialidad</h4>
                                            <p>
                                              {{
                                                instructor.educationSpecialty
                                              }}
                                            </p>
                                          </div>
                                          <br />
                                          <div>
                                            <h4>Redes Sociales</h4>
                                            <br />
                                            <ul class="tutor_social_links">
                                              <li>
                                                <a
                                                  v-if="
                                                    hasFacebookPage(instructor)
                                                  "
                                                  :href="
                                                    instructor.pageUrlFacebook
                                                  "
                                                  class="fb"
                                                  target="_blank"
                                                  ><i
                                                    class="fab fa-facebook-f"
                                                  ></i
                                                ></a>
                                              </li>
                                              <li>
                                                <a
                                                  v-if="
                                                    hasInstagramPage(instructor)
                                                  "
                                                  :href="
                                                    instructor.pageUrlInstagram
                                                  "
                                                  class="in"
                                                  target="_blank"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  v-if="
                                                    hasLinkedinPage(instructor)
                                                  "
                                                  :href="
                                                    instructor.pageUrlLinkedIn
                                                  "
                                                  class="ln"
                                                  target="_blank"
                                                >
                                                  <i
                                                    class="fab fa-linkedin-in"
                                                  ></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                          <el-button
                                            slot="reference"
                                            type="text"
                                            >Ver m&aacute;s...</el-button
                                          >
                                        </el-popover>
                                      </div>
                                    </div>
                                  </div>
                                </el-col>
                              </el-row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="blog_pagination">
                <a
                  @click="navigate(item.preview)"
                  class="bp_left course-pointer"
                  v-if="item.preview"
                >
                  <i class="uil uil-angle-double-left"></i>
                  <div class="kslu15">
                    <div class="prevlink">Anterior</div>
                    <div class="prev_title">{{ item.preview.title }}</div>
                  </div>
                </a>
                <a
                  @click="navigate(item.next)"
                  class="bp_right course-pointer"
                  v-if="item.next"
                >
                  <div class="kslu16">
                    <div class="prevlink1">Siguiente</div>
                    <div class="prev_title1">{{ item.next.title }}</div>
                  </div>
                  <i class="uil uil-angle-double-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-view></footer-view>
      <modal-course-promotional-video-view
        :conf="modalItemCoursePromotionalVideo"
        :formData="item.current"
        v-if="modalItemCoursePromotionalVideo.isVisible"
      />
    </div>
    <!-- Body End -->
  </div>
</template>
