import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import FileHelper from '../../../shared/helpers/FileHelper'
import SimulatorOfficial from '../models/SimulatorOfficial'
import SimulatorOfficialQuestion from '../models/SimulatorOfficialQuestion'
import SimulatorOfficialSection from '../models/SimulatorOfficialSection'

export default class SimulatorOfficialViewModel extends BaseViewModel {
	constructor(view) {
		super(view)
	}
	// INICIALIZADORES
	static UPDATE_STATES = {
		ADD: 1,
		EDIT: 2,
		REMOVE: 3,
	}

	//#region CRUD

	onCreate() {
		let item = new SimulatorOfficial({
			simulatorSections: [],
			state: "create",
			status: 1
		});
		return item;
	}

	onDelete(data) {
		let item = new SimulatorOfficial(data);
		item.state = "delete";
		return item;
	}

	onEdit(data) {
		let item = new SimulatorOfficial(data);
		item.state = "update";
		return item;
	}

	onSearchCollection() {
		return this.api.simulators.find(this.getSearchQuery())
	}

	mapCollection(collection) {
		return _.map(collection, (data) => new SimulatorOfficial(data));
	}

	onSearchItem(id) {
		return this.api.simulators.findOne(id)
	}

	onSave(data) {
		if (data.state === 'create')
			return this.api.simulators.create(this.mapDataToFormData(data.toSend(), this.view.coverImageFile));
		else
			return this.api.simulators.update(data.id, this.mapDataToFormData(data.toSend(), this.view.coverImageFile));
	}

	onDestroy(context, id) {
		context.api.simulators.delete(id)
			.then(response => context.view.onSuccess(response.data))
			.catch(context.view.onError)
	}

	onCancel(context) {
		context.view.item = null;
	}

	import(file) {
		this.api.simulators.import(file)
			.then(response => this.view.onImportResponse(response.data))
			.catch(this.view.onError)
	}

	exportToExcel() {
		this.api.simulators.exportToExcel()
			.then(response => this.view.onExportResponse(response.data))
			.catch(this.view.onError)
	}

	copyRegister(id) {
		this.api.simulators.copyRegister(id)
			.then(response => this.view.onCopyResponse(response.data))
			.catch(this.view.onError)
	}

	updateSectionQuestions(sections, questionIndex, item) {
		sections[questionIndex] = item;
	}

	addNewSectionQuestions(sections, item) {
		sections.push(item)
	}

	removeSectionQuestions(section, questionIndex) {
		sections.splice(questionIndex, 1);
	}

	// #endregion

	// #region BINDING DATA

	bindFormView() {
		this.api.difficultyLevels.find()
			.then((response) => {
				this.view.difficultyLevels = response.data.data;
				return this.api.universities.find()
			})
			.then((response) => {
				this.view.universities = response.data.data;
				if (this.view.formData.state === 'update') {
					this.view.careers = (this.view.formData.career) ? [this.view.formData.career] : [];
					this.view.formData.timerType = this.getSimulatorType(this.view.timerTypes, this.view.formData.timerType)
					this.view.formData.difficultyLevel = this.getDifficultyLevel(this.view.difficultyLevels, this.view.formData.difficultyLevel)
					this.view.coverImageUrl = (this.view.formData.coverImageUrl) ? this.view.formData.coverImageUrl : this.view.coverImageUrl
					this.view.formData.simulatorSections = _.map(this.view.formData.simulatorSections, (section) => new SimulatorOfficialSection(section))
				}
				setTimeout(() => {
					this.view.conf.isLoading = false;
				}, 400);
			})
			.catch(this.view.onError)
	}

	filterRemovedSections(collection) {
		return _.filter(collection, (item) => {
			return item.updateState != SimulatorOfficialViewModel.UPDATE_STATES.REMOVE
		})
	}

	// #endregion

	// #region QUERIES
	/**
   * Metodo para buscar publishedStatuses
   * @param {String} data Query string para parametros de búsqueda
   */
	 findAllSimulators(data) {
		this.api.simulators.find(data)
			.then((response) => this.view.onSearchResponse(response.data, response.headers))
			.catch(this.view.onError)
	}
	findResultCollection(data) {
		this.api.simulators.findResultByStudent(data)
			.then((response) => this.view.onFindResultCollectionResponse(response.data.data))
			.catch(this.view.onError);
	}
	findHistoryCollection() {
		this.api.simulators.findHistory(this.getSearchQuery())
			.then((response) => this.view.onFindHistoryCollectionResponse(response.data.data))
			.catch(this.view.onError);
	}
	/**
   * Metodo para buscar publishedStatuses
   * @param {String} data Query string para parametros de búsqueda
   */
	findSimulators() {
		this.api.simulators.find(this.getSearchQuery())
			.then((response) => this.view.onSearchResponse(response.data, response.headers))
			.catch(this.view.onError)
	}
	/**
   * Metodo para buscar publishedStatuses
   * @param {String} data Query string para parametros de búsqueda
   */
	findPublished() {
		this.api.simulators.findPublished(this.getSearchQuery())
			.then((response) => this.view.onSearchResponse(response.data, response.headers))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findSimulatorOfficialExamTypes(data) {
		this.api.simulatorExamnTypes.find(data)
			.then((response) => this.view.onFindSimulatorOfficialExamTypesResponse(response.data.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findKnowledgeAreas(data) {
		this.api.knowledgeAreas.find(data)
			.then((response) => this.view.onFindKnowledgeAreasResponse(response.data.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar dificultades
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findDifficultyLevel(data) {
		this.api.difficultyLevels.find(data)
			.then((response) => this.view.onFindDifficultyLevelsResponse(response.data))
			.catch(this.view.onError)
	}

	findCareers(query) {
		this.api.careers.find(query)
			.then((response) => this.view.onFindCareersResponse(response.data))
			.catch(this.view.onError)
	}

	/**
	 * Metodo para buscar tipo de simulador
	 */
	getSimulatorType(data, type) {
		return _.find(data, function (o) {
			return o.id == type;
		});
	}

	/**
	 * Metodo para buscar dificultad de simulador
	 */
	getDifficultyLevel(data, type) {
		return _.find(data, function (o) {
			return o.id == type;
		});
	}


	// #endregion

	//#region MAPPING DATA
	createSectionQuestion(sections) {
		var item = new SimulatorOfficialSection({
			name: '',
			state: 'create',
			descripton: '',
			sort: _.size(sections) + 1,
			simulatorQuestions: [],
		});

		return item;
	}

	editSection(data) {
		var item = new SimulatorOfficialSection(data);
		item.updateState = 3,
			item.state = 'update';
		return item;
	}

	changeDrag(collection) {
		var sections = this.filterRemovedSections(collection)
		for (let index = 0; index < sections.length; index++) {
			const element = sections[index];
			element.sort = index + 1;
			element.updateState = (element.updateState == 1) ? element.updateState : 2;
		}
	}

	allowDuration(formData) {
		return formData.isTimer && (!_.isNil(formData.timerType) && !_.isEmpty(formData.timerType))
	}

	convertFileToBase64(file) {
		return FileHelper.toBase64(file);
	}

	totalPartialQuestions(collection) {
		let questionQuantityCount = 0;

		if (!_.isUndefined(collection) && _.size(collection) != 0) {

			collection.forEach(element => {
				questionQuantityCount += element.questionQuantity;
			});
			return questionQuantityCount;

		} else {
			return questionQuantityCount;
		}

	}

	mapDataToFormData(data, coverImageFile) {
		const fd = new FormData()
		fd.append('coverImageFile', coverImageFile)
		fd.append('simulator', JSON.stringify(data))

		return fd;
	}

	// #endregion

	// #region VALIDATIONS
	/**
   * Metodo para validación de formulario de recepcion para verificar que vengan kits.
   */
	validateForm(valid) {
		var isValid = false
		let questionQuantityCount = 0;

		if (_.size(this.view.formData.simulatorSections) != 0) {

			this.view.formData.simulatorSections.forEach(element => {
				questionQuantityCount += element.questionQuantity;
			});

			if (this.view.formData.questionQuantity == questionQuantityCount) {
				if (valid) isValid = true
			} else {
				this.view.onError(new Error(
					'Ocupa agregar el numero de preguntas indicado'
				))
			}
		} else {
			if (valid) {
				this.view.onError(new Error(
					'Ocupa al menos agregar una sección'
				))
			}
		}
		return isValid
	}
	/**
   * Metodo para validación de formulario para verificar que venga el numero de preguntas.
   */
	validateFormSection(formData) {
		var isValid = false

		if (!_.isUndefined(formData.university)) {
			if (!_.isUndefined(formData.career)) {
				if (!_.isUndefined(formData.questionQuantity) && formData.questionQuantity > 0) {
					isValid = true
				} else {
					this.view.onError(new Error(
						'Ocupa agregar el Numero de Preguntas'
					))
				}
			} else {
				this.view.onError(new Error(
					'Ocupa Seleccionar la carrera.'
				))
			}
		} else {
			this.view.onError(new Error(
				'Ocupa Seleccionar la universidad.'
			))
		}
		return isValid
	}

	// #endregion

	getStatusTag(percentage) {
        if (percentage >= 0 && percentage < 70) {
            return 'score-danger score-tag medium'
        }
        if (percentage >= 70 && percentage < 90) {
            return 'score-warning score-tag medium'
        }
        if (percentage > 90 && percentage <= 100) {
            return 'score-success score-tag medium'
        }
    }

    getResultAnswersBySimulatorSectionAndStudent(simulatorId, simulatorSectionId, studentId) {
		this.api.simulators.getResultAnswersBySimulatorSectionAndStudent(`SimulatorId=${simulatorId}&SimulatorSectionId=${simulatorSectionId}&StudentId=${studentId}`)
			.then((response) => this.view.onGetResultAnswersBySimulatorSectionAndStudentResponse(response.data))
			.catch(this.view.onError)
	}
    
    getSectionStatus(simulatorId, simulatorSectionId) {
        this.api.simulators.getSectionStatus(simulatorId, simulatorSectionId)
			.then((response) => this.view.onGetSectionStatusResponse(response.data))
			.catch(this.view.onError)
    }
}
