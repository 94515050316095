<script>
import ListSimulatorQuestionCategoriesComponent from '../../features/lists/ListSimulatorQuestionCategoriesComponent';
import ModalSimulatorQuestionCategoryView from '../modals/ModalSimulatorQuestionCategoryView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListSimulatorQuestionCategoriesComponent,
  name: 'ListSimulatorQuestionCategoryView',
  components: {
    'modal-simulator-question-category-view': ModalSimulatorQuestionCategoryView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl simulator-question-categories">
      <el-col :xs="24" :sm="13" :md="13" :lg="6" :xl="5" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Categoria</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Categorias</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="4" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por Codigo." placement="top">
          <el-input size="small" placeholder="Buscar Codigo..." icon="search" v-model='searchCode'
          @keyup.enter.native="onAllFilterChange(searchCode, 'Code')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="6" :xl="5">
        <el-tooltip class="item" effect="dark" content="Buscar por nombre." placement="top">
          <el-input size="small" placeholder="Buscar nombre..." icon="search" v-model='searchName'
          @keyup.enter.native="onAllFilterChange(searchName, 'Name')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="CODIGO" min-width="120">
        <template slot-scope="scope">
         <div>
            {{ scope.row.code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="NOMBRE" min-width="250">
        <template slot-scope="scope">
          <div>
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-simulator-question-category-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-simulator-question-category-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
