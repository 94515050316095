import _ from 'lodash'
import PermissionHelper from "../shared/helpers/PermissionHelper";
import {EventBus} from '../shared/helpers/EventHelper';

export default {
  methods: {
    canShow(moduleName) {
      return PermissionHelper.canShow(moduleName, PermissionHelper.getPermissions())
    },
    canRead(moduleName) {
      return PermissionHelper.canRead(moduleName, PermissionHelper.getPermissions())
    },
    canCreate(moduleName) {
      return PermissionHelper.canCreate(moduleName, PermissionHelper.getPermissions())
    },
    canUpdate(moduleName) {
      return PermissionHelper.canUpdate(moduleName, PermissionHelper.getPermissions())
    },
    canDelete(moduleName) {
      return PermissionHelper.canDelete(moduleName, PermissionHelper.getPermissions())
    },
    onError(error) {
      console.log(error)
      this.fullLoader = false;
      if (error.response) {
        switch (error.response.status) {
          case 400:
          case 404:
            this.onRequestError(error);
            break;
          case 403:
            this.onPermissionsError(error);
            break;
          case 401:
            this.onAuthenticationError(error);
            break;
          case 500:
            this.onServerError(error);
            break;
          default:
            this.onBaseError(error);
        }
      } else {
        this.onBaseError(error);
      }
    },
    onBaseError(error) {
      if (error instanceof Error) {
        this.viewModel.showNotification("error", "Error", error.message);
      } else {
        // Errores sin solicitud a servidor
        var messages = _.flattenDeep(
          _.toArray(_.toArray(error.data.errors)[0])
        );
        messages.forEach(message => {
          setTimeout(() => {
            this.viewModel.showNotification("error", "Error", message);
          }, 150);
        });
        //this.showNotification('error', 'Error', _.flattenDeep(_.toArray(_.toArray(error.data.errors)[0])).toString());
      }
    },
    onPermissionsError() {
      // Errores sin permisos
      this.viewModel.showNotification(
        "error",
        "Error",
        "No tienes permisos para realizar esta acción."
      );
    },
    onAuthenticationError() {
      this.viewModel.showNotification(
        "error",
        "Error",
        "Su sesión ha finalizado."
      );
      setTimeout(() => {
        //SessionHelper.removeSession();
        this.view.$nextTick(() => this.view.$router.push("/"));
      }, 1200);
    },
    onRequestError(error) {
      var messages = _.flattenDeep(
        _.toArray(_.toArray(error.response.data)[0])
      );
      var that = this;
      messages.forEach(function(message) {
        setTimeout(() => {
          // that.viewModel.showNotification("warning", "Advertencia", message);
          that.onShowWarning(message);
        }, 150);
      });
    },
    onServerError(error) {
      // this.viewModel.showNotification("error", "Error", error.response.data);
      this.onShowError(error.response.data);
    },
    onShowWarningList(errors) {
      let resultErrors = [];
      Object.entries(errors).forEach((error) => {
        resultErrors.push(error[1][0].message);
      });
      setTimeout(() => {
        this.notifyWarning(resultErrors);
      }, 400);
    },
    notifyWarning(message) {
      this.$notify({
        title: "ADVERTENCIA",
        message: _.isArray(message) ? this.mapListMessages(message) : message,
        type: "warning",
      });
    },
    mapListMessages(collection) {
      const h = this.$createElement;
      let list = h(
        "ul",
        null,
        collection.map((item) => h("li", null, item))
      );
      return list;
    },
    onShowSuccess(message) {
      this.$notify({
        title: "FINALIZADO",
        message: message,
        type: "success"
      });
    },
    onShowWarning(message) {
      this.$notify({
        title: "ADVERTENCIA",
        message: message,
        type: "warning"
      });
    },
    onShowError(message) {
      this.$notify({
        title: "ERROR",
        message: message,
        type: "error"
      });
    },
    onNotifyEvent(featureName, data) {
      EventBus.$emit(featureName, data);
    },
  }
};
