import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Career from '../models/Career'

export default class CareerViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    // INICIALIZADORES

    //#region CRUD

    onCreate() {
        let item = new Career({
            state: "create",
            status: 1
        });
        return item;
    }

    onDelete(data) {
        let item = new Career(data);
        item.state = "delete";
        return item;
    }

    onEdit(data) {
        let item = new Career(data);
        item.state = "update";
        return item;
    }

    onSearchCollection() {
        return this.api.careers.find(this.getSearchQuery())
    }

    mapCollection(collection) {
        return _.map(collection, (data) => new Career(data));
    }

    onSearchItem(id) {
        return this.api.careers.findOne(id)
    }

    onSave(data) {
        if (data.state === 'create')
            return this.api.careers.create(data.toSend())
        else
            return this.api.careers.update(data.id, data.toSend());
    }

    onDestroy(context, id) {
        context.api.careers.delete(id)
            .then(response => context.view.onSuccess(response.data))
            .catch(context.view.onError)
    }

    onCancel(context) {
        context.view.item = null;
    }

    import(file) {
        this.api.careers.import(file)
            .then(response => this.view.onImportResponse(response.data))
            .catch(this.view.onError)
    }

    exportToExcel() {
        this.api.careers.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    // #endregion

    // #region BINDING DATA

    bindFormView() {
        if (this.view.formData.state === 'update') {
            if (!_.isEmpty(this.view.formData.university)) {
                this.view.universities = [this.view.formData.university]
                this.view.knowledgeAreas = [this.view.formData.knowledgeArea]
                this.view.isUniversity = false;
            }
        }
        setTimeout(() => {
            this.showModalLoading(this.view.conf, false);
        }, 400);
    }

    // #endregion

    // #region QUERIES

    /**
     * Metodo para buscar categorias de cursos
     * @param {String} data Query string para parametros de búsqueda
     */
    findKnowledgeAreas(data) {
        this.api.knowledgeAreas.find(data)
            .then((response) => this.view.onFindKnowledgeAreasResponse(response.data))
            .catch(this.view.onError)
    }
    /**
       * Metodo para buscar Universidades
       * @param {String} data Query string para parametros de búsqueda
       */
    findUniversities(data) {
        this.api.universities.find(data)
            .then((response) => {
                this.view.onFindUniversitiesResponse(response.data.data)
            })
            .catch(this.view.onError)
    }


    // #endregion

    //#region MAPPING DATA


    // #endregion

    // #region VALIDATIONS

    // #endregion
}
