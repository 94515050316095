import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Subscription from '../models/Subscription'

export default class SubscriptionViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new Subscription({
      state: "create",
      status: 1
    });
    return item;
  }

  onDelete(data) {
    let item = new Subscription(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Subscription(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.subscriptions.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Subscription(data));
  }

  onSearchItem(id) {
    return this.api.subscriptions.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.subscriptions.create(data.toSend())
    else
      return this.api.subscriptions.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.subscriptions.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.subscriptions.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.subscriptions.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    if (this.view.formData.state === 'update') {
      let data = this.view.formData

      this.view.formData.reportDate = [data.subscriptionDate, data.subscriptionEndDate];
      this.view.discounts = [data.discount];
      this.view.subscriptionPlans = [data.subscriptionPlan];
      this.view.users = [data.userProfile];
    }
    setTimeout(() => {
      this.showModalLoading(this.view.conf, false);
    }, 400);
  }

  // #endregion

  // #region QUERIES
  /**
   * Metodo para buscar usuarios
   * @param {String} data Query string para parametros de búsqueda
   */
  findUsers(data) {
    this.api.users.find(data)
      .then((response) => this.view.onSearchUsersResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar plan de inscripción
   * @param {String} data Query string para parametros de búsqueda
   */
  findSubscriptionPlans(data) {
    this.api.subscriptionPlans.find(data)
      .then((response) => this.view.onSearchSubscriptionPlansResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar descuento
   * @param {String} data Query string para parametros de búsqueda
   */
  findDiscounts(data) {
    this.api.discounts.find(data)
      .then((response) => this.view.onSearchDiscountsResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  //#region MAPPING DATA

  formatMoney(amount) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount);
  }

  // #endregion

  // #region VALIDATIONS

  // #endregion
}
