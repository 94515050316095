import BaseComponent from '../../../core/infraestructure/BaseComponent';
import SessionBoxViewModel from '../../../core/features/shared/viewmodels/SessionBoxViewModel';

export default {
    extends: BaseComponent,
    data() {
        return {
            session: null,
            profileImageUrl: '/images/hd_dp.jpg',
            isLogoutLoading: false,
        };
    },
    created() {
        this.viewModel = new SessionBoxViewModel(this);
        this.viewModel.profile()
        this.viewModel.sessionHideOnMobile()
        this.viewModel.onBindTemplateUI()
    },
    mounted() { },
    methods: {
        onFindProfileResponse(response) {
            if (response.isAuthenticated) {
                this.session = response.profile;
                this.profileImageUrl = (this.session.user.profileImageUrl) ? this.session.user.profileImageUrl : this.profileImageUrl;
                this.$root.$emit('update-session', response.profile);
                this.onNotifyEvent('session-info', response.profile);
            } else {
                this.$nextTick(() => this.$router.push("/"));
            }
        },
        onChangeMenuOptions(command) {
            if (command === "logout") {
                this.viewModel.logout();
            } else {
                this.$router.push(`/${command}`);
            }
        },
        onLogout() {
            this.$confirm("¿Desea cerrar sesión?", "Alerta", {
                confirmButtonText: "Cerrar Sesión",
                cancelButtonText: "Cancelar",
                type: "warning",
                closeOnClickModal: false,
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.isLogoutLoading = true
                    this.viewModel.logout();
                })
                .catch(() => { });

        },
        onLogoutResponse() {
            this.$nextTick(() => {
                this.viewModel.removePermissions();
                this.viewModel.removeSessionToken();
                setTimeout(() => {
                    this.$router.go({ name: "login" });
                }, 600 );
            });
        }
    },
    computed: {}
}