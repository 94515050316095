<script>
import SimulatorStudentDetailComponent from '../features/SimulatorStudentDetailComponent';
import HeaderView from '../../shared/views/HeaderView';
import MainFooterView from '../../shared/views/MainFooterView';
import SimulatorStudentDetailSectionView from './SimulatorStudentDetailSectionView';
import SimulatorStudentDetailResultView from './SimulatorStudentDetailResultView';


export default {
  extends: SimulatorStudentDetailComponent,
  components: {
    'header-view': HeaderView,
    'main-footer-view': MainFooterView,
    'simulator-detail-section-view': SimulatorStudentDetailSectionView,
    'simulator-result-view': SimulatorStudentDetailResultView,
  },
  name: 'SimulatorStudentDetailView',
};

</script>
<template>
  <div>
    <div v-if="simulator">
      <header-view></header-view>
      <div class="container simulator-detail" v-if="!isFinished">
        <el-row
          class="row-bg simulator-detail-header"
          :gutter="20"
          v-if="!isFinished"
        >
          <el-col :xs="16" :sm="18" :md="21" :lg="21" :xl="21">
            <h3>{{ simulator.name }}</h3>
          </el-col>
          <el-col :xs="8" :sm="6" :md="3" :lg="3" :xl="3" align="center" v-loading="isTimerLoading">
            <el-button
              size="medium"
              type="primary"
              class="btn-primary"
              @click="onStartTimer"
              v-if="!timerLabel && !isFinished && simulator.isTimer"
              >Iniciar</el-button
            >
            <h3 class="test-timer" v-if="simulator.isTimer && timerLabel">
              <i class="el-icon-timer"></i> {{ timerLabel }}
            </h3>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-if="!isFinished && simulator.isTimer && !sectionSelected"
        >
          <el-col :span="24" align="center">
            <h4 class="simulator-notification">
              <i class="fa fa-info-circle" aria-hidden="true"></i> Para comenzar
              tu simulador, haz click en iniciar y selecciona la sección que
              desees comenzar a responder. Que tengas éxito.
            </h4>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-if="!isFinished && !simulator.isTimer && !sectionSelected"
        >
          <el-col :span="24" align="center">
            <h4 class="simulator-notification">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              Para comenzar tu simulador, haz click en la secci&oacute;n que
              quieras comenzar a responder.
            </h4>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-if="!sectionSelected && !isFinished"
        >
          <el-col :span="24" style="padding-left: 0px; padding-right:0px">
            <div
              v-for="section in simulator.simulatorSections"
              v-bind:key="section.id"
              @click="onSelectSection(section)"
              class="simulator-detail-section"
            >
              <i class="fa fa-hand-o-right" aria-hidden="true"></i>
              {{ section.name }}
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20" v-if="sectionSelected">
          <el-col :span="24" style="padding-left: 0px; padding-right:0px">
            <simulator-detail-section-view
              :simulator-id="simulator.id"
              :current-section="sectionSelected"
            />
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          v-if="(!isFinished && !sectionSelected && timerLabel) || (!isFinished && !sectionSelected && !simulator.isTimer)"
        >
          <el-col :span="24" align="center">
            <el-button
              size="medium"
              type="primary"
              class="btn-primary"
              @click="onFinishSimulator"
              >Mostrar Resultados</el-button
            >
          </el-col>
        </el-row>
      </div>
      <simulator-result-view
        v-if="isFinished"
        :simulator-id="simulator.id"
      />
    </div>
    <div v-loading.fullscreen.lock="isLoading"></div>
  </div>
</template>
