export default {
  data() {
    return {
      isFileTemp: false,
      file: null,
      fileUrl: null,
      isFileLoading: false,
    };
  },
  created() {},
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onBeforeUploadSectionFile(file) {
      //console.log('on before', file)
      const isPdf = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isPdf) {
        this.$message.error("El Archivo debe estar en formato PDF!");
      }
      if (!isLt2M) {
        this.$message.error("El Archivo excede los 2MB!");
      }
      if (isPdf & isLt2M) {
        this.isFileLoading = true;
        setTimeout(() => {
          this.section.file = file;
          this.section.fileName = file.uid;
          this.$forceUpdate();
          this.isFileLoading = false;
        }, 400);
      }
      return isPdf && isLt2M;
    },
    onSuccessUploadSectionFile() {},
    onChangeUploadSectionFile() {},
    onShowSectionFile(fileUrl) {
      this.isFileLoading = true;
      setTimeout(() => {
        var link = document.getElementById("someLink");
        if (link) {
          link.id = "someLink"; //give it an ID!
          link.href = fileUrl ? fileUrl : null;
          link.target = "_blank";
        } else {
          link = document.createElement("a");
          link.id = "someLink"; //give it an ID!
          link.href = fileUrl ? fileUrl : null;
          link.target = "_blank";
          document.body.appendChild(link);
        }
        document.getElementById("someLink").click();
        this.isFileLoading = false;
      }, 400);
    },
    onRemoveSectionFile() {
      this.$confirm("¿Desea eliminar el archivo seleccionado?", "Alerta", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-primary",
      })
        .then(() => {
          this.isFileLoading = true;
          setTimeout(() => {
            this.section.file = null;
            this.section.fileUrl = null;
            this.$forceUpdate();
            this.isFileLoading = false;
          }, 400);
        })
        .catch(() => {});
    }
  },
};
