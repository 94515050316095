import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import CourseViewModel from '../../../../core/features/website/viewmodels/CourseViewModel';

export default {
    extends: ListComponent,
    data() {
        return {
            item: null,
            viewModel: null,
            fullLoader: true,
            filterOptions: {
                descriptionFilter: '',
                titleFilter: '',
            },
        }
    },
    mounted() { },
    beforeDestroy() { },
    created() {
        this.viewModel = new CourseViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.onSearch();
        },
        onAllFilterChange(data, field) {
            this.fullLoader = true;
            this.onFilterChange(data, field);
        },
        mapCourseImageUrl(item) {
            return (item.coverImageUrl) ? item.coverImageUrl : 'images/blog/img-1.jpg';
        },
        getCoverImage(url) {
            return (url) ? url : '/images/courses/add_img.jpg'
        },
        mapTags(tags) {
            var collection = (tags) ? tags.split(',') : []
            return collection.join(' | ')
        },
        onDoLike(course) {
            this.viewModel.doLike(course.id)
        },
        onDoLikeResponse() {
            this.onSearch();
        },
        getSocialStatusClass(flag) {
            return (flag) ? 'uil uil-heart active' : 'uil uil-heart'
        },
        navigate(course) {
            this.$router.push({
                name: "blog course single",
                params: {
                    id: course.id,
                    title: course.title.replace(/ /g, '-').toLowerCase()
                }
            });
        },
        descriptionStrippedHtml(description) {
            let regex = /(<([^>]+)>)/ig;

            return description.replace(regex, "");
        }
    },
    computed: {}
}