import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SimulatorOfficialDetailViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialDetailViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            answersSelected: [],
            
            isLoading: false,
            activeNames: []
        };
    },
    props: {
        currentQuestion: {
            required: true,
            type: Object
        },
        simulatorId: {
            required: true,
            type: String
        }
    },
    mounted() {
        EventBus.$on('onChangeQuestion', this.onChangeQuestion);
    },
    beforeDestroy() {
        EventBus.$off('onChangeQuestion', this.onChangeQuestion);
    },
    created() {
        this.viewModel = new SimulatorOfficialDetailViewModel(this);
        this.onActive()
    },
    watch: {
        'currentQuestion'(newValue) {
            this.isLoading = true
            this.activeNames = [newValue.value.id]
        }
    },
    methods: {
        onActive() {
            this.isLoading = true
            setTimeout(() => {
                this.activeNames = [this.currentQuestion.value.id]
                this.isLoading = false
            }, 400);
        },
        onChangeAnswer(data) {
            this.answersResult = this.viewModel.mapQuestionToUpdateProgress(this.currentQuestion.value, data)
            this.viewModel.updateProgress({ 
                simulatorQuestionId: this.currentQuestion.value.id,
                simulatorId: this.simulatorId,
                questionAnswers: this.answersResult
            });
        },
        onUpdateProgressResponse() {
            // console.log(response)
        },
        onChangeQuestion(data) {
            this.answersSelected = this.viewModel.mapSelectedAnswers(data);
            setTimeout(() => {
                this.isLoading = false
            }, 600);
        }
    },
};