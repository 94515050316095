<script>
import ListEvaluationsComponent from '../../features/lists/ListEvaluationsComponent';
import ModalEvaluationView from '../modals/ModalEvaluationView';

export default {
  extends: ListEvaluationsComponent,
  name: 'ListEvaluationView',
  components: {
    'modal-evaluation-view': ModalEvaluationView,
  },
};
</script>
<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl evaluations">
      <el-col :xs="24" :sm="13" :md="14" :lg="9" :xl="7" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Evaluación</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!--
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Evaluaciones</el-button
                >
              </el-dropdown-item>
              -->
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.courseFilter" filterable remote clearable placeholder="Curso..."
                :remote-method="onSearchCourse" @change="onAllFilterChange(filterOptions.courseFilter.id, 'CourseId')" @focus="onFocus"
                value-key="id">
                  <el-option v-for="item in courses" :key="item.id" :label="item.title" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.studentFilter" filterable remote clearable placeholder="Estudiantes..."
                :remote-method="onSearchStudent" @change="onAllFilterChange(filterOptions.studentFilter.id, 'StudentId')" @focus="onFocus"
                value-key="id">
                  <el-option v-for="item in students" :key="item.id" :label="item.userProfile.firstName +' '+ item.userProfile.lastName" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="7" :md="7" :lg="5" :xl="8">
        <el-button-group>
          <el-button type="default" size="small" class="btn-cancel btn-rate">
            <el-rate v-model="filterOptions.qualificationFilter" class="size-star" text-color="#909399" score-template="Calificación"
              @change="onAllFilterChange(filterOptions.qualificationFilter, 'Qualification')" @focus="onFocus" clearable></el-rate>
            </el-button>
            <el-button type="default" size="small" class="btn-cancel" @click="onClearQualification">X</el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      
      <el-table-column label="" width="70">
        <template slot-scope="scope">
          <div>
            <el-avatar :src="mapProfileImage(scope.row.student.userProfile)"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ESTUDIANTE" min-width="250">
        <template slot-scope="scope">
          <div>
            {{ scope.row.student.userProfile.firstName }} {{ scope.row.student.userProfile.lastName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CALIFICACIÓN" align="center" width="130">
        <template slot-scope="scope">
          <div><el-rate v-model="scope.row.qualification" disabled></el-rate></div>
        </template>
      </el-table-column>
      <el-table-column label="CURSO" min-width="250">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.course }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="REGISTRO" text-align="center" align="center" width="170">
        <template slot-scope="scope">
          <div>
            <el-tag type="success">{{scope.row.createdAt | formatDate}}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-evaluation-view :formData="item" :conf="modalItem" v-if="modalItem.isVisible"></modal-evaluation-view>
  </div>
</template>