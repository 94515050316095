import BaseModel from "../../../infraestructure/BaseModel"
import CourseSubject from "./CourseSubject";
import moment from "moment";

export default class CourseSection extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            id: this.id,
            title: this.title,
            sort: this.sort,
            description: this.description,
            imageUrl: this.imageUrl,
            isSpecialty: this.isSpecialty,
            specialties: (this.isSpecialty) ? this.specialties : null,
            updateState: this.updateState,
            courseSubjects: this.courseSubjects.map(item => item.toSend()),
        }
    }



    //#region COURSE SUBJECTS
    
    getTotalCourseSubjects() {
        return _.size(this.getCourseSubjects());
    }

    getTotalDuration() {
        return _.sumBy(this.getCourseSubjects(), (item) => item.getTotalDuration());
    }

    getDuration() {
        let totalDuration = this.getTotalDuration();
        return moment.utc(moment.duration(totalDuration, "minutes").asMilliseconds()).format("HH:mm")
    }

    getCourseSubjects() {
        return _.sortBy(this.courseSubjects.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    createCourseSubject() {
        return new CourseSubject({
            sort: this.getNextCourseSubjectIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            courseSubjectActivities: []
        }, 'create')
    }

    editCourseSubject(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new CourseSubject(data, 'update');
    }

    addCourseSubject(data) {
        this.courseSubjects.push(new CourseSubject(data, 'create'));
    }

    updateCourseSubject(selectedIndex, data) {
        let section = this.getCourseSubjects()[selectedIndex]
        var index = _.findIndex(this.courseSubjects, section)
        if (index != -1) {
            this.courseSubjects[index] = data
        } else {
            throw new Error("Tema no encontrado.")
        }
    }

    updateCourseSubjectPosition() {
        this.courseSubjects.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
            item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        });
    }

    removeCourseSubject(selectedIndex) {
        let section = this.getCourseSubjects()[selectedIndex]
        var index = _.findIndex(this.courseSubjects, section)
        if (index != -1) {
            if (section.id) {
                this.courseSubjects[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.courseSubjects.splice(index, 1);
            }
        } else {
            throw new Error("Tema no encontrado.")
        }
    }

    getNextCourseSubjectIndex() {
        return _.size(this.getCourseSubjects()) + 1;
    }

    getSpecialtyName() {
        return (this.isSpecialty) ? this.mapSpecialities(this.specialties) : "";
    }

    //#endregion

    //#region Maps
    mapSpecialities(specialties) {
        let nameSpecialties = '';

        _.map(specialties, (item, index) => {
            if(index == 0) nameSpecialties += item.name;
            if(index > 0) nameSpecialties += ", " + item.name;
        });
        return nameSpecialties;
    }
    //#endregion
}
