import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
// import SysModule from '../../system/models/SysModule'
import Permission from '../../system/models/Permission'
import Role from '../models/Role'
import SessionHelper from '../../../shared/helpers/SessionHelper'

export default class RoleViewModel extends BaseViewModel {
  constructor(view, moduleName) {
    super(view)
  }

  // INICIALIZADORES

  //CRUD

  onCreate() {
    let item = new Role({ state: "create", permissions: [] });
    return item;
  }

  onDelete(data) {
    let item = new Role(data);
    item.state = "delete";
    
    return item;
  }

  onEdit(data) {
    let item = new Role(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.roles.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Role(data));
  }

  onSearchItem(id) {
    return this.api.roles.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.roles.create(data)
    else
      return this.api.roles.update(data.id, data);
  }

  onDestroy(id) {
    this.api.roles.delete(id)
      .then(response => this.view.onSuccess(response.data))
      .catch(this.view.onError)
  }

  onCancel() {
    this.view.item = null;
  }

  import(file) {
    this.api.productArticles.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  export() {
    this.api.productArticles.export()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }


  //#region BINDING DATA 
  
  bindFormView(formData) {
    this.api.modules.find()
      .then((response) => {
        this.view.modules = response.data 
        return this.api.permissions.find('skip=0&limit=10000')
      })
      .then((response) => {
        this.view.permissions = response.data
        this.view.modulesWithPermissions = this.mapModulesParentCategory(this.view.modules)
        if(formData.state === 'update') {
          this.view.claims = this.mapClaims(formData.claims)
        }
        setTimeout(() => {
          this.showModalLoading(this.view.conf, false);
        }, 400);
      })
      .catch(this.view.onError)
  }

  //#endregion

  //#region QUERIES

  //#endregion

  //#region MAPPING DATA
  
  mapModulesParentCategory(collection) {
    var filteredCategories = _.filter(collection, (item) => {
      if(_.includes(item.code, '-Module')) return item;
    });
    return this.mapSubmodules(filteredCategories, collection)
  }

  mapSubmodules(parentCollection, collection) {
    return _.map(parentCollection, (parentModule) => {
      parentModule.subModules = this.filterSubmodules(parentModule, collection)
      return parentModule
    });
  }

  filterSubmodules(parentModule, collection) {
    return _.filter(collection, (module) => {
      var parent = parentModule.code.replace("-Module", "")
      if(_.startsWith(module.code, parent)) {
        module.permissions = this.mapPermissionsInModule(module)
        return module
      }
    });

  }

  mapPermissionsInModule(module) {
    return _.filter(this.view.permissions, { module: module.code })
  }

  mapClaims() {
    return _.map(this.view.formData.claims, (item) => {
      return item.value
    });
  }

  //#endregion



  init() {
    var modules = null
    /* SysModule.find()
      .then((response) => {
        modules = response.data
        return Permission.find(`skip=0&limit=10000`)
      })
      .then((response) => this.view.onActive(this.mapModulesWithPermissions(modules, response.data)))
      .catch(this.view.onError) */
  }

  mapModulesWithPermissions(modules, permissions) {
    return modules.map((sysModule) => {
      // eslint-disable-next-line no-return-assign
      let state = this.view.formData.state;

      sysModule.permissionSelected = (this.view.formData.state == 'update') ? this.updatePermissionSelected(sysModule.code) : [];

      sysModule.permissionSelected = _.map(sysModule.permissionSelected, item => item.description);

      sysModule.permissions = permissions.filter((permission) => permission.module === sysModule.code)
      sysModule.checkAll = (sysModule.permissionSelected.length == sysModule.permissions.length) ? true : false;
      sysModule.isIndeterminate = (sysModule.checkAll) ? false : true;
      return sysModule
    })
  }

  filterModulesBySession(modules) {
    const isSysAdmin = _.includes(SessionHelper.getSession().roles, 'SYSADMIN')
    return _.filter(modules, (item) => {
      if (isSysAdmin) return item
      if (item.name !== 'MODULES') return item
    })
  }

  /**
   * Metodo para seleccionar todos los permisos del modulo correspondiente.
   * @param {*} permissions 
   */
  onMapAllPermissions(permissions) {
    return _.map(permissions, (permission) => {
      return permission.name
    });
  }

  /**
   * Metodo para obtener los permisos asignados a un rol.
   * @param {*} sysModules 
   */
  onMapAllPermissionsToSend(sysModules) {
    const currentPermission = _.map(sysModules, (modules) => {
      if (modules.checkAll) return this.mapPermissionStructure(modules.permissions)
      return this.mapPermissionStructure(
        _.flattenDeep(_.map(modules.permissionSelected, (permissionSelected) => {
          return _.filter(modules.permissions, (permission) => {
            return permission.name.toLowerCase().indexOf(permissionSelected.toLowerCase()) > -1
          })
        }))
      );
    })

    const allPermissions = this.onMapConcatPermissions(currentPermission)

    return allPermissions
  }
  /**
   * Metodo para concatener todos los permisos en un solo array
   * @param {*} currentPermission 
   */
  onMapConcatPermissions(currentPermission) {
    for (let i = 0; i < currentPermission.length; i++) {
      if (_.isUndefined(this.allConcaPermissions)) {
        this.allConcaPermissions = currentPermission[i];
      } else {
        this.allConcaPermissions = this.allConcaPermissions.concat(currentPermission[i]);
      }

    }
    return this.allConcaPermissions
  }

  updatePermissionSelected(sysModule) {
    return _.filter(this.view.formData.claims, (permissions) => {
      return permissions.module.toLowerCase().indexOf(sysModule.toLowerCase()) > -1
    })
  }

  /**
   * Metodo para regresar los permisos con una nueva estructura
   */
  mapPermissionStructure(permissions) {
    return _.map(permissions, (permission) => {
      let currentPermission = {
        value: permission.code,
        module: permission.module,
        description: permission.name,
      }
      return currentPermission
    })
  }

}
