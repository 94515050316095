import _ from 'lodash'
import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent';
import SimulatorPublicViewModel from '../../../core/features/simulator/viewmodels/SimulatorPublicViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'SimulatorsContent-Simulators',
            types: [],
            reportDate: '',
            viewModel: null,
            permissions: null,
            modules: [],
            timerTypes: [
                {
                    id: 1,
                    name: 'General'
                },
                {
                    id: 2,
                    name: 'Sección'
                },
                {
                    id: 3,
                    name: 'Por Pregunta'
                }
            ],
            isTestTimeType: false,
            difficultyLevels: [],
            numberSections: [],
            simulatorQuestionCategories: [],
            itemSection: null,
            sectionSelectedIndex: null,
            questionSelectedIndex: null,
            selectedCategory: [],
            availebleCategories: [],
            modalItemSection: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                difficultyLevel: [
                    { required: true, message: 'Nivel requerido.', trigger: 'change' }
                ],
                duration: [
                    {
                        validator: this.onDurationValidator,
                    }
                ]
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorPublicViewModel(this),
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedSimulatorSection', this.onSaveSimulatorSection);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSimulatorSection', this.onSaveSimulatorSection);
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onAddSection() {
            if(this.viewModel.validateFormSection(this.formData)) {
                let item = this.viewModel.createSection();
                this.formData.simulatorSections.push(item);
                this.$forceUpdate();
            }
        },
        onFindSimulatorOfficialExamType(data) {
            if (data) {
                this.viewModel.findSimulatorOfficialExamTypes(`Name=${data}`)
            } else {
                this.types = []
            }
        },
        onFindSimulatorOfficialExamTypesResponse(response) {
            this.types = response;
        },
        onFocus() {
            this.$forceUpdate()
        },
        isTimerChange(isTimer) {
            if(!isTimer) {
                this.formData.timerType = null;
                this.formData.duration = null;
                this.isTestTimeType = false;
            }
        },
        isTimerTypeChange(type) {
            this.isTestTimeType = false;
            if(type) {
                this.isTestTimeType = (type.id === 3);
            }
            this.formData.duration = null;
        },
        onChangeIsSpecialty(section) {
            this.$set(section, 'career', '');
            this.$forceUpdate();
        },
        isSpecialtiesRequired(rule, value, callback) {
            if(_.isEmpty(value)) {
                callback(new Error('Sección requerido.'));
            } else {
                callback();
            }
        },
        onSpecialtyChange(section) {
            return !section.isSpecialty || _.isUndefined(section.isSpecialty)
        },
        onChangeDifficultyLevel(difficultyLevel) {
            if(difficultyLevel) {
                this.formData.simulatorSections = [];
                this.viewModel.findQuestionCategoriesByUniversities(difficultyLevel.id);
            } else {
                this.formData.simulatorSections = [];
                this.simulatorQuestionCategories = [];
				this.availebleCategories = [];
                this.$forceUpdate();
            }
        },
        onChangeItemOptions(option) {
            this.sectionSelectedIndex = option.index;
            if(option.command === 'delete') this.onDeleteSection(option.index);
        },
        onDeleteSection(sectionIndex) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    setTimeout(() => {
                        this.$set(this, 'selectedCategory', this.selectedCategory.filter((item) => item.index !== sectionIndex));
                        this.$set(this, 'availebleCategories', this.simulatorQuestionCategories);
                        this.viewModel.deletionSection(sectionIndex, this.formData.simulatorSections);
                        this.viewModel.updateCategoryIndex(this.selectedCategory, this.formData.simulatorSections);
                        this.$forceUpdate()
                    }, 200);
                })
                .catch(() => {

                });
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    this.conf.isLoading = true;
                    this.viewModel.generateTemporalSimulator(this.formData);
                }
            });
        },
        onGenerateTemporalSimulatorResponse(response) {
            setTimeout(() => {
                this.$router.push({ path: `/estudiante/simuladores/${response.simulatorId}/prueba` })
                this.conf.isLoading = false;
            }, 500);
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanReadSimulatorOfficial(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteSimulatorOfficial(permissions) {
            return this.canDelete(permissions);
        },
        onDurationValidator(rule, value, callback) {
            if(this.formData.isTimer) {
                if (value) {
                    if (value >= 1) {
                        callback();
                    } else {
                        callback(new Error('Valor no válido.'));
                    }
                } else {
                    callback(new Error('Duración requerida.'));
                }
            } else {
                callback();
            }
        },
        onQuestionNumberValidator(rule, value, callback) {
            this.conf.isLoading = true;
            this.viewModel.questionNumberValidator(rule, value, callback);
        },
        availableSimulatorQuestionCategories(categories) {
            if(_.isEmpty(this.selectedCategory)) {
                this.$set(this, 'availebleCategories', categories);
            } else {
                let selectCategories = _.map(this.selectedCategory, (category) => {
                    return _.omit(category, ['index']);
                });
                this.$set(this, 'availebleCategories', _.differenceWith(this.simulatorQuestionCategories, selectCategories, _.isEqual));
            }
        },
        onClearSimulatorQuestionCategory(index) {
            this.selectedCategory = this.selectedCategory.filter((item) => item.index !== index);
        },
        onChangeSimulatorQuestionCategory(category, index) {
            this.selectedCategory = this.selectedCategory.filter((item) => item.index !== index);

            this.selectedCategory.push({
                id: category.id,
                name: category.name,
                index: index
            });
            //this.viewModel.changeSimulatorQuestionCategory(this.selectedCategory, category);
        },
        onClickSimulatorQuestionCategory() {
            this.availableSimulatorQuestionCategories(this.availebleCategories)
        }
    }
}