import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SimulatorOfficialViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'SimulatorsContent-Simulators',
            types: [],
            careers: [],
            reportDate: '',
            viewModel: null,
            permissions: null,
            modules: [],
            timerTypes: [
                {
                    id: 1,
                    name: 'General'
                },
                {
                    id: 3,
                    name: 'Sección'
                },
                {
                    id: 4,
                    name: 'Por Pregunta'
                }
            ],
            isTestTimeType: false,
            difficultyLevels: [],
            universities: [],
            knowledgeAreas: [],
            numberSections: [],
            itemSection: null,
            sectionSelectedIndex: null,
            questionSelectedIndex: null,
            coverImageFile: null,
            coverImageUrl: '/images/courses/add_img.jpg',
            isCoverImageTemp: false,
            modalItemSection: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                questionQuantity: [
                    { required: true, message: 'No. de Preguntas requerido.', trigger: 'change' }
                ],
                topScore: [
                    { required: true, message: 'Puntaje Max. requerido.', trigger: 'change' }
                ],
                minimumScore: [
                    { required: true, message: 'Puntaje Min. requerido.', trigger: 'change' }
                ],
                difficultyLevel: [
                    { required: true, message: 'Nivel requerido.', trigger: 'change' }
                ],
                university: [
                    { required: true, message: 'Universidad requerido.', trigger: 'change' }
                ],
                career: [
                    { required: false, message: 'Carrera requerido.', trigger: 'change' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorOfficialViewModel(this)
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedSimulatorSection', this.onSaveSimulatorSection);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSimulatorSection', this.onSaveSimulatorSection);
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onFindSimulatorOfficialExamType(data) {
            if (data) {
                this.viewModel.findSimulatorOfficialExamTypes(`Name=${data}`)
            } else {
                this.types = []
            }
        },
        onFindSimulatorOfficialExamTypesResponse(response) {
            this.types = response;
        },
        onSearchCourseCategory(data) {
            if (data) {
                this.viewModel.findCourseCategories(`Name=${data}`)
            } else {
                this.parents = []
            }
        },
        onSearchCourseCategoriesResponse(response) {
            this.parents = response;
          },
        onFocus() {
            this.$forceUpdate()
        },
        isTimerChange(isTimer) {
            if(!isTimer) {
                this.formData.timerType = null;
                this.formData.duration = null;
                this.isTestTimeType = false;
            }
        },
        isTimerTypeChange(type) {
            this.isTestTimeType = false;
            if(type) {
                this.isTestTimeType = (type.id === 2);
            }
            this.formData.duration = null;
        },
        onAddSection(){
            if(this.viewModel.validateFormSection(this.formData)) {
                //this.itemSection = this.viewModel.createSectionQuestion(this.formData.simulatorSections);
                /**this.itemSection = {
                    limitQuestion : this.formData.questionQuantity,
                    totalPartialQuestion : this.viewModel.totalPartialQuestions(this.formData.simulatorSections),
                    universityId : this.formData.university.id,
                } */
                this.itemSection = this.formData.createSimulatorOfficialSection();
                this.itemSection.limitQuestion = this.formData.questionQuantity;
                this.itemSection.totalPartialQuestion = this.viewModel.totalPartialQuestions(this.formData.simulatorSections);
                this.itemSection.universityId = this.formData.university.id;
                this.itemSection.careerId = (this.formData.career) ? this.formData.career.id : null;
                this.viewModel.showModal(this.modalItemSection, true);
                this.viewModel.showModalLoading(this.modalItemSection, true);
            }
        },
        onSaveSimulatorSection(item) {
            if(this.sectionSelectedIndex != null) {
                this.formData.updateSimulatorOfficialSection(this.sectionSelectedIndex, item);
            } else {
                this.formData.addSimulatorOfficialSection(item);
            }
            this.sectionSelectedIndex = null;
            this.viewModel.showModal(this.modalItemSection, false);
        },
        onChangeItemOptions(option) {
            this.sectionSelectedIndex = option.index;
            if(option.command === 'edit') this.onUpdateSection(option.index, option.item);
            if(option.command === 'delete') this.onDeleteSection(option.index);
        },
        onDeleteSection(sectionIndex) {
            this.$confirm("¿Desea eliminar la sección seleccionada?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.conf.isLoading = true
                    this.sectionSelectedIndex = null
                    setTimeout(() => {
                        this.formData.removeSimulatorOfficialSection(sectionIndex);
                        this.$forceUpdate()
                        this.formData.updateSimulatorOfficialSectionPosition()
                        this.conf.isLoading = false
                    }, 400);
                })
                .catch(() => { 
                    this.sectionSelectedIndex = null
                });
        },
        onUpdateSection(sectionIndex, section){
            this.sectionSelectedIndex = sectionIndex;
            this.itemSection = this.formData.editSimulatorOfficialSection(section);
            this.itemSection.limitQuestion = this.formData.questionQuantity;
            this.itemSection.totalPartialQuestion = this.viewModel.totalPartialQuestions(this.formData.simulatorSections);
            this.itemSection.universityId = this.formData.university.id;
            this.itemSection.careerId = (this.formData.career) ? this.formData.career.id : null;
            this.viewModel.showModal(this.modalItemSection, true);
            this.viewModel.showModalLoading(this.modalItemSection, true);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    this.conf.isLoading = true;
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanReadSimulatorOfficial(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteSimulatorOfficial(permissions) {
            return this.canDelete(permissions);
        },
        sortSimulatorOfficialSections(collection) {
            let array = _.filter(collection, function(o) { 
                if(o.updateState !== 3) return o; 
            });
            return _.sortBy(array, 'sort');
        },
        onChangeDrag(collection) {
            this.viewModel.changeDrag(collection);
        },
        onSaveResponse(response) {
            setTimeout(() => {
                this.conf.isLoading = false;
                this.onShowSuccess(response.message);
                this.showComponent(false);
                EventBus.$emit('onSubmitedSimulator', null);
            }, 400);
        },
        onAllowDuration() {
            return this.viewModel.allowDuration(this.formData);
        },
        onRemoveCoverImage(data) {
            this.coverImageUrl = '/images/courses/add_img.jpg'
            this.coverImageFile = null;
            this.isCoverImageTemp = false;
            this.formData.coverImageUrl = null;
        },
        onSuccessUploadCoverImage(data) {
            return false;
        },
        async onBeforeUploadCoverImage(file) {
            let dataUrl = await this.viewModel.convertFileToBase64(file)
            this.coverImageUrl = (dataUrl) ? dataUrl : '/images/courses/add_img.jpg'
            this.coverImageFile = file;
            this.isCoverImageTemp = true;
            /* console.log('on before', file)
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('La imagen debe estar en formato JPG!');
            }
            if (!isLt2M) {
            this.$message.error('La imagen excede los 2MB!');
            }
            return isJPG && isLt2M; */
            return true;
        },
        onChangeUniversity(data) {
            this.$set(this.formData, 'career', '');
            this.knowledgeAreas = []
            if(data)
                this.viewModel.findCareers(`Skip=0&Limit=10000&UniversityId=${data.id}`)
        },
        onFindCareersResponse(response) {
            this.careers = response.data;
        },
    }
}