import Quill from 'quill'
import htmlEditButton from "quill-html-edit-button";
import ImageResize from "quill-image-resize-module--fix-imports-error";

Quill.register('modules/htmlEditButton', htmlEditButton)
Quill.register('modules/imageResize', ImageResize);

window.Quill = Quill;


export default {
    toolbarOptions: [
        {
            'header': [1, 2, 3, 4, 5, 6, false]
        },
        'bold',
        'italic',
        'underline',
        'strike',
        'link',
        'image',
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'color': [] },
        { 'background': [] },
        'formula'
    ],
    mathOperators: [
        ["\\sqrt[n]{x}", "\\nthroot"], ["\\frac{x}{y}", "\\frac"],
        ["\\exp{x}", "\\superscript"], ["\\_{x}", "\\subscript"],
        ["\\sum^{s}_{x}{d}", "\\sum"], ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\int^{s}_{x}{d}", "\\int"], ["\\binom{n}{k}", "\\binom"], ["\\cos^{n}", "\\cos"]
    ],
    buildEditor(selector) {
        var editor = new Quill(selector, {
            theme: 'snow',
            bounds: selector,
            modules: {
                formula: true,
                toolbar: this.toolbarOptions,
                htmlEditButton: {
                    debug: true, // logging, default:false
                    msg: "Editar el contenido en Html", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                    okText: "Ok", // Text to display in the OK button, default: Ok,
                    cancelText: "Cancelar", // Text to display in the cancel button, default: Cancel
                    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                    buttonTitle: "Mostrar Html", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                    prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                    editorModules: {}, // The default mod
                },
                imageResize: {
                    // See optional "config" below
                    //displaySize: true
                }
            },
        })
        this.bindMathOperators(editor)
        this.bindEmptyStyles(editor);
        return editor;
    },
    bindMathOperators(editor) {
        var enableMathQuillFormulaAuthoring = mathquill4quill();
        enableMathQuillFormulaAuthoring(editor, {
            operators: this.mathOperators,
        });
    },
    bindEmptyStyles(editor) {
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, function(node, delta){
            var plaintext = node.innerText;
            var Delta = Quill.import('delta');
            return new Delta().insert(plaintext);
          });
    },
}