import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import SimulatorQuestionViewModel from '../../../../core/features/setting/viewmodels/SimulatorQuestionViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Settings-Careers',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      courseCategories: [],
      difficultyLevels: [],
      types: [],
      filterOptions: {
        courseCategoryFilter: null,
        codeFilter: null,
        difficultyLevelFilter: null,
        typeFilter: null,
        nameFilter: null,
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR PREGUNTAS',
        onUploadImport: 'onUploadImportSimulatorQuestion',
        onCancelImport: 'onCancelImportSimulatorQuestion',
      },
    };
  },
  mounted() {
    EventBus.$on('onSubmitedSimulatorQuestion', this.onSearch);
    EventBus.$on('onUploadImportSimulatorQuestion', this.onUploadImport);
    EventBus.$on('onCancelImportSimulatorQuestion', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedSimulatorQuestion', this.onSearch);
    EventBus.$off('onUploadImportSimulatorQuestion', this.onUploadImport);
    EventBus.$off('onCancelImportSimulatorQuestion', this.onCancelImport);
  },
  created() {
    this.viewModel = new SimulatorQuestionViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onSearchSimulatorExamType();
      this.onFindDifficultyLevel();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchSimulatorExamType() {
      this.viewModel.findSimulatorExamTypes()
    },
    onFindSimulatorExamTypesResponse(response) {
      this.types = response;
    },
    onFindDifficultyLevel() {
      this.viewModel.findDifficultyLevel()
    },
    onFindDifficultyLevelsResponse(response) {
      this.difficultyLevels = response.data;
    },
    onSearchCourseCategory(data) {
      if (data) {
        this.viewModel.findSimulatorQuestionCategories(`Name=${data}`)
      } else {
        this.courseCategories = []
      }
    },
    onFindSimulatorQuestionCategoriesResponse(response) {
      this.courseCategories = response;
    },
    onFocus() {
      this.$forceUpdate()
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file) {
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport() {
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}