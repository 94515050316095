<script>
import ModalCourseSectionComponent from '../../features/modals/ModalCourseSectionComponent';
import ModalCourseSubjectView from './ModalCourseSubjectView';
import draggable from 'vuedraggable';

export default {
  extends: ModalCourseSectionComponent,
  name: 'ModalCourseSectionView',
  components: {
    'modal-course-subject-view': ModalCourseSubjectView,
    draggable,
  },
};
</script>

<template>
  <div>
    <el-dialog
      title="Sección"
      :visible.sync="conf.isVisible"
      custom-class="course-section-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información de Sección</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="18" :md="18" :lg="20" :xl="20">
              <el-form-item label="Título" prop="title">
                <el-input
                  v-model="formData.title"
                  maxlength="120"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="4" :xl="4">
              <el-form-item label="Orden" prop="sort">
                <el-input-number
                  v-model="formData.sort"
                  size="small"
                  :disabled="true"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Descripción" prop="description">
                <el-input
                  v-model="formData.description"
                  type="textarea"
                  :row="3"
                  maxlength="255"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" align="center">
              <el-form-item label="Especialidad" prop="career">
                <el-switch
                  v-model="formData.isSpecialty"
                  active-text="SI"
                  inactive-text="NO"
                  @change="onChangeIsSpecialty">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
              <el-form-item label="Especialidad" prop="career">
                <el-select
                  v-model="formData.specialties"
                  multiple
                  filterable
                  clearable
                  placeholder="Seleccionar"
                  value-key="id"
                  size="small"
                  @change="onChangeSpecialties"
                  @remove-tag="onChangeRemoveSpecialties"
                  :disabled="!formData.isSpecialty"
                >
                  <el-option
                    v-for="item in careers"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button-group>
                <el-button
                  type="primary"
                  size="small"
                  class="btn-primary"
                  icon="el-icon-circle-plus"
                  @click="onCreateCourseSubject"
                  :disabled="!onCanCreateCourseSubject(modulePermissions)"
                >
                  Agregar Tema
                </el-button>
              </el-button-group>
            </el-col>
          </el-row>
          <br />
          <el-row>
            <el-col :span="24">
              <div style="overflow-x:auto;">
                <div class="divTable redTable">
                  <div class="divTableHeading">
                    <div class="divTableRow">
                      <div class="divTableHead center" style="width:90px;">
                        ORDEN
                      </div>
                      <div class="divTableHead" style="min-width:200px;">
                        TÍTULO
                      </div>
                      <div
                        class="divTableHead"
                        style="width:120px; text-align:center;"
                      >
                        ACTIVIDADES
                      </div>
                      <!-- <div
                        class="divTableHead"
                        style="width:90px; text-align:center;"
                      >
                        DURACIÓN
                      </div>-->
                      <div class="divTableHead center" style="width:90px;">
                        OPCIONES
                      </div>
                    </div>
                  </div>
                </div>
                <draggable
                  v-model="formData.courseSubjects"
                  @change="onChangeDrag(formData.courseSubjects)"
                  draggable=".item"
                >
                  <div
                    v-for="(element,
                    indexActivity) in formData.getCourseSubjects()"
                    :key="element.id"
                    class="item"
                  >
                    <div class="divTable redTable">
                      <div class="divTableBody">
                        <div class="divTableRow">
                          <div
                            class="divTableCell center"
                            style="width:90px;"
                          >
                            {{ element.sort }}
                          </div>
                          <div class="divTableCell" style="min-width:200px;">
                            {{ element.title }}
                          </div>
                          <div
                            class="divTableCell"
                            style="width:120px; text-align:center;"
                          >
                            {{ element.getTotalActivities() }}
                          </div>
                          <!-- <div
                            class="divTableCell"
                            style="min-width:90px; text-align:center;"
                          >
                            <el-tag type="default" effect="plain">
                              {{ element.getTotalDuration() }} min.
                            </el-tag>
                          </div>-->
                          <div
                            class="divTableCell center"
                            style="width:90px;"
                          >
                            <el-dropdown @command="onChangeItemOptions">
                              <el-button size="small">
                                <i
                                  class="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  :command="{
                                    command: 'edit',
                                    item: element,
                                    index: indexActivity,
                                  }"
                                  :disabled="
                                    !onCanReadCourseSubject(modulePermissions)
                                  "
                                >
                                  <i
                                    class="el-icon-edit"
                                    aria-hidden="true"
                                  ></i>
                                  Mostrar
                                </el-dropdown-item>
                                <el-dropdown-item
                                  :command="{
                                    command: 'delete',
                                    item: element,
                                    index: indexActivity,
                                  }"
                                  :disabled="
                                    !onCanDeleteCourseSubject(modulePermissions)
                                  "
                                >
                                  <i
                                    class="el-icon-delete"
                                    aria-hidden="true"
                                  ></i>
                                  Eliminar
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
    <modal-course-subject-view
      :formData="itemCourseSubject"
      :conf="modalItemCourseSubject"
      v-if="modalItemCourseSubject.isVisible"
    />
  </div>
</template>
