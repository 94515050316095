import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import DiscountViewModel from '../../../../core/features/discount/viewmodels/DiscountViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            isListLoading: null,
            listLoadingMessage: null,
            discounts: [],
            viewModel: null,
            filters: [],
            collectionHistory: [],
            permissions: null,
            formData: {
                rangeDate: '',
                discount: '',
            },
            rules: {
                rangeDate: [
                    { required: true, message: 'Rango de Fecha requerido.' }
                ],
                discount: [
                    { required: true, message: 'Cupon requerido.' }
                ],
            }
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
    },
    created() {
        this.viewModel = new DiscountViewModel(this)
        //this.onActive();
    },
    methods: {
        onSearch() {
            this.$refs['formData'].validate((valid) => {
                if (valid) {
                    this.showLoading(true, 'Actualizando historial...');
                    this.viewModel.findHistoryCollection();
                }
            });
        },
        onFindHistoryCollectionResponse(response) {
            setTimeout(() => {
                this.showLoading(false, '');
                this.collectionHistory = response;
            }, 500);
        },
        mapProfileImage(userProfile) {
            return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
        },
        sizeCareers(careers) {
            return _.size(careers) != 0;
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
            if(!data) {
                this.formData.rangeDate = null;
                this.viewModel.removeFilter('StartDate');
                this.viewModel.removeFilter('EndDate');
            }
            this.onSearch();
        },
        onRangeDate(data, startDate, endDate) {
            if (data) {
                this.onFilterChange(data[0], startDate);
                this.onFilterChange(data[1], endDate);
                this.onSearch();
            } else {
                this.viewModel.removeFilter(startDate);
                this.viewModel.removeFilter(endDate);
                this.onSearch();
            }
        },
        onSearchDiscounts(data) {
            if (data) {
                this.viewModel.findDiscounts(`Name=${data}`)
            } else {
                this.discounts = []
            }
        },
        onSearchDiscountsResponse(response) {
            this.discounts = response;
        },
        onAccept() {
            this.formData = null;
            this.discounts = [];
            EventBus.$emit(this.conf.onAcceptHistory, null);
        },
        onFilterChange(data, field) {
            this.viewModel.updateFilters(data, field, '=');
        },
    }
}