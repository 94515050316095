<script>
import CourseActivitiesComponent from '../../features/boxes/CourseActivitiesComponent';
import ModalCoursePublicActivityView from '../modals/ModalCoursePublicActivityView';

export default {
  extends: CourseActivitiesComponent,
  components: {
    'modal-course-public-activity-view': ModalCoursePublicActivityView,
  },
};
</script>

<template>
  <div>
    <div
      class="lecture-container"
      style="margin-left:40px;"
      v-for="(activity, activityIndex) in orderActivities(
        courseSubject.courseSubjectActivities
      )"
      v-bind:key="activityIndex"
    >
      <el-col :xs="20" :sm="22" :md="22" :lg="22" :xl="22">
        <el-row>
          <el-col>
            {{ activity.title }}
          </el-col>
          <el-col>
            <el-tag effect="plain"> <i class="el-icon-time"></i> {{ activity.getDuration() }} </el-tag>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="4" :sm="2" :md="2" :lg="2" :xl="2" align="center">
        <el-button
          type="btn_addcart btn-primary"
          style="color:white;"
          @click="onShowPublicActivity(activity)"
          v-if="activity.isPublic"
          size="small"
          >Ver</el-button
        >
      </el-col>
      <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2" align="center">
        
      </el-col>
    </div>
    <modal-course-public-activity-view
      :conf="modalItemCoursePublicActivity"
      :formData="activityPreview"
      v-if="modalItemCoursePublicActivity.isVisible"
    />
  </div>
</template>
