import _ from 'lodash';
import QuillEditorHelper from '../../../../core/shared/helpers/QuillEditorHelper';
import TimerHelper from '../../../../core/shared/helpers/TimerHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import Evaluation from '../../evaluations/models/Evaluation';
import CourseDoublyLinkedList from '../models/CourseDoublyLinkedList';

export default class CourseLearnDetailViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static HOUR_IN_MINUTES = 60;
    static ACTIVITY_TYPE_LECTURE = 1;
    static ACTIVITY_TYPE_TEST = 2;

    onCreateEvaluation() {
        var item = new Evaluation({
            state: 'create'
        });
        return item;
    }

    async onBindFormView(courseId) {
        try {
            var detailResponse = await this.api.courses.findDetailsLearn(courseId)
            this.view.courseDetail = detailResponse.data.data;
            this.initDoubleLinkedList(this.sortSubjects(detailResponse.data.data.courseSections));
            // this.view.currentCourseNode = this.view.doublyLinkedList.get(0);
            // this.view.courseSectionActive = this.getActiveSection(response.data.data.courseSections)
            // this.view.courseSubjectActive = this.getActiveSubject(response.data.data.courseSections);
            // this.view.activitySelected = this.getFirstActivity(response.data.data.courseSections);
            // this.view.activityTypeSelected = this.getFirstActivityType(this.view.activitySelected);
            this.view.updateCurrentActivity(this.view.activitySelected, false);
            this.view.progressActivities = this.mapActivityProgress(detailResponse.data.data.courseSubjectActivityUserProgresses);

            var activityTypesResponse = await this.api.courseSubjectActivityTypes.find()
            this.view.courseSubjectActivityTypes = activityTypesResponse.data.data;
            
            var courseProgressResponse = await this.api.courses.findProgressByCourse(courseId)
            this.view.$root.$emit('update-progress', {
                show: true,
                percentage: courseProgressResponse.data.percentage
            });

            this.view.fullLoader = false;

            this.bindActivityNavigation()

        } catch (error) {
            this.view.onError(error)
        }
    }



    getTotalActivities(collection) {
        let totalItems = _.size(collection);
        if (totalItems === 1) return `${totalItems} actividad`;
        return `${totalItems} actividades`;
    }

    getTotalDurationOfActivities(collection) {
        return (_.sumBy(collection, (item) => item.duration) / CourseLearnDetailViewModel.HOUR_IN_MINUTES).toFixed(2);
    }

    onDoProgress(courseSubjectActivityId) {
        this.api.courses.doProgress(courseSubjectActivityId)
            .then(response => this.view.onDoProgressResponse(response.data))
            .catch(this.view.onError)
    }

    updateProgressCourse(courseId) {
        this.api.courses.findProgressByCourse(courseId)
            .then((response) => {
                this.view.$root.$emit('update-progress', {
                    show: true,
                    percentage: response.data.percentage
                });
            })
            .catch(this.view.onError);
    }

    onDoOpenAnswer(courseSubjectActivityId, data) {
        this.api.courses.doOpenAnswer(courseSubjectActivityId, data)
            .then(response => this.view.onDoOpenAnswerResponse(response.data))
            .catch(this.view.onError)
    }

    onDoMultipleAnswer(courseSubjectActivityId, data) {
        this.api.courses.doMultipleAnswer(courseSubjectActivityId, data)
            .then(response => this.view.onDoMultipleAnswerResponse(response.data))
            .catch(this.view.onError)
    }

    onDoFinish(courseId) {
        this.api.courses.doFinish(courseId)
            .then((response) => this.view.onDoFinishResponse(response.data))
            .catch(this.view.onError)
    }

    onDoTestAnswer(courseSubjectActivityId, data) {
        return this.api.courses.doTestAnswer(courseSubjectActivityId, data)
    }

    findTestTryNumber(courseSubjectActivityId) {
        this.api.courses.findTestTryNumber(courseSubjectActivityId)
            .then(response => this.view.onFindTestTryNumberResponse(response.data))
            .catch(this.view.onError)
    }

    onDoTestAnswersRequests(requestsCollection) {
        this.api.courses.all(requestsCollection)
            .then(response => this.view.onDoTestAnswerResponse(response))
            .catch(this.view.onError)
    }

    findTestAnswersDetails(data) {
        this.api.courses.findTestAnswersDetails(data.courseSubjectActivityId, data)
            .then(response => this.view.onFinishTest(response.data))
            .catch(this.view.onError)
    }

    sendCourseEvaluation(data) {
        this.api.evaluations.doCourseEvaluation(data)
            .then((response) => this.view.onSubmitEvaluationResponse(response.data))
            .catch(this.view.onError)
    }

    getActiveSection(collection) {
        if (_.isNil(collection) || _.isEmpty(collection)) return [];
        return _.sortBy(collection, 'sort')[0].id
    }

    getActiveSubject(collection) {
        if (_.isNil(collection) || _.isEmpty(collection)) return [];
        var section = _.sortBy(collection, 'sort')[0];

        if (_.isNil(section.courseSubjects) || _.isEmpty(section.courseSubjects)) return [];
        return _.sortBy(section.courseSubjects, 'sort')[0].id
    }

    getFirstActivity(collection) {
        
        if (_.isNil(collection) || _.isEmpty(collection)) return null;
        var section = _.sortBy(collection, 'sort')[0];

        if (_.isNil(section.courseSubjects) || _.isEmpty(section.courseSubjects)) return null;
        var subject =  _.sortBy(section.courseSubjects, 'sort')[0];
        
        if (_.isNil(subject.courseSubjectActivities) || _.isEmpty(subject.courseSubjectActivities)) return null;
        return _.sortBy(subject.courseSubjectActivities, 'sort')[0];
    }

    getFirstActivityType(activity) {
        return (activity) ? activity.courseSubjectActivityType : 0;
    }

    mapActivityProgress(collection) {
        if (_.isNil(collection)) return [];
        return _.map(collection, (item) => {
            return item.courseSubjectActivity.id;
        });
    }

    /* verifyAnswers(activity) {
        var successAnswers = _.filter(activity.courseSubjectActivityAnswers, { success: true })
        var successAnswersMapped = _.map(successAnswers, (answer) => {
            return answer.id;
        })
        return _.isEqual(successAnswersMapped, this.view.activityAnswers);
    }*/

    verifyAnswers(answersSelected, answersConfiguration) {
        var successAnswers = _.filter(answersConfiguration, { success: true })
        var successAnswersMapped = _.map(successAnswers, (answer) => {
            return answer.id;
        })
        return _.isEqual(successAnswersMapped, answersSelected);
    }

    courseHasSubjects(collection) {
        return !_.isNil(collection) && !_.isEmpty(collection);
    }

    initDoubleLinkedList(subjects) {
        this.view.doublyLinkedList = new CourseDoublyLinkedList();
        _.forEach(subjects, (subject) => {
            _.forEach(subject.courseSubjectActivities, (activity) => {
                this.view.doublyLinkedList.push(activity);
            })
        })
    }

    nextCourseNode(currentCourseNode) {
        return currentCourseNode.next;
    }

    prevCourseNode(currentCourseNode) {
        return currentCourseNode.prev;
    }

    getNodeByActivityId(activityId) {
        return this.view.doublyLinkedList.findByActivityId(activityId);
    }

    bindEditor(selector) {
        this.view.openAnswerEditor = QuillEditorHelper.buildEditor(selector);
    }

    bindActivityNavigation() {
        var tid = setInterval(function () {
            if (document.readyState !== 'complete') return;
            clearInterval(tid);


            var querySelector = document.querySelector.bind(document);

            var nav = document.querySelector('.vertical_nav');
            var wrapper = document.querySelector('.wrapper');

            var menu = document.getElementById("js-menu");
            var subnavs = menu.querySelectorAll('.menu--item__has_sub_menu');

            // Toggle menu click
            querySelector('.toggle_menu').onclick = function () {

                nav.classList.toggle('vertical_nav__opened');

                wrapper.classList.toggle('toggle-content');

            };


            // Minify menu on menu_minifier click
            querySelector('.collapse_menu').onclick = function () {

                nav.classList.toggle('vertical_nav__minify');

                wrapper.classList.toggle('wrapper__minify');

                for (var j = 0; j < subnavs.length; j++) {
                    subnavs[j].classList.remove('menu--subitens__opened');
                }

            };


            // Open Sub Menu

            for (var i = 0; i < subnavs.length; i++) {

                if (subnavs[i].classList.contains('menu--item__has_sub_menu')) {

                    subnavs[i].querySelector('.menu--link').addEventListener('click', function (e) {

                        for (var j = 0; j < subnavs.length; j++) {

                            if (e.target.offsetParent != subnavs[j])
                                subnavs[j].classList.remove('menu--subitens__opened');

                        }

                        e.target.offsetParent.classList.toggle('menu--subitens__opened');

                    }, false);

                }
            }


        }, 400);
    }

    hideContentMenu() {
        var querySelector = document.querySelector.bind(document);
        querySelector('.vertical_nav').classList.remove('vertical_nav__opened')
    }

    mapMultipleChoiceAnswer(text, answers) {
        var header = `<p>${text}</p>`
        var list = `<ul>`
        _.forEach(answers, (answer) => {
            if (answer.success) {
                list += `<li>${answer.title || answer.description}</li>`
            }
        })
        list += `</ul>`
        return header + list;
    }

    sortSubjects(collection) {
        var result = []
        var orderedSections = _.sortBy(collection, 'sort');
        _.forEach(orderedSections, (section) => {
            section.courseSubjects = _.sortBy(section.courseSubjects, 'sort');
            _.forEach(section.courseSubjects, (subject) => {
                subject.courseSubjectActivities = _.sortBy(subject.courseSubjectActivities, 'sort');
                result.push(subject);
            })
        })
        return result;
    }

    getSubjectIdByActivity(activity) {
        var resultSubject = null
        _.forEach(this.view.courseDetail.courseSections, (section) => {
            _.forEach(section.courseSubjects, (subject) => {
                if(_.some(subject.courseSubjectActivities, { id: activity.id })) {
                    resultSubject = subject
                }
            })
        })

        return (resultSubject) ? resultSubject.id : null;
    }

    getTestSectionDefault(testSections) {
        this.initTestSectionsLinkedList(testSections);
        return this.view.testDoublyLinkedList.get(0);
    }

    initTestSectionsLinkedList(testSections) {
        this.view.testDoublyLinkedList = new CourseDoublyLinkedList();
        _.forEach(testSections, (testSection) => {
            this.view.testDoublyLinkedList.push(JSON.parse(JSON.stringify(testSection)));
        })

    }

    initTimer(options) {
        return TimerHelper.startCountDown(options)
    }

    scoreQuestion(question, answers) {
        let answersByQuestion = _.filter(answers, { courseSubjectTestQuestionAnswer: { courseSubjectTestQuestion: { id: question.id } } });
        let answersSelected = _.map(answersByQuestion, (answer) => answer.courseSubjectTestQuestionAnswer.id)
        return this.verifyAnswers(answersSelected, question.courseSubjectTestQuestionAnswers);
    }

    verifyAnswerResponse(answerId, answers) {
        let answersSelected = _.map(answers, (answer) => answer.courseSubjectTestQuestionAnswer.id)
        return _.includes(answersSelected, answerId)
    }

    scoreTest(answers, sections) {
        if (_.isEmpty(answers)) return 0;
        let questionsGroup = this.getAnswersGrouped(answers);
        let totalQuestions = _.size(questionsGroup);
        let totalSuccess = this.getTotalSuccess(answers, sections);
        return ((totalSuccess / totalQuestions) * 100).toFixed(0);
    }

    getTotalSuccess(answers, sections) {
        let questionsGroup = this.getAnswersGrouped(answers);
        let filteredQuestions = _.filter(questionsGroup, (group, groupId) => {
            if (this.compareQuantityAnswers(group, sections, groupId)) return false;
            return this.verifyAnswersAllSuccessByGroup(group);
        });
        return _.size(filteredQuestions);
    }

    getAnswersGrouped(answers) {
        return _.groupBy(answers, 'courseSubjectTestQuestionAnswer.courseSubjectTestQuestion.id')
    }

    compareQuantityAnswers(group, sections, groupId) {
        let answersConfigured = this.getAnswerConfiguredByQuestion(sections, groupId);
        let mappedAnswers = _.flatMap(group, (item) => item.courseSubjectTestQuestionAnswer);
        return _.size(answersConfigured) != _.size(mappedAnswers)
    }

    getAnswerConfiguredByQuestion(sections, questionId) {
        let mappedQuestions = _.flatMap(sections, (item) => item.courseSubjectTestQuestions);
        let question = _.find(mappedQuestions, { id: questionId })
        return (question) ? _.filter(question.courseSubjectTestQuestionAnswers, { success: true }) : [];
    }

    verifyAnswersAllSuccessByGroup(group) {
        return _.every(group, { courseSubjectTestQuestionAnswer: { success: true } })
    }

    isScoreSuccess(score) {
        return score >= 60
    }

    //#region DISPLAY INFORMATION ACTIVITIES

    getActivityTypeIcon(activity) {
        let iconClass = "";
        switch (activity.courseSubjectActivityType) {
            case CourseLearnDetailViewModel.ACTIVITY_TYPE_LECTURE:
                iconClass = 'uil uil-file icon_142';
                break;
            case CourseLearnDetailViewModel.ACTIVITY_TYPE_TEST:
                iconClass = 'uil uil-file-alt icon_142';
                break;
            default:
                break;
        }
        return iconClass;
    }

    isValidActivityType(collection, activityType, keyId) {
        let item = _.find(collection, { id: activityType })
        return (!_.isNil(item) && item.id === keyId)
    }

    //#endregion

    //#region DERECHOS DE AUTOR

    setCopyRights() {
        if (document.addEventListener) {

            document.addEventListener(
                "contextmenu",
                function (e) {
                    e.preventDefault();
                },
                false
            );
        } else {
            document.attachEvent("oncontextmenu", function () {
                window.event.returnValue = false;
            });
        }

        document.addEventListener("copy", function (e) {
            e.clipboardData.setData("text/plain", "Favor de no copiar el contenido");
            e.clipboardData.setData(
                "text/html",
                "<b>Favor de no copiar el texto</b>"
            );
            e.preventDefault();
        });

        document.addEventListener("cut", function (e) {
            e.clipboardData.setData("text/plain", "Favor de no cortar el contenido");
            e.clipboardData.setData(
                "text/html",
                "<b>Favor de no cortar el texto</b>"
            );
            e.preventDefault();
        });
    }

    //#endregion
}