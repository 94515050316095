import moment from "moment";
import BaseModel from "../../../infraestructure/BaseModel"
import CourseSubjectActivityAnswer from "./CourseSubjectActivityAnswer";
import SystemHelper from "../../../shared/helpers/SystemHelper";
import CourseSubjectTestActivity from "./CourseSubjectTestActivity";
export default class CourseSubjectActivity extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            sort: this.sort,
            hasFile: this.hasFile,
            hasVideo: this.hasVideo,
            isPublic: this.isPublic,
            isDownload: this.isDownload,
            courseSubjectActivityType: this.courseSubjectActivityType, // (this.courseSubjectActivityType.id) ? this.courseSubjectActivityType.id : this.courseSubjectActivityType,
            title: this.title,
            description: this.description,
            videoUrl: this.videoUrl,
            fileUrl: this.fileUrl,
            hasOpenResponse: this.hasOpenResponse,
            hasMultipleResponse: this.hasMultipleResponse,
            duration: this.duration || 0,
            file: this.file,
            fileName: this.fileName,
            timerType: (this.timerType) ? this.timerType.id : 0,
            isRequired: this.isRequired,
            isTimer: this.isTimer,
            updateState: this.updateState,
            courseSubjectActivityAnswers: (this.courseSubjectActivityAnswers) ? this.courseSubjectActivityAnswers.map((item) => item.toSend()) : [],
            courseSubjectTestActivities: (this.courseSubjectTestActivities) ? this.courseSubjectTestActivities.map((item) => item.toSend()) : []
        }
    }

    bindFile(activityFile) {
        this.file = (activityFile) ? activityFile : this.file;
        if (!this.id && this.file) this.fileUrl = SystemHelper.getGuidV4();
    }

    getTotalDuration() {
        return this.duration
    }

    getDuration() {
        return moment.utc(moment.duration(this.duration, "minutes").asMilliseconds()).format("HH:mm")
    }
    //#region ANSWERS

    setDefaultAnwers(numberOfDetail) {
        if (_.isNil(numberOfDetail) || !_.isNumber(numberOfDetail)) throw new Error("El valor debe ser numerico.")
        for (let index = 0; index < numberOfDetail; index++) {
            this.addCourseSubjectActivityAnswer(this.createCourseSubjectActivityAnswer())
        }
    }

    removeAllAnswers() {
        this.courseSubjectActivityAnswers.forEach((item) => {
            item.updateState = this.UPDATE_STATUS.REMOVED;
        })
    }

    getCourseSubjectActivityAnswers() {
        return _.sortBy(this.courseSubjectActivityAnswers.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    emptyAnswers() {
        return _.isEmpty(this.getCourseSubjectActivityAnswers())
    }

    createCourseSubjectActivityAnswer() {
        return new CourseSubjectActivityAnswer({
            title: '',
            description: '',
            success: false,
            sort: this.getNextCourseSubjectActivityAnswerIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            courseSubjects: []
        }, 'create')
    }

    editCourseSubjectActivityAnswer(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new CourseSubjectActivityAnswer(data, 'update');
    }

    addCourseSubjectActivityAnswer(data) {
        this.courseSubjectActivityAnswers.push(new CourseSubjectActivityAnswer(data, 'create'));
    }

    updateCourseSubjectActivityAnswer(selectedIndex, data) {
        let section = this.getCourseSubjectActivityAnswers()[selectedIndex]
        var index = _.findIndex(this.courseSubjectActivityAnswers, section)
        if (index != -1) {
            this.courseSubjectActivityAnswers[index] = data
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    updateCourseSubjectActivityAnswerPosition() {
        this.courseSubjectActivityAnswers.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
            item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        });
    }

    removeCourseSubjectActivityAnswer(selectedIndex) {
        let section = this.getCourseSubjectActivityAnswers()[selectedIndex]
        var index = _.findIndex(this.courseSubjectActivityAnswers, section)
        if (index != -1) {
            if (section.id) {
                this.courseSubjectActivityAnswers[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.courseSubjectActivityAnswers.splice(index, 1);
            }
        } else {
            throw new Error("Respuesta no encontrada.")
        }
    }

    getNextCourseSubjectActivityAnswerIndex() {
        return _.size(this.getCourseSubjectActivityAnswers()) + 1;
    }

    //#endregion

    //#region TEST ACTIVITIES

    getTestActivities() {
        return _.sortBy(this.courseSubjectTestActivities.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    getTestActivity(selectedIndex) {
        return this.getTestActivities()[selectedIndex]
    }

    createTestActivity() {
        this.courseSubjectTestActivities.push(new CourseSubjectTestActivity({
            courseSubjectTestQuestions: [],
            isSectionFileTemp: false,
            updateState: this.UPDATE_STATUS.ADDED,
        }, 'create'));
    }

    editTestActivity(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new CourseSubjectTestActivity(data, 'update');
    }

    changeUpdateState(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
    }

    removeTestActivity(selectedIndex) {
        let section = this.getTestActivities()[selectedIndex]
        var index = _.findIndex(this.courseSubjectTestActivities, section)
        if (index != -1) {
            if (section.id) {
                this.courseSubjectTestActivities[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.courseSubjectTestActivities.splice(index, 1);
            }
        } else {
            throw new Error("Sección de examen no encontrada.")
        }
    }

    updateTestActivity(selectedIndex, data) {

    }

    //#endregion

    //#region QUESTIONS

    

}