export default class BaseModel {
    constructor(options, state) {
        Object.assign(this, options)
        if (state) {
            this.state = state
        }
    }

    UPDATE_STATUS = {
        ADDED: 1,
        UPDATED: 2,
        REMOVED: 3
    }
}