import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import SimulatorOfficialQuestionViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialQuestionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            careers: [],
            knowledgeAreas: [],
            types: [],
            universities: [],
            difficultyLevels: [],
            simulatorQuestionCategories: [],
            isUniversity: true,
            isKnowledgeArea: true,
            isQuestionsLoading: false,
            filterOptions: {
                careerFilter: null,
                knowledgeAreaFilter: null,
                nameFilter: null,
                universityFilter: null,
                questionQuantity: null,
                difficultyLevelFilter: null,
                isSpecialtyFilter: null,
            },
            filters: [],
            rules: {
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                description: [
                    { validator: this.validateDescription }
                ],
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorOfficialQuestionViewModel(this);
        this.onActive();
    },
    mounted() {
        this.$root.$on('update-question-editor', this.onUpdateEditor);
    },
    beforeDestroy() {

    },
    methods: {
        onActive() {
            this.viewModel.bindQuestionFormView(this.formData);
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
        },
        onFocus() {
            this.$forceUpdate()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        onFindQuestions() {
            this.conf.isLoading = true;
            this.viewModel.findQuestions(this.filterOptions);
        },
        onFindSimulatorQuestionsResponse(response) {
            this.formData.simulatorsQuestions = this.filterDuplicateQuestions(this.formData.questions, response);
            setTimeout(() => {
                this.conf.isLoading = false;
                if(_.size(response) == 0) {
                    this.$notify({
                        title: 'Advertencia',
                        message: 'No existen mas preguntas disponibles para la universidad, carrera o categoria previamente seleccionadas, por favor de agregar mas preguntas.',
                        type: 'warning'
                    });
                }
            }, 400);
            this.$forceUpdate()
        },
        filterDuplicateQuestions(existenceCollection, newCollection) {
            return _.differenceWith(newCollection, existenceCollection.map(item => item.simulatorQuestion), (a, b) => a.id === b.id);
        },
        onFindUniversity(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onFindUniversitiesResponse(response) {
            this.universities = response;
        },
        onChangeUniversity(data) {
            this.$set(this.formData, 'knowledgeArea', '');
            this.isUniversity = true;
            this.knowledgeAreas = []
            if(data)
                this.viewModel.findKnowledgeAreas(`UniversityId=${data.id}`)
        },
        onFindKnowledgeAreasResponse(response) {
            this.knowledgeAreas = response;
            this.isUniversity = false;
        },
        onChangeKnowledgeArea(data) {
            if (data) {
                this.viewModel.findCareers(`Skip=0&Limit=10000&UniversityId=${data.university.id}&KnowledgeAreaId=${data.id}`)
            } else {
                this.careers = []
                this.isKnowledgeArea = true;
            }
        },
        onFindCareersResponse(response) {
            this.careers = response;
            this.isKnowledgeArea = false;
        },
        onAddRandomQuestions() {
            if(this.viewModel.isQuestionsQuantityValid(this.formData)) {
                this.isQuestionsLoading = true
                this.filterOptions.questionQuantity = this.formData.questionQuantity;
                this.viewModel.getRandomQuestions(this.filterOptions);
            } else {
                this.onShowWarning('Numero de preguntas requerido')
            }
        },
        onFindRandomQuestionsResponse(response) {
            setTimeout(() => {
                this.formData.simulatorsQuestions = response;
            }, 200);
            setTimeout(() => {
                this.isQuestionsLoading = false
                
                if(_.size(response) == 0) {
                    this.$notify({
                        title: 'Advertencia',
                        message: 'No existen mas preguntas disponibles para la universidad, carrera o categoria previamente seleccionadas, por favor de agregar mas preguntas.',
                        type: 'warning'
                    });
                }
            }, 200);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    EventBus.$emit('onSubmitedSimulatorSectionQuestions', this.mapSelectedQuestions(this.multipleSelection));
                }
            });
        },
        mapSelectedQuestions(collection) {
            return collection.map(item => {
                return { id: null, simulatorQuestion: item }
            })
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
    }
}