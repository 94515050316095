import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SimulatorQuestionViewModel from '../../../../core/features/setting/viewmodels/SimulatorQuestionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            active: 0,
            submitLabel: 'Siguiente',
            totalQuestions: 1,
            modulePermissions: 'SimulatorsContent-Simulators',
            knowledgeAreas: [],
            simulatorTypes: [],
            simulatorQuestionCategories: [],
            viewModel: null,
            permissions: null,
            modules: [],
            answerEditors: [],
            editorDescription: null,
            simulatorAnswers: [],
            isAnswerLoading: false,
            isUniversity: true,
            isKnowledgeArea: true,
            difficultyLevels: [
                {
                    id: 1,
                    name: 'FACIL'
                },
                {
                    id: 2,
                    name: 'INTERMEDIO'
                },
                {
                    id: 3,
                    name: 'DIFICIL'
                }
            ],
            universities: [],
            careers: [],
            rules: {
                quantityQuestions: [
                    { required: true, message: 'Numero de preguntas requerido.', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                courseCategory: [
                    { required: true, message: 'Categoria requerida.', trigger: 'change' }
                ],
                type: [
                    { required: true, message: 'Tipo requerida.', trigger: 'change' }
                ],
                difficultyLevel: [
                    { required: true, message: 'Dificultad requerida.', trigger: 'change' }
                ],
                simulatorQuestionCategory: [
                    { required: true, message: 'Categoria requerida.', trigger: 'change' }
                ],
                university: [
                    { required: true, message: 'Universidad requerida.', trigger: 'change' }
                ],
                knowledgeArea: [
                    { validator: this.isKnowledgeAreaRequired }
                ],
                specialties: [
                    { validator: this.isSpecialtiesRequired }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorQuestionViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindQuestionFormView();
        },
        onBindFormViewResponse() {
            setTimeout(() => {
                this.viewModel.bindDescriptionEditor('.description-editor')
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.conf.isLoading = false;
            }, 400);
        },
        onFindSimulatorExamType(data) {
            if (data) {
                this.viewModel.findSimulatorExamTypes(`Name=${data}`)
            } else {
                this.simulatorTypes = []
            }
        },
        onFindSimulatorExamTypesResponse(response) {
            this.simulatorTypes = response;
        },
        onFindUniversity(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onFindUniversitiesResponse(response) {
            this.universities = response;
        },
        onSelectUniversity(data) {
            if (data) {
                this.viewModel.findCareers(`Skip=0&Limit=10000&UniversityId=${data.id}`)
            } else {
                this.careers = []
                this.isUniversity = true;
            }
        },
        onFindSimulatorQuestionCategories(data) {
            if (data) {
                this.viewModel.findSimulatorQuestionCategories(`Name=${data}`)
            } else {
                this.simulatorQuestionCategories = []
            }
        },
        onFindSimulatorQuestionCategoriesResponse(response) {
            this.simulatorQuestionCategories = response;
        },
        onSelectSimulatorQuestionCategory(data) {
            if (data) {
                this.viewModel.findSimulatorQuestionCategories(`Name=${data}`)
            } else {
                this.simulatorQuestionCategories = []
            }
        },
        onFindKnowledgeAreasResponse(response) {
            this.knowledgeAreas = response;
            this.careers = [];
            this.isUniversity = false;
        },
        onSelectKnowledgeArea(data) {
            if (data) {
                this.viewModel.findCareers(`Skip=0&Limit=10000&UniversityId=${data.university.id}&KnowledgeAreaId=${data.id}`)
            } else {
                this.careers = [];
                this.isKnowledgeArea = true;
            }
        },
        onFindCareersResponse(response) {
            this.careers = response;
            this.isKnowledgeArea = false;
        },
        onFocus() {
            this.$forceUpdate()
        },
        onChangeIsSpecialty() {
            this.$set(this.formData, 'specialties', '');
            this.$forceUpdate();
        },
        onChangeAllSpecialities(res) {
            if(res) {
                this.formData.specialties = this.careers;
            } else {
                this.$set(this.formData, 'specialties', '');
                this.$forceUpdate();
            }
        },
        isKnowledgeAreaRequired(rule, value, callback) {
            if (this.formData.isSpecialty) {
                if (_.isEmpty(value)) {
                    callback(new Error('Área de Conocimiento requerida.'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        isSpecialtiesRequired(rule, value, callback) {
            if (this.formData.isSpecialty) {
                if (_.isEmpty(value)) {
                    callback(new Error('Especialidad requerida.'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        onAddAnswer() {
            this.isAnswerLoading = true

            setTimeout(() => {
                this.formData.addAnswer()
            }, 200);

            setTimeout(() => {
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.isAnswerLoading = false
            }, 200);
        },
        onDeleteAnswer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary" })
                .then(() => {
                    this.isAnswerLoading = true
                    setTimeout(() => {
                        this.formData.removeAnswer(index)
                        this.viewModel.removeAnswerEditor(this.answerEditors, index)
                        this.$forceUpdate()
                    }, 200);
                    setTimeout(() => {
                        this.$forceUpdate()
                        this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                        this.isAnswerLoading = false
                    }, 200);
                })
                .catch(() => {

                });
        },
        onUpdateEditor(data) {
            this.$set(this.formData, 'description', data);
        },
        onSubmitForm(formName) {
            if(this.formData.state === 'create') {
                if (this.active == 1) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.conf.isLoading = true;
                            this.onSave(this.formData)
                        }
                    });
                } else {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.conf.isLoading = true;
                            this.submitLabel = 'Guardar';
                            this.active++;
                            this.onBindFormViewResponse()
                        }
                    });
                }
            } else {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.conf.isLoading = true;
                        this.onSave(this.formData)
                    }
                });
            }

        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            if(this.formData.state === 'create') {
                setTimeout(() => {
                    if (this.totalQuestions === this.formData.quantityQuestions) {
                        this.conf.isLoading = false;
                        this.onShowSuccess(response.message);
                        this.showComponent(false);
                        EventBus.$emit('onSubmitedSimulatorQuestion', null);
                    } else {
                        this.conf.isLoading = false;
                        this.onShowSuccess(response.message);
                        this.clearFormQuestion()
                        this.totalQuestions++;
                    }
                }, 400);
            } else {
                setTimeout(() => {
                    this.conf.isLoading = false;
                    this.onShowSuccess(response.message);
                    this.showComponent(false);
                    EventBus.$emit('onSubmitedSimulatorQuestion', null);
                }, 400);
            }

        },
        onSaveError(error){
            setTimeout(() => {
                this.conf.isLoading = false;
                this.onError(error);
            }, 400);
        },
        clearFormQuestion() {
            this.formData.name = '';
            this.formData.description = '';
            this.editorDescription.updateContents([]);
            this.editorDescription.setText('');

            this.answerEditors.forEach((editor, index) => {
                this.formData.simulatorAnswers[index].description = '';
                this.formData.simulatorAnswers[index].success = false;
                editor.component.updateContents([]);
                editor.component.setText('');
            })
        },
        validateStepOne() {
            console.log(this.$refs)
        },
        isAnswerValid(rule, value, callback, source, options){
            this.viewModel.isAnswerValid(rule, value, callback, source, options);
        },
    }
}