import BaseModel from "../../../infraestructure/BaseModel"
import CourseSubjectTestQuestion from "./CourseSubjectTestQuestion";
export default class CourseSubjectTestActivity extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            description: this.description,
            instruction: this.instruction,
            file: this.file,
            fileUrl: this.fileUrl,
            fileName: this.fileName,
            duration: this.duration || 0,
            updateState: this.updateState,
            courseSubjectTestQuestions: this.courseSubjectTestQuestions.map((item) => item.toSend())
        };
    }

    //#region QUESTIONS
    
    updateDescription(description) {
        this.description = description
        this.updateState = (this.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
    }

    updateInstruction(instruction) {
        this.instruction = instruction
        this.updateState = (this.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
    }

    up

    createQuestion() {
        var item = new CourseSubjectTestQuestion({
            sort: this.getNextQuestionIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            courseSubjectTestQuestionAnswers: [],
        }, 'create');

        return item;
    }

    editQuestion(data) {
        var item = new CourseSubjectTestQuestion(data, 'create');
        item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
        return item;
    }

    getQuestions() {
        return _.sortBy(this.courseSubjectTestQuestions.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    getNextQuestionIndex() {
        return _.size(this.getQuestions()) + 1;
    }

    removeQuestion(selectedIndex) {
        let question = this.getQuestions()[selectedIndex]
        var index = _.findIndex(this.courseSubjectTestQuestions, question)
        if (index != -1) {
            if (question.id) {
                this.courseSubjectTestQuestions[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.courseSubjectTestQuestions.splice(index, 1);
            }
        } else {
            throw new Error("Sección de examen no encontrada.")
        }
    }

    updateQuestion(selectedIndex, data) {
        let question = this.getQuestions()[selectedIndex]
        var index = _.findIndex(this.courseSubjectTestQuestions, question)
        if (index != -1) {
            this.courseSubjectTestQuestions[index] = data
        } else {
            throw new Error("Pregunta no encontrada.")
        }
    }

    addQuestion(data) {
        this.courseSubjectTestQuestions.push(data)
    }

    updateQuestionsPosition() {
        this.courseSubjectTestQuestions.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
        });
    }
    
    //#endregion
}