<script>
import CourseSectionsComponent from '../../features/boxes/CourseSectionsComponent';
import CourseSubjectsView from './CourseSubjectsView';

export default {
  extends: CourseSectionsComponent,
  components: {
    'course-subjects-view': CourseSubjectsView,
  },
};
</script>

<template>
  <el-collapse class="ui-accordion ui-widget ui-helper-reset boxe-course-section">
    <el-collapse-item
      :name="sectionIndex"
      v-for="(section, sectionIndex) in orderSections(courseSections)"
      v-bind:key="sectionIndex"
    >
      <template slot="title">
        <el-col :xs="18" :sm="18" :md="18" :lg="20" :xl="20">
          <div class="title-h3">
            <i class="uil uil-meeting-board"></i>
            {{ section.title }}
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="3" :xl="3" class="hidden-xs-only hidden-sm-only" align="right">
          {{ section.getTotalCourseSubjects() }} Temas
        </el-col>
        <!--<el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2" align="center">
          {{ section.getDuration() }}
        </el-col>-->
      </template>
      <course-subjects-view :section="section" />
    </el-collapse-item>
  </el-collapse>
</template>
