import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import CourseViewModel from '../../../../core/features/course/viewmodels/CourseViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'CourseContent-Courses',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      courseCategoryFilter: '',
      courseCategories: [],
      limit: 12,
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CURSOS',
        onUploadImport: 'onUploadImportCourse',
        onCancelImport: 'onCancelImportCourse'
      },
      studentInfoItem: null,
      modalSubscriptionInfo: {
          isVisible: false,
          isLoading: false,
      },
    }
  },
  mounted() {
    EventBus.$on('onSubmitedCourse', this.onSearch);
    EventBus.$on('onUploadImportCourse', this.onUploadImport);
    EventBus.$on('onCancelImportCourse', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedCourse', this.onSearch);
    EventBus.$off('onUploadImportCourse', this.onUploadImport);
    EventBus.$off('onCancelImportCourse', this.onCancelImport);
  },
  created() {
    this.viewModel = new CourseViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.$root.$on('update-session', this.onUpdateSession);
    },
    onSearch() {
      this.showLoading(true, 'Actualizando registros...');
      this.viewModel.findPublished();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchCourseCategory(data) {
      if (data) {
        this.viewModel.findCourseCategories(`Name=${data}`)
    } else {
        this.parents = []
    }
    },
    onSearchCourseCategoriesResponse(response) {
      this.parents = response;
    },
    onFocusCourseCategory() {
      this.$forceUpdate()
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    getCoverImage(url) {
      return (url) ? url : '/images/courses/add_img.jpg'
    },
    mapTags(tags) {
      var collection = (tags) ? tags.split(',') : []
      return collection.join(' | ')
    },
    onDoLike(course) {
      this.viewModel.doLike(course.id)
    },
    onDoLikeResponse() {
      this.onSearch();
    },
    getSocialStatusClass(flag) {
      return (flag) ? 'uil uil-heart active' : 'uil uil-heart'
    },
    onUpdateSession(data){
      if(data.user.hasSubscriptions && !data.user.hasSubscriptionInfo){
          this.studentInfoItem = this.viewModel.onCreateStudentInfo()
          this.viewModel.showModal(this.modalSubscriptionInfo, true)
      }
    },
    isCollectionEmpty(collection) {
      return _.isEmpty(collection)
    }
  },
  computed: {}
}