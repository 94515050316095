import _ from 'lodash';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import Course from '../../course/models/Course';
import CourseSection from "../../course/models/CourseSection";
import CourseSubject from "../../course/models/CourseSubject";
import CourseSubjectActivity from "../../course/models/CourseSubjectActivity";

export default class CourseViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    // INICIALIZADORES
    static HOUR_IN_MINUTES = 60;
    static ACTIVITY_TYPE_LECTURE = 1;
    static ACTIVITY_TYPE_TEST = 2;


    //#region CRUD

    onEdit(data) {
        let item = new Course(data);
        item.state = "update";
        return item;
    }

    onSearchCollection() {
        this.view.limit = 5;
        return this.api.courses.findPublishedInWebSite(this.getSearchQuery())
    }

    mapCollection(collection) {
        return _.map(collection, (data) => new Course(data));
    }

    onSearchItem(name) {
        this.onGetDetails(name)
            .then(response => this.onGetDetailsResponse(response.data))
            .catch(this.view.onError);
    }

    onGetDetails(name) {
        return this.api.courses.findDetailsInWebSite(name)
    }

    onGetDetailsResponse(response) {
        this.view.item = {
            current: this.mapCurrent(response.data.current),
            next: (!_.isNull(response.data.next)) ? new Course(response.data.next) : null,
            preview: (!_.isNull(response.data.preview)) ? new Course(response.data.preview) : null
        }
        setTimeout(() => {
            this.view.fullLoader = false;
        }, 400);
        //Change in the url the id for the title
        //window.history.pushState(null, "", this.view.item.title.replace(/ /g, '-').toLowerCase());
    }

    onCancel(context) {
        context.view.item = null;
    }

    import(file) {
        this.api.courses.import(file)
            .then(response => this.view.onImportResponse(response.data))
            .catch(this.view.onError)
    }

    exportToExcel() {
        this.api.courses.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    // #endregion

    // #region BINDING DATA

    bindFormView() { new Course() }


    // #endregion

    // #region QUERIES


    // #endregion

    //#region MAPPING DATA
    mapCurrent(course) {
        let courseCurrent = new Course(course);
        courseCurrent.courseSections = this.mapCourseSections(course.courseSections);
        return courseCurrent;
    }

    mapCourseSections(collection) {
        return _.map(collection, (item) => {
            item.courseSubjects = this.mapCourseSubjects(item.courseSubjects);
            return new CourseSection(item);
        });
    }

    mapCourseSubjects(collection) {
        return _.map(collection, (item) => {
            item.courseSubjectActivities = this.mapCourseSubjectActivities(
                item.courseSubjectActivities
            );
            return new CourseSubject(item);
        });
    }

    mapCourseSubjectActivities(collection) {
        return _.map(collection, (item) => {
            return new CourseSubjectActivity(item);
        });
    }

    // #endregion

    // #region VALIDATIONS

    // #endregion
}