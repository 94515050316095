<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuInstructorView from '../../../shared/views/MainMenuInstructorView';
import MainFooterView from '../../../shared/views/MainFooterView';
import LiveClassDetailComponent from '../../features/details/LiveClassDetailComponent';
export default {
  extends: LiveClassDetailComponent,
  components: {
    HeaderView,
    MainMenuInstructorView,
    MainFooterView,
  },
};
</script>

<template>
  <div>
    <header-view></header-view>
    <main-menu-instructor-view></main-menu-instructor-view>
    <div class="wrapper" v-loading="isItemLoading" :element-loading-text="itemLoadingMessage">
      <div class="sa4d25">
        <div class="container-fluid">
          <div class="row" v-if="liveClassDetails">
            <div class="col-xl-8 col-lg-8">
              <div class="section3125">
                <div class="live1452">
                  <iframe
                    :src="liveClassDetails.streamUrl"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="user_dt5">
                  <div class="user_dt_left">
                    <div class="live_user_dt">
                      <div class="user_img5">
                        <img :src="mapProfileImageUrl(liveClassDetails.publisherImageUrl)" alt="" />
                      </div>
                      <div class="user_cntnt">
                        <h4>{{liveClassDetails.publisherTitle}}</h4>
                        <span class="subscribe-btn btn-primary btn-author"> Autor </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4">
              <div class="right_side">
                <div class="fcrse_3">
                  <div class="cater_ttle">
                    <h4>Chat En Vivo</h4>
                  </div>
                  <div class="live_chat">
                    <div class="chat1">
                      <p v-for="item in chat" v-bind:key="item.id">
                        <a href="#">{{item.account.username}}</a>{{item.description}}
                      </p>
                    </div>
                  </div>
                  <div class="live_comment">
                    <input
                      class="live_input"
                      type="text"
                      placeholder="Dinos que opinas..."
                      @keyup.enter="sendMessage"
                      v-model="message"
                    />
                    <button class="btn_live" @click="sendMessage">
                      <i class="uil uil-message"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="section3125 mb-15 mt-20">
                <!-- <h4 class="item_title">Live Streams</h4> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <main-footer-view></main-footer-view>
    </div>
  </div>
</template>
