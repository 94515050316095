<script>
import BlogSinglePagesComponent from '../features/lists/BlogSinglePagesComponent';
import FooterView from '../../shared/views/MainFooterView';
export default {
  extends: BlogSinglePagesComponent,
  name: 'BlogSinglePageView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Sub Header Start -->
    <div class="wrapper _bg4586 _new89" v-if="item">		
      <div class="_215b15">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">						
              <div class="title125">						
                <div class="titleleft">					
                  <div class="ttl121">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        <li class="breadcrumb-item"><a href="/noticias">Noticias</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ item.current.title }}</li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div class="titleright">						
                  <a href="/noticias" class="blog_link"><i class="uil uil-angle-double-left"></i>Regresar a Noticias</a>
                </div>
              </div>
              <div class="title126">	
                <h2>{{ item.current.title }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sub Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89" v-if="item">		
      <div class="faq1256">
        <div class="container">
          <div class="row justify-content-md-center">					
            <div class="col-lg-8">
              <div class="bg_blog2">
                <img :src="item.current.imageUrl" alt="">
              </div>
              <div class="vew120 frc123">
                <div class="vdtodt55">
                  <!-- <span class="vdt24">109k views</span> -->
                  <span class="vdt24">{{ item.current.createdAt | formatDate}}</span>
                </div>
              </div>
              <div class="vew120 mt-35 mb-30">			
                <div class="container description" v-html="item.current.description"></div>
              </div>					
            </div>
            <div class="col-md-12">
              <div class="blog_pagination">
                <a @click="navigate(item.preview)" class="bp_left course-pointer" v-if="item.preview">
                  <i class="uil uil-angle-double-left"></i>
                  <div class="kslu15">
                    <div class="prevlink">Anterior</div>
                    <div class="prev_title">{{ item.preview.title }}</div>
                  </div>
                </a>
                <a @click="navigate(item.next)" class="bp_right course-pointer" v-if="item.next">								
                  <div class="kslu16">
                    <div class="prevlink1">Siguiente</div>
                    <div class="prev_title1">{{ item.next.title }}</div>
                  </div>
                  <i class="uil uil-angle-double-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>
