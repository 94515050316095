import BaseModel from "../../../infraestructure/BaseModel"
import SimulatorOfficialQuestion from './SimulatorOfficialQuestion';

export default class SimulatorOfficialSection extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            name: this.name,
            sort: this.sort,
            description: this.description,
            questionQuantity: this.questionQuantity,
            //isSpecialty: this.isSpecialty,
            //specialties: (this.isSpecialty) ? this.specialties : null,
            specialties: this.mapSpecialties(),
            updateState: this.updateState,
            simulatorSectionQuestions: this.mapSimulatorQuestions(this.simulatorSectionQuestions)
        }
    }

    mapSpecialties() {
        return (this.careerId) ? this.careerId : []
        /* return [ {
            id: (this.careerId) ? this.careerId : this.specialties[0].id,
        }]*/
    }

    mapSimulatorQuestions(collection) {
        return _.map(collection, (item) => {
            return {
                id: item.id,
                simulatorQuestion: item.simulatorQuestion,
                sort: item.sort || 0,
                updateState: item.updateState,
            };
        });
    }

    getQuestions() {
        return _.sortBy(this.simulatorSectionQuestions.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    createSimulatorOfficialQuestion() {
        return new SimulatorOfficialQuestion({
            sort: this.getNextSimulatorOfficialQuestionIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            questionAnswers: [{ description: '', success: false }, { description: '', success: false }, { description: '', success: false }, { description: '', success: false }],
        }, 'create')
    }

    editSimulatorOfficialQuestion(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new SimulatorOfficialQuestion(data, 'update');
    }

    addSimulatorOfficialQuestion(collection) {
        collection.forEach ((item) => {
            var data = new SimulatorOfficialQuestion(item, 'create');
            data.sort = this.getNextSimulatorOfficialQuestionIndex();
            data.updateState = this.UPDATE_STATUS.ADDED;
            this.simulatorSectionQuestions.push(data);
        });
    }

    updateSimulatorOfficialQuestion(selectedIndex, data) {
        let question = this.getSimulatorOfficialQuestions()[selectedIndex]
        var index = _.findIndex(this.simulatorSectionQuestions, question)
        if (index != -1) {
            this.simulatorSectionQuestions[index] = data
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    updateSimulatorOfficialQuestionPosition() {
        this.simulatorSectionQuestions.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
            item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        });

    }

    removeQuestion(selectedIndex) {
        let question = this.getQuestions()[selectedIndex]
        var index = _.findIndex(this.simulatorSectionQuestions, question)

        if (index != -1) {
            if (question.id) {
                this.simulatorSectionQuestions[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.simulatorSectionQuestions.splice(index, 1);
            }
        } else {
            throw new Error("Pregunta no encontrada.")
        }
    }

    getNextSimulatorOfficialQuestionIndex() {
        return _.size(this.getQuestions()) + 1;
    }
}