<script>
import CourseActivitiesComponent from '../features/boxes/CourseActivitiesComponent';
import ModalCoursePublicActivityView from '../modals/ModalCoursePublicActivityView';

export default {
  extends: CourseActivitiesComponent,
  components: {
    'modal-course-public-activity-view': ModalCoursePublicActivityView,
  },
};
</script>

<template>
  <div>
    <div
      class="lecture-container"
      v-for="(activity, activityIndex) in orderActivities(
        courseSubject.courseSubjectActivities
      )"
      v-bind:key="activityIndex"
    >
      <el-col :span="20">
        <el-row>
          <el-col>
            {{ activity.title }}
          </el-col>
          <el-col>
            <el-tag effect="plain"> <i class="el-icon-time"></i> {{ activity.getDuration() }} </el-tag>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="2" align="center">
        <el-button
          type="text"
          @click="onShowPublicActivity(activity)"
          class="preview-text"
          v-if="activity.isPublic"
          >Ver</el-button
        >
      </el-col>
      <el-col :span="2" align="center">
        
      </el-col>
    </div>
    <modal-course-public-activity-view
      :conf="modalItemCoursePublicActivity"
      :formData="activityPreview"
      v-if="modalItemCoursePublicActivity.isVisible"
    />
  </div>
</template>
