<script>
import ModalStudentInfoComponent from '../features/ModalStudentInfoComponent';
import ModalSelectCareerView from './ModalSelectCareerView';
export default {
  extends: ModalStudentInfoComponent,
  name: 'ModalStudentInfoView',
  components: {
    'modal-select-career-view': ModalSelectCareerView,
  },
};
</script>
<template>
  <el-dialog
    title="Información de Inscripción"
    custom-class="subscription-account-modal"
    :visible.sync="conf.isVisible"
    width="50%"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Datos de Inscripción</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-button
              @click="onSelectCareers"
              type="primary"
              size="small"
              class="btn-primary"
              icon="el-icon-circle-plus"
              >Seleccionar Carreras</el-button
            >
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              :data="formData.collection"
              width="100%"
              max-height="270"
              element-loading-spinner="el-icon-loading"
              v-loading="isItemLoading"
              :element-loading-text="itemLoadingMessage"
            >
              <el-table-column prop="name" label="Carrera" min-width="300">
              </el-table-column>
              <el-table-column label="Área" min-width="300">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.knowledgeArea.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Universidad" min-width="300">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.university.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="OPCIONES"
                :width="160"
                align="center"
                text-align="center"
              >
                <template slot-scope="scope">
                  <el-dropdown @command="onChangeItemOptions">
                    <el-button size="small">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="{
                          command: 'delete',
                          item: scope.row,
                          index: scope.$index,
                        }"
                      >
                        <i class="el-icon-delete" aria-hidden="true"></i>
                        Eliminar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="16" :md="17" :lg="20" :xl="20">
            <el-form-item
              label="Preparatoria que cursa o cursó"
              prop="highSchool"
            >
              <el-select
                v-model="formData.highSchool"
                clearable
                placeholder="Seleccionar"
                value-key="id"
                size="small"
              >
                <el-option
                  v-for="item in highSchools"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :md="7" :lg="4" :xl="4">
            <el-button
              @click="onActiveNewHighSchool"
              type="primary"
              size="small"
              class="btn-primary btns-in-row-form-item"
              icon="el-icon-circle-plus"
              >Agregar Preparatoria</el-button
            >
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20" v-if="isNewHighSchool">
          <el-col :xs="24" :sm="16" :md="17" :lg="20" :xl="20">
            <el-form-item label="Nueva Preparatoria">
              <el-input v-model="currentHighSchool" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :md="7" :lg="4" :xl="4">
            <el-button
              @click="onAddHighSchool(currentHighSchool)"
              type="primary"
              size="small"
              class="btn-primary btns-in-row-form-item"
              icon="el-icon-circle-plus"
              >Agregar</el-button
            >
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="¿Por qué quiere estudiar la(s) carrera(s) seleccionada(s)?"
              prop="selectedCareerObservation"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.selectedCareerObservation"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="¿Qué espera de esta asesoría?"
              prop="advisoryObservation"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.advisoryObservation"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="¿Qué materia se le dificulta más entre matemáticas y español? y ¿Por qué?"
              prop="subjectComplicationObservation"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.subjectComplicationObservation"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item
              label="¿Padece de Alguna Enfermedad o Condición Especial?"
            >
              <el-switch
                style="display: block"
                v-model="formData.hasDiseaseOrSpecialCondition"
                active-color="#13ce66"
                inactive-text="No"
                active-text="Si"
                :inactive-value="false"
                :active-value="true"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Dirección" prop="homeAddress">
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.homeAddress"
                maxlength="255"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Acerca de ti" prop="about">
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.about"
                maxlength="255"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        class="btn btn-default steps_btn btn-primary finish"
        size="medium"
        >Enviar</el-button
      >
    </span>
    <modal-select-career-view
      :formData="itemCareers"
      :conf="modalItemCareers"
      v-if="modalItemCareers.isVisible"
    ></modal-select-career-view>
  </el-dialog>
</template>
