<script>
import ModalCareerComponent from '../../features/modals/ModalCareerComponent';
export default {
  extends: ModalCareerComponent,
  name: 'ModalCareerView',
};
</script>

<template>
  <el-dialog
    title="Carrera"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="career-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Carrera</legend>
         <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" maxlength="70" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Universidad" prop="university">
              <el-select v-model="formData.university" clearable filterable remote :remote-method="onFindUniversity" @change="onSelectUniversity" @focus="onFocus" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in universities" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Área de Conocimiento" prop="knowledgeArea">
              <el-select v-model="formData.knowledgeArea" clearable filterable @focus="onFocus" :disabled="isUniversity" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in knowledgeAreas" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>