import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SubscriptionPlanViewModel from '../../../../core/features/subscriptionplan/viewmodels/SubscriptionPlanViewModel';
import subscriptionPlanOptions from "../../../../core/features/subscriptionplan/config";

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'SubscriptionPlansContent-SubscriptionPlans',
            viewModel: null,
            permissions: null,
            modules: [],
            types: subscriptionPlanOptions.TYPES,
            rules: {
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: "Selecciona una Categoria", trigger: "change" }
                ],
                amount: [
                    { required: true, message: 'Cantidad ($) requerido.', trigger: 'change' }
                ],
                paymentQuantity: [
                    { required: true, message: 'Cantidad de Pagos requerido.', trigger: 'change' }
                ],
                dueDate:[
                    {
                        validator: this.onValidateDueDate,
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SubscriptionPlanViewModel(this)
        this.viewModel.bindFormView(this.formData)
    },
    mounted() { },
    methods: {
        onActive(response) {

        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedSubscriptionPlan', null);
        },
        onShowDueDate() {
            return this.formData.type !== null && this.formData.type === subscriptionPlanOptions.SUBSCRIPTION_PLANS.ON_SITE_DUE_DATE
        },
        onValidateDueDate(rules, value, callback) {
            if(this.formData.type === subscriptionPlanOptions.SUBSCRIPTION_PLANS.ON_SITE_DUE_DATE) {
                if(!value) {
                    callback(new Error("Fecha de vencimiento requerido"))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
    }
}