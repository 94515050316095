<script>
import FooterView from '../../shared/views/MainFooterView';
export default {
  name: 'IndexPageView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse  justify-content-center">
                    <a class="nav-item nav-link active" href="/">Inicio</a>
                    <a class="nav-item nav-link" href="/acerca">Acerca</a>
                    <a class="nav-item nav-link" href="/cursos">Cursos</a>
                    <a class="nav-item nav-link" href="/noticias"
                      >Noticias</a
                    >
                    <a class="nav-item nav-link" href="/contacto">Contacto</a>
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>Mejorando Vidas Con Conocimiento</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215td5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Caracter&iacute;sticas</h2>
                <p>En Appmisionate, tendr&aacute;s acceso a:</p>
                <img class="line-title" src="images/line.svg" alt="" />
              </div>
            </div>
            <div class="col-lg-3  col-sm-6">
              <div class="feature125">
                <i class="uil uil-mobile-android-alt"></i>
                <h4>Movilidad</h4>
                <p>
                  Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                  ipsum primis in faucibus.
                </p>
              </div>
            </div>
            <div class="col-lg-3  col-sm-6">
              <div class="feature125">
                <i class="uil uil-users-alt"></i>
                <h4>Ayuda Acad&eacute;mica</h4>
                <p>
                  Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                  ipsum primis in faucibus.
                </p>
              </div>
            </div>
            <div class="col-lg-3  col-sm-6">
              <div class="feature125">
                <i class="uil uil-award"></i>
                <h4>Aprendizaje Seguro</h4>
                <p>
                  Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                  ipsum primis in faucibus.
                </p>
              </div>
            </div>
            <div class="col-lg-3  col-sm-6">
              <div class="feature125">
                <i class="uil uil-globe"></i>
                <h4>Contenido 24/7</h4>
                <p>
                  Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                  ipsum primis in faucibus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215zd5">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="title478">
                <h2>¿Qu&eacute; es Appmisionate?</h2>
                <img class="line-title" src="images/line.svg" alt="" />
                <p>
                  Es una plataforma educativa que ofrece programas de
                  preparación para los exámenes de admisión a la universidad,
                  contamos con una infinidad de herramientas apegadas a las
                  nuevas formas de estudio de los futuros universitarios.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="story125">
                <img src="images/about/stroy_img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215td5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Estad&iacute;sticas</h2>
                <p>La mejor inversi&oacute;n que puedas hacer..</p>
                <img class="line-title" src="images/line.svg" alt="" />
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <div class="p__metric">
                1000+
                <span>Estudiantes</span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <div class="p__metric">
                50
                <span>Cursos</span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <div class="p__metric">
                10
                <span>Universidades</span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <div class="p__metric">
                15
                <span>Asesores</span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <div class="p__metric">
                4
                <span>Ciudades</span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <div class="p__metric">
                3000+
                <span>Alumnos Aceptados</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215xd5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Nuestros Maestros</h2>
                <p>Expertos con valores que deben de distinguir a un asesor educativo.</p>
                <img class="line-title" src="images/line.svg" alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="jmio125">
                <div class="row">
                  <div class="col-lg-6">
                    <ul class="_htg452 _abcd145">
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Compromiso</span></div></li>
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Innovación</span></div></li>
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Calidad</span></div></li>
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Respeto</span></div></li>
                    </ul>
                  </div>
                  <div class="col-lg-6">
                    <ul class="_htg452 _abcd145">
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Responsabilidad</span></div></li>
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Tolerancia</span></div></li>
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Dignidad</span></div></li>
                      <li><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>Equidad</span></div></li>
                    </ul>
                  </div>
                </div>
                
                <a href="/registrarse" class="crer_btn_link btn-primary"
                  >Unete a nuestra familia</a
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="story125">
                <img src="images/our-origins.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>
