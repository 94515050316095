<script>
import ListExploreLiveClassFinishedComponent from '../../features/lists/ListExploreLiveClassFinishedComponent';
import ModalStreamLiveClassView from '../modals/ModalStreamLiveClassView';
import ModalStudentInfoView from '../../../account/views/ModalStudentInfoView';

export default {
  extends: ListExploreLiveClassFinishedComponent,
  components: {
    'modal-stream-live-class': ModalStreamLiveClassView,
    'modal-student-info-view': ModalStudentInfoView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="section3125">
          <div class="la5lo1">
            <div class="row">
              <div class="col-md-3" v-for="(item) in collection" :key="item.id">
                <div class="stream_1 mb-30">
                  <router-link :to="'en-vivo/' + item.id +'/detalles'">
                  <span class="stream_bg">
                    <img :src="mapProfileImageUrl(item)" alt="" />
                    <h4>{{item.publisherTitle}}</h4>
                    <p>{{item.title}} <span></span> </p>
                  </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-3 col-lg-4">
						<div class="right_side">							
							<div class="fcrse_3">
								<div class="cater_ttle">
									<h4>Live Streaming</h4>
								</div>
								<div class="live_text">
									<div class="live_icon"><i class="uil uil-kayak"></i></div>
									<div class="En vivo-content">
										<p>Set up your channel and stream En vivo to your students</p>
										<button class="live_link" onclick="window.location.href = 'add_streaming.html';">Get Started</button>
										<span class="livinfo">Info : This feature only for 'Instructors'.</span>
									</div>
								</div>
							</div>							
						</div>
					</div> -->
    <div v-if="isCollectionEmpty(collection)"><h3>No se encontraron clases en vivo</h3></div>
    <el-pagination
      v-else
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    </div>
    <modal-stream-live-class
      :conf="modalItemLiveClass"
      :formData="itemLiveClass"
      v-if="modalItemLiveClass.isVisible"
      >
    </modal-stream-live-class>
    <modal-student-info-view
    :formData="studentInfoItem"
    :conf="modalSubscriptionInfo"
    v-if="modalSubscriptionInfo.isVisible"></modal-student-info-view>

  </div>
</template>
