<script>
import ListCoursesInstructorComponent from '../../features/lists/ListCoursesInstructorComponent';
import ModalCourseView from '../modals/ModalCourseView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListCoursesInstructorComponent,
  name: 'ListCourseInstructorView',
  components: {
    'modal-course-category-view': ModalCourseView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl course-categories">
      <el-col :xs="24" :sm="9" :md="11" :lg="8" :xl="6" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Curso</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Cursos</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <!--<el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.courseCategoryFilter" filterable remote clearable placeholder="Categorias..."
                :remote-method="onSearchCourseCategory" @change="onAllFilterChange(filterOptions.courseCategoryFilter.id, 'CourseCategoryId')" @focus="onFocusCourseCategory"
                value-key="id">
                  <el-option v-for="item in courseCategories" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.isPublishedFilter" clearable placeholder="Publicado..." @change="onAllFilterChange(filterOptions.isPublishedFilter, 'IsPublished')" value-key="id">
                  <el-option v-for="item in publishedStatuses" :key="item.id" :label="item.name" :value="item.state"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="4" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por Codigo." placement="top">
          <el-input size="small" placeholder="Buscar Codigo..." icon="search" v-model='filterOptions.codeFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.codeFilter, 'Code')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por titulo." placement="top">
          <el-input size="small" placeholder="Buscar titulo..." icon="search" v-model='filterOptions.titleFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.titleFilter, 'Title')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="CODIGO" min-width="120">
        <template slot-scope="scope">
         <div>
            {{ scope.row.code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="TITULO" min-width="240">
        <template slot-scope="scope">
          <div>
            {{ scope.row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CATEGORIA" align="center" min-width="200">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.courseCategory }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="AUTOR" align="center" min-width="200">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.author }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="PUBLICAR" align="center" min-width="120">
        <template slot-scope="scope">
          <el-switch
            style="display: block"
            v-model="scope.row.isPublished"
            active-color="#13ce66"
            inactive-text="No"
            active-text="Si"
            :inactive-value="false"
            :active-value="true"
            @change="onDoPublish(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="130"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'copy', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-copy-document" aria-hidden="true"></i> Copiar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-course-category-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-course-category-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
