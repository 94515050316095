import ItemComponent from "../../../../core/infraestructure/ItemComponent";
import CourseDetailViewModel from "../../../../core/features/course/viewmodels/CourseDetailViewModel";

export default {
  extends: ItemComponent,
  data() {
    return {
      viewModel: null,
      specialtySelected: null,
      isContentLoading: false,
      courseSections: [],
    };
  },
  props: {
    courseDetails: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CourseDetailViewModel(this);
  },
  mounted() {},
  methods: {
    orderSections(collection) {
      return _.sortBy(collection, "sort");
    },
  },
  watch: {
    'courseDetails.specialty'(newValue, oldValue) {
      this.courseSections = this.courseDetails.courseSections.filter((item) => {
        if(newValue) {
          var specialties = _.filter(item.specialties, { id: newValue })
          return (!item.isSpecialty && _.isEmpty(specialties)) || (item.isSpecialty && !_.isEmpty(specialties))
        } else {
          return item
        }
      })
    }
  }
};
