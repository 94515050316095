<script>
import SimulatorDetailSectionComponent from '../../features/details/SimulatorDetailSectionComponent';
import SimulatorDetailQuestionView from '../../views/details/SimulatorDetailQuestionView';

export default {
  extends: SimulatorDetailSectionComponent,
  components: {
    'simulator-detail-question-view': SimulatorDetailQuestionView,
  },
  name: 'SimulatorDetailSectionView',
};
</script>
<template>
  <div v-loading="isLoading">
    <div class="simulator-question-current">
      <h3>{{ currentSection.name }}</h3>
      <p>{{ currentSection.description }}</p>
    </div>
    <div v-if="questionSelected">
      <simulator-detail-question-view :simulator-id="simulatorId" :current-question="questionSelected" />
      <div class="course-content-navigation">
        <div class="lecture-nav">
          <div
            class="ctrl prev"
            @click="onPrevQuestion"
          >
            <i class="uil uil-angle-left"></i>
          </div>
          <div
            class="ctrl next"
            @click="onNextQuestion"
            v-if="!isFinalQuestion()"
          >
            <i class="uil uil-angle-right"></i>
          </div>
          <el-button
            v-if="isFinalQuestion()"
            class="ctrl next btn-primary"
            type="primary"
            @click="onFinishSection"
          >
            Finalizar Secci&oacute;n <i class="uil uil-angle-right"></i>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
