import ItemComponent from "../../../../core/infraestructure/ItemComponent";
import HeaderView from "../../../shared/views/HeaderView";
import MainMenuStudentView from "../../../shared/views/MainMenuStudentView";
import MainFooterView from "../../../shared/views/MainFooterView";
import PermissionHelper from "../../../../core/shared/helpers/PermissionHelper";
import CourseDetailViewModel from "../../../../core/features/course/viewmodels/CourseDetailViewModel";
import ModalCoursePromotionalVideoView from "../../views/modals/ModalCoursePromotionalVideoView";
import ModalCoursePublicActivityView from "../../views/modals/ModalCoursePublicActivityView";

export default {
  extends: ItemComponent,
  components: {
    "header-view": HeaderView,
    "main-menu-student-view": MainMenuStudentView,
    "main-footer-view": MainFooterView,
    "modal-course-promotional-video-view": ModalCoursePromotionalVideoView,
    "modal-course-public-activity-view": ModalCoursePublicActivityView,
  },
  data() {
    return {
      viewModel: null,
      courseDetails: null,
      courseSections: [],
      courseSubjectActivityTypes: [],
      careers: [],
      careerSelected: null,
      isFavorite: false,
      isLiked: false,
      totalLiked: 0,
      totalDisliked: 0,
      isDisliked: false,
      isViewed: false,
      totalViewed: 0,
      isSubscribed: false,
      modalItemCoursePromotionalVideo: {
        isVisible: false,
        isLoading: false,
      },
      isCourseSectionsLoading: false,
    };
  },
  created() {
    this.fullLoader = true;
    this.viewModel = new CourseDetailViewModel(this);
    this.onActive();
  },
  methods: {
    onCanShow(moduleName) {
      return PermissionHelper.canShow(moduleName);
    },
    onActive() {
      this.viewModel.onBindProfile();
      this.viewModel.onSearchDetail(this.$route.params.id);
    },
    onSearchDetailResponse(response) {
      this.courseDetails = response;
    },
    onSearchDetailErrorResponse(error) {
      this.onError(error);
    },
    onSearchCourseSubjecActivityTypesResponse(response) {
      this.courseSubjectActivityTypes = response.data;
    },
    getTotalActivities(collection) {
      return this.viewModel.getTotalActivities(collection);
    },
    getTotalDurationOfActivities(collection) {
      return this.viewModel.getTotalDurationOfActivities(collection);
    },
    getCoverImage(url) {
      return url ? url : "/images/courses/add_img.jpg";
    },
    getProfileImage(author) {
      return author && author.profileImageUrl
        ? author.profileImageUrl
        : "/images/left-imgs/img-1.jpg";
    },
    onShowPromotionalVideo() {
      this.viewModel.showModal(this.modalItemCoursePromotionalVideo, true);
    },
    hasPromotionalVideo() {
      return this.courseDetails.promotionalVideoUrl;
    },
    onDoLike() {
      this.viewModel.doLike(this.courseDetails.id);
    },
    onDoDislike() {
      this.viewModel.doDislike(this.courseDetails.id);
    },
    onDoFavorite() {
      this.viewModel.doFavorite(this.courseDetails.id);
    },
    onDoFavoriteResponse(response) {
      this.isFavorite = response.isFavorite;
    },
    onDoLikeResponse(response) {
      this.isLiked = response.isLiked;
      this.totalLiked = response.totalLiked;
    },
    onDoDislikeResponse(response) {
      this.isDisliked = response.isDisliked;
      this.totalDisliked = response.totalDisliked;
    },
    onDoViewedResponse(response) {
      this.isViewed = response.isViewed;
      this.totalViewed = response.totalViewed;
    },
    getSocialStatusClass(flag) {
      return flag ? "lkcm152 active" : "lkcm152";
    },

    mapTags(tags) {
      var collection = tags ? tags.split(",") : [];
      return collection.join(" | ");
    },
    mapProfileImageUrl(userProfile) {
      return userProfile && userProfile.profileImageUrl
        ? userProfile.profileImageUrl
        : "/images/left-imgs/img-2.jpg";
    },
    hasFacebookPage(instructor) {
      return (
        !_.isNil(instructor.pageUrlFacebook) &&
        !_.isEmpty(instructor.pageUrlFacebook)
      );
    },
    hasInstagramPage(instructor) {
      return (
        !_.isNil(instructor.pageUrlInstagram) &&
        !_.isEmpty(instructor.pageUrlInstagram)
      );
    },
    hasLinkedinPage(instructor) {
      return (
        !_.isNil(instructor.pageUrlLinkedIn) &&
        !_.isEmpty(instructor.pageUrlLinkedIn)
      );
    },
    mapTotalCourses(instructor) {
      return instructor.totalCourse === 1
        ? `${instructor.totalCourse} Curso`
        : `${instructor.totalCourse} Cursos`;
    },
    onGoToCourse(course) {
      this.fullLoader = true;
      if (this.isSubscribed) {
        if (this.courseDetails.specialtySelected) {
         this.viewModel.doStartCourse(course.id, this.courseDetails.specialtySelected);
        } else {
          this.fullLoader = false;
          this.onShowWarning("Selecciona una especialidad para inicializar el curso.");
        }
      } else {
        this.fullLoader = false;
        this.$alert("Para ver el contenido de nuestros cursos, necesitas una subscripción activa.", "Información",
          {
            confirmButtonText: "Aceptar",
            callback: (action) => {
              this.$router.push(`/estudiante/mi-cuenta`);
            },
            confirmButtonClass: "btn-cancel",
          }
        );
      }
    },
    onDoStartCourseResponse() {
      setTimeout(() => {
        this.$router.push(`/estudiante/cursos/${this.courseDetails.id}/aprender`)
      }, 400);
    },
    onChangeCareerSelected(specialtyId) {
      if(this.courseDetails.currentSpecialty && this.courseDetails.currentSpecialty != specialtyId) {
        this.$confirm("Cambiar especialidad eliminara todo el avance de tu curso. ¿Estas Seguro?", "Eliminar", {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
          cancelButtonClass: "btn-cancel",
          confirmButtonClass: "btn-primary",
        })
        .then(() => { 
          this.isCourseSectionsLoading = true;
          this.viewModel.removeCourseSpecialty(this.courseDetails.id, this.courseDetails.currentSpecialty);
        })
        .catch(() => { 
          this.$set(this.courseDetails, 'specialty', this.courseDetails.currentSpecialty)
          this.$set(this.courseDetails, "specialtySelected", this.courseDetails.currentSpecialty);
        });
      } else {
        this.isCourseSectionsLoading = true;
        setTimeout(() => {
          this.$set(this.courseDetails, "specialtySelected", specialtyId);
          this.isCourseSectionsLoading = false;
        }, 400);
      }
    },
    onRemoveCourseSpecialtyResponse() {
      setTimeout(() => {
        this.$set(this.courseDetails, "specialtySelected", this.courseDetails.specialty);
        this.$set(this.courseDetails, "currentSpecialty", this.courseDetails.specialty);
        this.isCourseSectionsLoading = false;
      }, 400);
    }
  },
};
