<script>
import ModalHistoryComponent from '../../features/modals/ModalHistoryComponent'
import ModalSimulatorSolvedView from './ModalSimulatorSolvedView'

export default {
    extends: ModalHistoryComponent,
    name:'ModalHistoryView',
    components: {
        'modal-simulator-solved-view': ModalSimulatorSolvedView
    },
}
</script>
<template>
  <el-dialog :title="conf.title" custom-class="simulator-solved-history-by-students" :visible.sync="conf.isVisible" width="85%" :before-close="onAccept" :close-on-click-modal="false">
    <el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'top'" v-loading="conf.isLoading">
      <el-row :gutter="20">
        <fieldset>
          <legend>FILTROS DE BUSQUEDA</legend>
          <el-col :xs="24" :sm="12" :md="12" :lg="9" :xl="7">
            <el-form-item>
                <el-select size="small"
                    v-model="formData.simulator"
                    filterable
                    remote
                    reserve-keyword
                    clearable
                    placeholder="Simulador Oficial..."
                    :remote-method="onSearchSimulators"
                    @change="onAllFilterChange(formData.simulator, 'SimulatorId')"
                    value-key="id"
                    >
                        <el-option v-for="item in simulators" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="9" :xl="7">
            <el-form-item>
                <el-input
                    size="small"
                    v-model="formData.student"
                    clearable
                    placeholder="Nombre del Estudiante..."
                    v-on:input="onAllFilterChange(formData.student, 'StudentName')"
                    >
                ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="9" :xl="7">
            <el-form-item>
              <el-date-picker :disabled="!formData.simulator" size="small" v-model="formData.rangeDate" type="daterange" range-separator="-" start-placeholder="F. Inicio" end-placeholder="F. Fin" value-format="yyyy-MM-dd" format="dd MMM yyyy" @change="onRangeDate(formData.rangeDate, 'StartDate', 'EndDate')"></el-date-picker>
            </el-form-item>
          </el-col>
        </fieldset>
      </el-row>
      <el-row :gutter="20" style="margin-top: -20px">
        <fieldset>
          <legend>LISTA DE RESULTADOS</legend>
          <el-table :data="collectionHistory" empty-text="No se encontraron resultados." v-loading="isItemLoading" element-loading-spinner="el-icon-loading" :element-loading-text="itemLoadingMessage" height="500px">
            <el-table-column label="" width="60">
                <template slot-scope="scope">
                <div>
                    <el-avatar :src="mapProfileImage(scope.row.userProfile)"></el-avatar>
                </div>
                </template>
            </el-table-column>
            <el-table-column label="POSICIONAMIENTO" min-width="150" align="center">
                <template slot-scope="scope">
                <div>
                    <el-tag type="info" effect="plain">{{ scope.row.rank }}</el-tag>
                </div>
                </template>
            </el-table-column>
            <el-table-column label="NOMBRE" min-width="350">
                <template slot-scope="scope">
                <div>
                    <el-tag type="info" effect="plain">{{ scope.row.userProfile.firstName }} {{ scope.row.userProfile.lastName }}</el-tag>
                </div>
                </template>
            </el-table-column>
            <el-table-column label="CORREO" min-width="350">
                <template slot-scope="scope">
                <div>
                    <el-tag type="info" effect="plain">{{ scope.row.userProfile.email }}</el-tag>
                </div>
                </template>
            </el-table-column>
            <el-table-column label="RESUELTO EL:" min-width="120" align="center">
                <template slot-scope="scope">
                <div>
                    <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.simulatorStudents[0].createdAt | formatDate}}</el-tag>
                </div>
                </template>
            </el-table-column>
            <el-table-column label="CARRERAS" min-width="100" align="center">
                <template slot-scope="scope">
                <el-popover placement="bottom" width="300" height="50" trigger="click">
                    <el-table :data="scope.row.careers">
                    <el-table-column min-width="350" property="name" label="Carreras"></el-table-column>
                    </el-table>
                    <el-button type="primary" size="small" align="center" class="btn-primary" slot="reference" v-if="sizeCareers(scope.row.careers)">Ver</el-button>
                </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="ACTIVO" min-width="120" align="center">
                <template slot-scope="scope">
                <el-switch
                    disabled
                    v-model="scope.row.userProfile.status"
                    active-color="#13ce66"
                    active-text="SI"
                    inactive-text="NO"
                    size="small"
                    :inactive-value="1"
                    :active-value="2"
                >
                </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="RESULTADOS:" min-width="110" align="center">
                <template slot-scope="scope">
                <div>
                    <el-button type="primary" size="small" align="center" class="btn-primary" @click="onShowSimulatorSolved(scope.row.id, formData.simulator)" slot="reference">Ver</el-button>
                </div>
                </template>
            </el-table-column>
          </el-table>
          <el-pagination
            small
            layout="prev, pager, next"
            :page-count="pagination.totalPages"
            :current-page.sync="currentPage"
            @current-change="onPageChange"
          ></el-pagination>
        </fieldset>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onAccept" size="small" class="btn-cancel">Aceptar</el-button>
    </span>
    <modal-simulator-solved-view :conf="modalSimulatorSolved" v-if="modalSimulatorSolved.isVisible"></modal-simulator-solved-view>
  </el-dialog>
</template>