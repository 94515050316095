<script>
import SimulatorStudentDetailResultComponent from '../features/SimulatorStudentDetailResultComponent';

export default {
  extends: SimulatorStudentDetailResultComponent,
  components: {},
  name: 'SimulatorStudentDetailResultView',
};
</script>
<template>
  <div>
    <div class="simulator-result-top" v-if="simulatorResult">
      <div class="container">
       <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h3>{{ simulatorResult.student }}</h3>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h3>{{ simulatorResult.greeting }}</h3>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <div class="score-tag">
              <div class="inner">
                {{ simulatorResult.finalScore }}
              </div>
            </div>
            <div class="points-label">
              PUNTOS
            </div>
            <el-row type="flex" class="row-bg points-info" :gutter="20">
              <el-col :span="24" align="center">
                <h5>PUNTAJE</h5>
                <h6>
                  MIN. {{ simulatorResult.lowScore }} / MAX.
                  {{ simulatorResult.topScore }}
                </h6>
              </el-col>
            </el-row>
            
          </el-col>
        </el-row>
         
      </div>
    </div>
    <div class="container simulator-result-content" v-if="simulatorResult">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24" align="center">
          <h3>Resultados de Simulador de Prueba</h3>
        </el-col>
      </el-row>
       <el-row class="row-bg section-header" :gutter="20">
        <el-col :xs="10" :sm="16" :md="16" :lg="19" :xl="19" >
          <div class="info-text">SECCIONES</div>
        </el-col>
        <el-col :xs="14" :sm="8" :md="8" :lg="5" :xl="5" align="center">
          <div class="info-text">ACIERTOS / PREGUNTAS</div>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg section"
        :gutter="20"
        v-for="(section, index) in simulatorResult.sectionResults"
        v-bind:key="index"
      >
        <el-col :span="19" class="section-name">{{ section.name }}</el-col>
        <el-col :span="5" align="center">
          <div :class="getStatusTag(section.percentage)">
            <div class="inner">
              {{ section.totalSuccess }}/{{ section.totalQuestions }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="simulator-result-footer" v-if="simulatorResult">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24" align="center">
          <el-button
            size="large"
            type="primary"
            class="btn-primary btn-fullscreen"
            @click="onFinish"
          >
            Finalizar Simulador
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div v-loading.fullscreen.lock="isLoading"></div>
  </div>
</template>
