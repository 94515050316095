<script>
import FileSectionComponent from '../../features/details/FileSectionComponent';

export default {
    extends: FileSectionComponent,
    name: 'FileSectionView',
};
</script>

<template>
  <div v-loading="isFileLoading">
    <div
      v-if="!section.file && !section.fileUrl"
    >
      <el-upload
        class="file-uploader"
        :name="'sectionFileUploader'"
        action
        :on-success="onSuccessUploadSectionFile"
        :before-upload="onBeforeUploadSectionFile"
        :on-change="onChangeUploadSectionFile"
        :show-file-list="false"
        :data="section.sectionFile"
      >
        <el-button size="small" type="primary" class="btn-primary"
          ><i class="el-icon-upload"></i> Click para subir archivo</el-button
        >
      </el-upload>
    </div>
    <div
      v-if="section.file || section.fileUrl"
    >
      <el-button-group>
        <el-button
          size="small"
          type="primary"
          class="btn-primary"
          :disabled="section.isSectionFileTemp"
          @click="onShowSectionFile(section.fileUrl)"
          v-if="section.fileUrl"><i class="el-icon-view"></i> Ver Archivo</el-button
        >
        <el-button
          size="small"
          type="danger"
          class="btn-delete"
          @click="onRemoveSectionFile"
          ><i class="el-icon-circle-close"></i> Eliminar Archivo</el-button
        >
      </el-button-group>
    </div>
  </div>
</template>
