export default class SubscriptionPlan {
    constructor(options) {
      Object.assign(this, options)
    }
  
    getFullName(){
      return `${this.firstName} ${this.lastName}`
    }
  
    getPermissions(){
  
    }
  
    toSend() {
      return {
        id: this.id,
        code: this.code,
        name: this.name,
        amount: this.amount,
        type: this.type,
        paymentQuantity: this.paymentQuantity,
        description: this.description,
        dueDate:this.dueDate,
        isActive: this.isActive
      }
    }
  }
  