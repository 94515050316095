import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import SimulatorOfficialSectionViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialSectionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            editors: [],
            questionEditor: null,
            isQuestionsLoading: false,
            careers: [],
            modalItemQuestion: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                questionQuantity: [
                    { validator: this.validateQuestionQuantity }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                description: [
                    { validator: this.validateDescription }
                ],
                specialties: [
                    { validator: this.isSpecialtiesRequired }
                ],
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorOfficialSectionViewModel(this);
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedSimulatorSectionQuestions', this.onSaveSimulatorSectionQuestions);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSimulatorSectionQuestions', this.onSaveSimulatorSectionQuestions);
    },
    methods: {
        onActive() {
            this.viewModel.bindQuestionFormView(this.formData);
        },
        collectionCourseSubjectTestQuestionAnswers(collection) {
            return _.filter(collection, function(o) { 
                if(o.updateState !== 3) return o; 
            });
        },
        onFindCareersResponse(response) {
            this.careers = response.data;
        },
        onFocus() {
            this.$forceUpdate()
        },
        onAddQuestion(){
            if(this.viewModel.validateFormQuestion(this.formData.questionQuantity)) {
                //this.itemQuestion = this.viewModel.createQuestion(this.formData.sectionSimulatorOfficialQuestions);
                this.itemQuestion = {
                    questions : this.formData.getQuestions(),
                    questionQuantity : this.formData.questionQuantity,
                    universityId : this.formData.universityId,
                    careerId : this.formData.careerId,
                    isSpecialty : _.isUndefined(this.formData.isSpecialty) ? false : this.formData.isSpecialty,
                };
                if(this.formData.isSpecialty) {
                    this.itemQuestion.specialties = this.viewModel.mapSpecialties(this.formData.specialties);
                }
                this.viewModel.showModal(this.modalItemQuestion, true);
                this.viewModel.showModalLoading(this.modalItemQuestion, true);
            }
        },
        isSpecialtiesRequired(rule, value, callback) {
            if(this.formData.isSpecialty) {
                if(_.isEmpty(value)) {
                    callback(new Error('Especialidad requerida.'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        onSaveSimulatorSectionQuestions(collection) {
            //this.viewModel.addNewQuestions(this.formData.simulatorQuestions, collection);
            this.formData.addSimulatorOfficialQuestion(collection);
            this.viewModel.showModal(this.modalItemQuestion, false);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    EventBus.$emit('onSubmitedSimulatorSection', this.formData);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onAddAnswer() {
            this.viewModel.addNewAnswer(this.formData.courseSubjectTestQuestionAnswers);
        },
        onDeleteAnswer(index) {
            this.formData.courseSubjectTestQuestionAnswers[index].updateState = 3;
            this.editors[index].updateState = 3;
            this.$forceUpdate()
            //this.viewModel.removeAnswer(this.formData.courseSubjectTestQuestionAnswers, index);
        },
        onUpdateEditor(data) {
            this.$set(this.formData, 'description', data);
        },
        onChangeIsSpecialty() {
            this.$set(this.formData, 'specialties', '');
        },
        validateDescription(rule, value, callback) {
            this.viewModel.isDescriptionValid(rule, value, callback)
        },
        validateQuestionQuantity(rule, value, callback) {
            this.viewModel.isQuestionQuantityValid(rule, value, callback, this.formData.totalPartialQuestion, this.formData.limitQuestion)
        },
        getCouseCategoryName(courseCategory) {
            return this.viewModel.getCouseCategoryName(courseCategory);
        },
        sortSimulatorSectionQuestions(collection) {
            return this.viewModel.filterAndSortSectionQuestions(collection);
        },
        onChangeDrag(collection) {
            this.viewModel.changeDrag(collection);
        },
        onRemoveQuestion(index) {
            this.$confirm("¿Desea eliminar la pregunta seleccionada?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.conf.isLoading = true
                    setTimeout(() => {
                        //this.questionSelectedIndex = null
                        this.formData.removeQuestion(index)
                        this.$forceUpdate()
                        this.formData.updateSimulatorOfficialQuestionPosition()
                        this.conf.isLoading = false
                    }, 400);
                })
                .catch(() => { 
                    //this.questionSelectedIndex = null
                });
        }
    }
}