<script>
import ListDiscountsComponent from '../../features/lists/ListDiscountsComponent';
import ModalDiscountView from '../modals/ModalDiscountView';
import ModalHistoryDiscountView from '../modals/ModalHistoryDiscountView'
import ModalImportView from '../../../shared/views/ModalImportView';

export default {
  extends: ListDiscountsComponent,
  name: 'ListDiscountView',
  components: {
    'modal-discount-view': ModalDiscountView,
    'modal-history-discount-view': ModalHistoryDiscountView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl discounts">
      <el-col :xs="24" :sm="13" :md="14" :lg="9" :xl="7" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Descuento</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button size="small" class="btn-primary-light" icon="el-icon-s-data" @click="onShowHistory" :disabled="!onCanRead(modulePermissions)" >Historial de Cupones Usados</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Descuentos</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <!--<el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.subscriptionPlanFilter" filterable remote clearable placeholder="Plan de Inscripción..."
                :remote-method="onSearchSubscriptionPlans" @change="onAllFilterChange(filterOptions.subscriptionPlanFilter.id, 'SubscriptionPlanId')" value-key="id">
                  <el-option v-for="item in subscriptionPlans" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="block">
                  <span class="block-title">F. Reporte:</span>
                  <el-date-picker
                    size="small"
                    v-model="reportDate"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="F. Inicio"
                    end-placeholder="F. Fin"
                    value-format="yyyy-MM-dd"
                    format="dd MMM yyyy"
                    @change="onRangeDate(reportDate, 'StartDate', 'EndDate')"
                  ></el-date-picker>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="3" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por Codigo." placement="top">
          <el-input size="small" placeholder="Buscar Codigo" icon="search" v-model='searchCode'
          @keyup.enter.native="onAllFilterChange(searchCode, 'Code')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por nombre." placement="top">
          <el-input size="small" placeholder="Buscar nombre" icon="search" v-model='searchName'
          @keyup.enter.native="onAllFilterChange(searchName, 'Name')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="3" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por Cantidad." placement="top">
          <el-input size="small" placeholder="Cantidad ($)" icon="search" v-model='searchAmount'
          @keyup.enter.native="onAllFilterChange(searchAmount, 'Amount')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="3" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por Capacidad." placement="top">
          <el-input size="small" placeholder="Capacidad" icon="search" v-model='searchMax'
          @keyup.enter.native="onAllFilterChange(searchMax, 'Max')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="CODIGO" min-width="190">
        <template slot-scope="scope">
         <div>
            {{ scope.row.code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="NOMBRE" min-width="300">
        <template slot-scope="scope">
          <div>
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="PLAN DE INSCRIPCIÓN" min-width="300">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" effect="plain">{{ scope.row.subscriptionPlan }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CAPACIDAD" min-width="120" align="center">
        <template slot-scope="scope">
          <div> <el-tag type="default" effect="plain"> {{scope.row.max}}</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column label="DISPONIBILIDAD" min-width="130" align="center">
        <template slot-scope="scope">
          <div> <el-tag type="default" effect="plain"> {{scope.row.availability}}</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column label="CANTIDAD (%)" min-width="120" align="center">
        <template slot-scope="scope">
          <div> <el-tag type="default" effect="plain"> {{scope.row.amount}}</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column prop="startDate" label="F. INICIO" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.startDate | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="endDate" label="F. CONCLUSIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.endDate)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.endDate | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <!--
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-discount-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-discount-view>
    <modal-history-discount-view :conf="modalHistory" v-if="modalHistory.isVisible"></modal-history-discount-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>