import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import CourseSubjectTestViewModel from '../../../../core/features/course/viewmodels/CourseSubjectTestViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            answerEditors: [],
            questionEditor: null,
            isAnswerLoading: false,
            rules: {
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                description: [
                    { validator: this.validateDescription }
                ],
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectTestViewModel(this);
        this.onActive();
    },
    mounted() { },
    beforeDestroy() { },
    methods: {
        onActive() {
            this.viewModel.bindQuestionFormView(this.formData);
        },
        onCancel() {
            EventBus.$emit('onCanceledCourseSubjectTestQuestion', null);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    EventBus.$emit('onSubmitedCourseSubjectTestQuestion', this.formData);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onAddAnswer() {
            this.conf.isLoading = true
            this.formData.addAnswer()
            setTimeout(() => {
                this.formData.setStateToUpdate()
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.conf.isLoading = false
            }, 400);
        },
        onDeleteAnswer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning', cancelButtonClass: "btn-cancel", confirmButtonClass: "btn-primary"})
                .then(() => {
                    this.conf.isLoading = true
                    setTimeout(() => {
                        this.formData.removeAnswer(index)
                        this.viewModel.removeAnswerEditor(this.answerEditors, index)
                        this.formData.setStateToUpdate()
                        this.$forceUpdate()
                        this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                        this.conf.isLoading = false
                    }, 400);
                })
                .catch(() => {

                });
        },
        validateDescription(rule, value, callback) {
            this.viewModel.isDescriptionValid(rule, value, callback)
        }
    }
}