import ItemComponent from "../../../../core/infraestructure/ItemComponent";
import CourseViewModel from "../../../../core/features/website/viewmodels/CourseViewModel";

export default {
  extends: ItemComponent,
  data() {
    return {
      viewModel: null,
      specialtySelected: null,
      isContentLoading: false,
    };
  },
  props: {
    courseDetails: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new CourseViewModel(this);
  },
  mounted() {
    setTimeout(() => {
      this.$set(this, 'specialtySelected', this.courseDetails.specialty)
    }, 400);
  },
  methods: {
    orderSections(collection) {
      return _.sortBy(collection, "sort");
    },
  },
};
