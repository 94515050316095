export default {
  TYPES: [
    {
      id: 1,
      name: 'PRESENCIAL'
    },
    {
      id: 2,
      name: 'EN LINEA UN MES'
    },
    {
      id: 3,
      name: 'EN LINEA TRES MES'
    },
    {
      id: 4,
      name: 'EN LINEA SEIS MES'
    },
    {
      id: 5,
      name: "PRESENCIAL CON FECHA DE VENCIMIENTO"
    }
  ],
  SUBSCRIPTION_PLANS: {
    ON_SITE: 1,
    ON_LINE_ONE_MONTH: 2,
    ON_LINE_THREE_MONTH: 3,
    ON_LINE_SIX_MONTH: 4,
    ON_SITE_DUE_DATE: 5,
  },
  SUBSCRIPTION_PLANS_LABEL: {
    ON_SITE: 'PRESENCIAL',
    ON_LINE_ONE_MONTH: 'EN LINEA UN MES',
    ON_LINE_THREE_MONTH: 'EN LINEA TRES MES',
    ON_LINE_SIX_MONTH: 'EN LINEA SEIS MES',
    ON_SITE_DUE_DATE: 'PRESENCIAL CON FECHA DE VENCIMIENTO'
  },
}