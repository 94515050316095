<script>
import SimulatorOfficialResultComponent from '../../features/details/SimulatorOfficialResultComponent';
import ModalSimulatorResultAnswers from '../modals/ModalSimulatorResultAnswers';

export default {
  extends: SimulatorOfficialResultComponent,
  components: {
    'modal-simulator-result-answers-view': ModalSimulatorResultAnswers,
  },
  name: 'SimulatorOfficialResultView',
};
</script>
<template>
  <div>
    <div class="simulator-result-top" v-if="simulatorResult">
      <div class="container">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h3>{{ simulatorResult.student }}</h3>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h4>{{ simulatorResult.greeting }}</h4>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <div class="score-tag">
              <div class="inner">
                {{ simulatorResult.finalScore }}
              </div>
            </div>
            <div class="points-label">
              PUNTOS
            </div>
            <el-row type="flex" class="row-bg points-info" :gutter="20">
              <el-col :span="24" align="center">
                <h5>PUNTAJE</h5>
                <h6>
                  MIN. {{ simulatorResult.lowScore }} / MAX.
                  {{ simulatorResult.topScore }}
                </h6>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg points-info" :gutter="20">
              <el-col :span="24" align="center">
                <h5>POSICIONAMIENTO</h5>
                <h6>{{ simulatorResult.rank }}</h6>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="container simulator-result-content" v-if="simulatorResult">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24" align="center">
          <h3>Resultados de {{ simulatorResult.simulatorName }}</h3>
        </el-col>
      </el-row>
      <el-row class="row-bg section-header" :gutter="20">
        <el-col :xs="10" :sm="16" :md="16" :lg="19" :xl="19" >
          <div class="info-text">SECCIONES</div>
        </el-col>
        <el-col :xs="14" :sm="8" :md="8" :lg="5" :xl="5" align="center">
          <div class="info-text">ACIERTOS / PREGUNTAS</div>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg section"
        :gutter="20"
        v-for="(section, index) in simulatorResult.sectionResults"
        v-bind:key="index"
        
      >
      
        <el-col :span="15" class="section-name">{{ section.name }} </el-col>
        <el-col :span="4" align="center" class="section-show-results">
          <el-button type="primary" class="btn-primary" size="small" @click="onGetAnswersResult(section)">
            <i class="el-icon-view" /> <span class="simulator-result-view-text">Ver Resultados</span>
          </el-button>
        </el-col>
        <el-col :span="5" align="center">
          <div :class="getStatusTag(section.percentage)">
            <div class="inner">
              {{ section.totalSuccess }}/{{ section.totalQuestions }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="container simulator-result-footer" v-if="simulatorResult">
      <el-row  class="row-bg" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="center" class="hidden-xs-only hidden-sm-only hidden-md-only">
          <el-button-group>
            <el-button
            size="large"
            type="primary"
            class="btn-primary btn-fullscreen"
            @click="onFinish"
            icon="el-icon-back"
          >
            Regresar a Simuladores
          </el-button>
            <el-button
              size="large"
              type="primary"
              class="btn-primary btn-fullscreen"
              @click="onRepeat"
              icon="el-icon-refresh"
            >
              Repetir Simulador
            </el-button>
          </el-button-group>
        </el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" align="center" class="hidden-lg-only hidden-xl-only">
          <el-button
            size="large"
            type="primary"
            class="btn-primary btn-fullscreen"
            @click="onFinish"
            icon="el-icon-back"
          >
            Regresar a Simuladores
          </el-button>
          
        </el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" align="center" class="hidden-lg-only hidden-xl-only">
          <el-button
            size="large"
            type="primary"
            class="btn-primary btn-fullscreen"
            @click="onRepeat"
            icon="el-icon-refresh"
          >
            Repetir Simulador
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div v-loading.fullscreen.lock="isLoading"></div>
    <modal-simulator-result-answers-view
      v-if="modalShowResults.isVisible"
      :conf="modalShowResults"
      :form-data="resultAnswers" />
  </div>
</template>
