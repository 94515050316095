import * as signalR from '@microsoft/signalr'
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import LiveClassViewModel from '../../../../core/features/liveclass/viewmodels/LiveClassViewModel'


export default {
    extends: ItemComponent,
    components: {},
    data() {
        return {
            viewModel: null,
            liveClassDetails: null,
            chat: [],
            message: '',
        };
    },
    created() {
        this.fullLoader = true;
        this.showLoading(true, 'Cargando el en vivo...');
        this.connectoToSignalRHub();
        this.viewModel = new LiveClassViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindLiveClassDetails(this.$route.params.id)
        },
        onBindLiveClassDetailsResponse(response) {
            this.liveClassDetails = response.data
            this.viewModel.findByLiveClass(this.liveClassDetails.id)
        },
        mapProfileImageUrl(publisherImageUrl) {
            return (publisherImageUrl) ? publisherImageUrl : '/images/left-imgs/img-2.jpg';
        },
        sendMessage() {
            this.viewModel.onDoPublish({
                description: this.message,
                liveClassId: this.liveClassDetails.id
            })
        },
        findByLiveClassResponse(response) {
            this.chat = response.data;
            setTimeout(() => {
                this.showLoading(false, '');
            }, 400);
        },
        doPublishResponse(response)  {
            this.message = '';
        },
        connectoToSignalRHub() {
            let baseURL = '/chat-events'

            if(process.env.NODE_ENV == 'development') {
                baseURL = location.protocol + '//' + location.hostname + ':5000/chat-events'
            }
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(baseURL)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            connection.start()
                .then(() => { connection.on("RefreshChat", this.onRefreshChat); })
                .catch(this.onError);
        },
        onRefreshChat() {
            this.viewModel.findByLiveClass(this.liveClassDetails.id)
        },
    },
};