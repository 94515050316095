import BaseModel from "../../../infraestructure/BaseModel"
import SimulatorOfficialSection from "./SimulatorOfficialSection"

export default class SimulatorOfficial extends BaseModel {
    constructor(options, state) {
        super(options, state);
    }

    toSend() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            isPublic: this.isPublic,
            isTimer: this.isTimer,
            coverImageUrl: this.coverImageUrl,
            timerType: (this.timerType) ? this.timerType.id : null,
            duration: this.duration,
            questionQuantity: this.questionQuantity,
            topScore: this.topScore,
            minimumScore: this.minimumScore,
            universityId: (this.university) ? this.university.id : null,
            difficultyLevel: (this.difficultyLevel) ? this.difficultyLevel.id : null,
            simulatorSections: this.simulatorSections.map(item => item.toSend()),
        }
    }

    //#region SIMULATOR SECTIONS

    getSimulatorOfficialSections() {
        return _.sortBy(this.simulatorSections.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    getTotalSections() {
        return _.size(this.getSimulatorOfficialSections());
    }

    getTotalDuration() {
        return _.sumBy(this.getSimulatorOfficialSections(), (item) => item.getTotalDuration());
    }

    getDuration() {
        let totalDuration = this.getTotalDuration();
        return moment.utc(moment.duration(totalDuration, "minutes").asMilliseconds()).format("HH:mm")
    }

    createSimulatorOfficialSection() {
        return new SimulatorOfficialSection({
            name: '',
			descripton: '',
            sort: this.getNextSimulatorOfficialSectionIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            simulatorSectionQuestions: []
        }, 'create')
    }

    editSimulatorOfficialSection(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new SimulatorOfficialSection(data, 'update');
    }

    addSimulatorOfficialSection(data) {
        this.simulatorSections.push(new SimulatorOfficialSection(data, 'create'));
    }

    updateSimulatorOfficialSection(selectedIndex, data) {
        let section = this.getSimulatorOfficialSections()[selectedIndex]
        var index = _.findIndex(this.simulatorSections, section)
        if (index != -1) {
            this.simulatorSections[index] = data
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    updateSimulatorOfficialSectionPosition() {
        this.simulatorSections.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
            item.updateState = (item.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        });

    }

    removeSimulatorOfficialSection(selectedIndex) {
        let section = this.getSimulatorOfficialSections()[selectedIndex]
        var index = _.findIndex(this.simulatorSections, section)
        
        if (index != -1) {
            if (section.id) {
                this.simulatorSections[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.simulatorSections.splice(index, 1);
            }
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    getNextSimulatorOfficialSectionIndex() {
        return _.size(this.getSimulatorOfficialSections()) + 1;
    }

    //#endregion
}