<script>
import ListSimulatorStudentComponent from '../../features/lists/ListSimulatorStudentComponent';
// import ModalSimulatorView from '../modals/ModalSimulatorView';
import ModalImportView from '../../../shared/views/ModalImportView';
// import ModalStudentInfoView from '../../../account/views/ModalStudentInfoView'


export default {
  extends: ListSimulatorStudentComponent,
  name: 'ListSimulatorStudentView',
  components: {
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl simulator-categories">
      <div class="col-xl-12 col-lg-8">
        <div class="section3125">
          <div class="explore_search">
            <div class="ui search focus">
              <div class="ui left icon input swdh11">
                <el-input
                  type="text"
                  placeholder="Buscar simulador."
                  class="prompt srch_explore"
                  v-model="searchName"
                  @keyup.enter.native="onAllFilterChange(searchName, 'Name')"
                  suffix-icon="search"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-row>
    <div class="row" v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <div class="col-lg-3 col-md-4" v-for="simulator in collection" :key="simulator.id">
        <div class="fcrse_1 mt-30">
            <router-link to="" event @click.native.prevent="isValidateSubscrition('/estudiante/simuladores/' + simulator.id +'/aplicar')" class="fcrse_img">
              <img :src="getCoverImage(simulator.coverImageUrl)" alt="" />
              <div class="simulator-overlay">
                
                <span class="play_btn1"><i class="uil uil-play"></i></span>
                
              </div>
            </router-link>
            <div class="fcrse_content">
              <router-link to="" event @click.native.prevent="isValidateSubscrition('/estudiante/simuladores/' + simulator.id +'/aplicar')" class="crse14s" >{{simulator.name}}</router-link>
            </div>
          </div>
      </div>
    </div>
    <div v-if="isCollectionEmpty(collection)"><h3>No se encontraron simuladores</h3></div>
    <el-pagination
      v-else
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-simulator-category-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-simulator-category-view>
    <modal-student-info-view
    :formData="studentInfoItem"
    :conf="modalSubscriptionInfo"
    v-if="modalSubscriptionInfo.isVisible">
    </modal-student-info-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
