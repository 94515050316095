import BaseComponent from '../../../core/infraestructure/BaseComponent';
import AccountRegisterViewModel from '../../../core/features/authentication/viewmodels/AccountRegisterViewModel.js';

export default {
    extends: BaseComponent,
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Favor de ingresar información.'));
            } else if (value !== this.formData.password) {
                callback(new Error('Las contraseñas no coinciden.'));
            } else {
                callback();
            }
        };
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                password: ''
            },
            rules: {
                firstName: [
                    { required: true, message: 'Nombre(s) requerido(s).', trigger: 'blur' },
                ],
                lastName: [
                    { required: true, message: 'Apellido(s) requerido(s).', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'Correo requerido.' },
                    { type: 'email', message: 'Ingresar email valido', trigger: 'blur,change' },
                    { validator: this.verifyUserEmail }
                ],
                password: [
                    { required: true, message: 'Contraseña requerida.' },
                    { min: 6, message: 'Se requieren más de 6 caracteres', trigger: 'blur,change', },
                    { type: 'string', pattern: /^[0-9a-zA-ZñÑ@._-]+$/g, message: 'Valores alfabeticos y (@,._-)', trigger: 'change' }
                ],
                checkPass: [
                    { required: true, message: 'Verifiación de contraseña requerida.' },
                    { min: 6, message: 'Se requieren más de 6 caracteres', trigger: 'blur,change', },
                    { type: 'string', pattern: /^[0-9a-zA-ZñÑ@._-]+$/g, message: 'Valores alfabeticos y (@,._-)', trigger: 'change' },
                    { validator: validatePass },
                ],
            },
        };
    },
    created() {
        this.viewModel = new AccountRegisterViewModel(this);
    },
    methods: {
        onSignup(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.viewModel.showFormLoading(true, 'Registrando cuenta...');
                    this.viewModel.signUp(this.formData);
                    return true;
                }
                return false;
            });
        },
        onSignUpResponse(response) {
            this.viewModel.showFormLoading(false, '');
            this.viewModel.showNotification('success', 'Completo', response.message);

            setTimeout(() => {
                this.$router.push("/");
            }, 2000);
        },
        onHandleError(error) {
            this.viewModel.showFormLoading(false, '');
            this.onError(error);
        },
        
    },
    computed: {},
};