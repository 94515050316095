import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import DiscountViewModel from '../../../../core/features/discount/viewmodels/DiscountViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'Authorization-Users',
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            subscriptionPlans: [],
            rules: {
                subscriptionPlan: [
                    { required: true, message: 'Plan de Inscripción requerido.', trigger: 'change' }
                ],
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                reportDate: [
                    { required: true, message: "Fecha requerida.", trigger: "change" }
                ],
                amount: [
                    { required: true, message: 'Cantidad ($) requerido.', trigger: 'blur' }
                ],
                max: [
                    { required: true, message: 'Capacidad de Usuarios requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new DiscountViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedDiscount', null);
        },
        onSearchSubscriptionPlans(data) {
            if(data) {
                this.viewModel.onSearchSubscriptionPlans(`Name=${data}&IsActive=true`)
            } else {
                this.subscriptionPlans = []
            }
        },
        onSearchSubscriptionPlansResponse(response) {
            this.subscriptionPlans = response.data;
        }
    }
}