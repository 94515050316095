import BaseModel from '../../../infraestructure/BaseModel';
import SimulatorAnswer from './SimulatorAnswer';
export default class SimulatorQuestion extends BaseModel{
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            simulatorQuestionId: this.id,
            code: this.code,
            name: this.name,
            description: this.description,
            type: this.type.id,
            difficultyLevel: this.difficultyLevel.id,
            simulatorQuestionCategoryId: (this.simulatorQuestionCategory) ? this.simulatorQuestionCategory.id : null,
            universityId: (this.university) ? this.university.id : null,
            isSpecialty: this.isSpecialty,
            specialties: (this.isSpecialty) ? this.mapSpecialties(this.specialties) : [],
            simulatorAnswers: this.simulatorAnswers.map(item => item.toSend())
        }
    }

    updateData(data) {
        this.description = data;
    }

    mapSimulatorAnswers(answerEditors, answers) {
        let toDelete = _.filter(answers, (answer) => answer.updateState == 4)
        let toUpdate = _.filter(answers, (answer) => answer.updateState != 4)

        let mappedToUpdate = _.map(toUpdate, (answer, index) => {
            let answerEditor = _.find(answerEditors, {selector: `answer_editor_${index}`});
            answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
            return answer
        })

        return toDelete.concat(mappedToUpdate)
    }

    bindAnswers() {
        _.forEach(this.simulatorAnswers, (answer, index) => (answer.id) ? answer.updateState = this.UPDATE_STATUS.UPDATED : answer.updateState = this.UPDATE_STATUS.ADDED)
    }


    addAnswer() { 
        this.simulatorAnswers.push(new SimulatorAnswer({
            description: '',
            success: false,
            updateState: this.UPDATE_STATUS.ADDED
        }))
    }

    initDefaultAnswers() {
        return [
          new SimulatorAnswer({
            description: null,
            success: false,
            updateState: this.UPDATE_STATUS.ADDED,
          }),
          new SimulatorAnswer({
            description: null,
            success: false,
            updateState: this.UPDATE_STATUS.ADDED,
          }),
          new SimulatorAnswer({
            description: null,
            success: false,
            updateState: this.UPDATE_STATUS.ADDED,
          }),
        ];
      }


    removeAnswer(selectedIndex) {
        let answer = this.getAnswers()[selectedIndex]
        var index = _.findIndex(this.simulatorAnswers, answer)
        if (index != -1) {
            if (answer.id) {
                this.simulatorAnswers[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.simulatorAnswers.splice(index, 1);
            }
        } else {
            throw new Error("Pregunta no encontrada.")
        }
    }

    getAnswers() {
        return this.getFilteredAnswers(this.simulatorAnswers);
    }

    getFilteredAnswers(collection) {
        return _.filter(collection, (item) => item.updateState != this.UPDATE_STATUS.REMOVED)
    }

    mapSpecialties(specialties) {
        return specialties.filter(item => item != null).map((item) => { 
            return {
                id: item.id
            }
         })
    }

    
}