import BaseModel from "../../../infraestructure/BaseModel"
import CourseSubjectTestQuestionAnswer from "./CourseSubjectTestQuestionAnswer"
export default class CourseSubjectTestQuestion extends BaseModel {
    constructor(options, state) {
        super(options, state)
    }

    toSend() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            sort: this.sort || 0,
            duration: this.duration || 0,
            updateState: this.updateState,
            courseSubjectTestQuestionAnswers: this.courseSubjectTestQuestionAnswers.map((item) => item.toSend())
        }
    }

    setStateToUpdate() {
        this.updateState = (this.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
    }

    //#region ANSWERS

    setDefaultAnswers(numberOfDetail) {
        if (_.isNil(numberOfDetail) || !_.isNumber(numberOfDetail)) throw new Error("El valor debe ser numerico.")
        for (let index = 0; index < numberOfDetail; index++) {
            this.addAnswer(this.createAnswer())
        }
    }

    removeAllAnswers() {
        this.courseSubjectTestQuestionAnswers = []
    }

    getAnswers() {
        return _.sortBy(this.courseSubjectTestQuestionAnswers.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED), 'sort');
    }

    createAnswer() {
        return new CourseSubjectTestQuestionAnswer({
            title: '',
            description: '',
            success: false,
            sort: this.getNextAnswerIndex(),
            updateState: this.UPDATE_STATUS.ADDED,
            courseSubjects: []
        }, 'create')
    }

    editAnswer(data) {
        data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED;
        return new CourseSubjectTestQuestionAnswer(data, 'update');
    }

    addAnswer(data) {
        this.courseSubjectTestQuestionAnswers.push(new CourseSubjectTestQuestionAnswer(data, 'create'));
    }

    updateAnswer(selectedIndex, data) {
        let section = this.getAnswers()[selectedIndex]
        var index = _.findIndex(this.courseSubjectTestQuestionAnswers, section)
        if (index != -1) {
            this.courseSubjectTestQuestionAnswers[index] = data
        } else {
            throw new Error("Sección no encontrada.")
        }
    }

    updateAnswerPosition() {
        this.courseSubjectTestQuestionAnswers.filter((item) => item.updateState !== this.UPDATE_STATUS.REMOVED).forEach((item, index) => {
            item.sort = index + 1;
        });
    }

    removeAnswer(selectedIndex) {
        let answer = this.getAnswers()[selectedIndex]
        var index = _.findIndex(this.courseSubjectTestQuestionAnswers, answer)
        if (index != -1) {
            if (answer.id) {
                this.courseSubjectTestQuestionAnswers[index].updateState = this.UPDATE_STATUS.REMOVED;
            } else {
                this.courseSubjectTestQuestionAnswers.splice(index, 1);
            }
        } else {
            throw new Error("Respuesta no encontrada.")
        }
    }

    getNextAnswerIndex() {
        return _.size(this.getAnswers()) + 1;
    }

    getTotalAnswers() {
        return _.size(this.getAnswers())
    }

    //#endregion

}