import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent'
import CourseViewModel from '../../../../core/features/course/viewmodels/CourseViewModel'

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            courseCategories: [],
            universities: [],
            viewModel: null,
            permissions: null,
            modules: [],
            itemCourseSection: null,
            courseSectionSelectedIndex: null,
            coverImageFile: null,
            coverImageUrl: '/images/courses/add_img.jpg',
            isCoverImageTemp: false,
            editorAbout: null,
            instructorSelected: null,
            modalItemCourseSection: {
                isVisible: false,
                isLoading: false
            },
            dynamicTags: [],
            inputTagsVisible: false,
            inputTagsValue: '',
            instructors: [],
            currentUniversity: null,
            rules: {
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Titulo requerido.', trigger: 'blur' }
                ],
                courseCategory: [
                    { required: true, message: "Categoria requerido", trigger: "blur" }
                ],
                university: [
                    { required: true, message: "Universidad requerido.", trigger: "blur" },
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseViewModel(this)
        this.onActive()
    },
    beforeMount() {
        this.conf.isLoading = true
    },
    mounted() {
        EventBus.$on('onSubmitedCourseSection', this.onUpsertCourseSection);
        EventBus.$on('onCanceledCourseSection', this.onCanceledCourseSection);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSection', this.onUpsertCourseSection);
        EventBus.$off('onCanceledCourseSection', this.onCanceledCourseSection);
    },
    methods: {
        onActive() {
            setTimeout(() => {
                $('#add-course-tab').steps();
                this.viewModel.onBindEditor('.about-editor');
                this.viewModel.bindFormView();
            }, 300);
        },
        onSearchCourseCategory(data) {
            if (data) {
                this.viewModel.findCourseCategories(`Name=${data}`)
            } else {
                this.courseCategories = []
            }
        },
        onSearchCourseCategoriesResponse(response) {
            this.courseCategories = response;
        },
        onFocusCourseCategory() {
            this.$forceUpdate()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.conf.isLoading = true
                    if (_.isString(this.formData.university)) this.formData.university = this.currentUniversity;
                    this.formData.about = this.editorAbout.root.innerHTML;
                    this.formData.tags = this.dynamicTags.join(',');
                    this.onSave(this.formData)
                }
            });
        },
        onSaveResponse(response) {
            this.viewModel.onSaveFiles(response.id, this.formData.courseSections)
        },
        onSaveFileResponse() {
            this.showLoading(false, '');
            this.onShowSuccess("Registro actualizado");
            this.showComponent(false);
            EventBus.$emit('onSubmitedCourse', null);
        },
        onSaveError(error){
            this.conf.isLoading = false
            this.onError(error);
        },
        onCanceledCourseSection(){
            this.courseSectionSelectedIndex = null;
            this.modalItemCourseSection.isLoading = false;
            this.modalItemCourseSection.isVisible = false;
        },
        onCreateCourseSection() {
            if(this.formData.university) {
                this.itemCourseSection = this.formData.createCourseSection();
                this.itemCourseSection.universityId =  this.formData.university.id;
                this.modalItemCourseSection.isLoading = true;
                this.modalItemCourseSection.isVisible = true;
            } else {
                this.onShowWarning('Seleccione una universidad');
            }
        },
        onEditCourseSection(item) {
            this.itemCourseSection = this.formData.editCourseSection(item);
            this.itemCourseSection.universityId =  this.formData.university.id;
            this.modalItemCourseSection.isLoading = true;
            this.modalItemCourseSection.isVisible = true;
            // this.viewModel.showModal(this.modalItemCourseSection, true);
        },
        onDeleteCourseSection(index) {
            this.$confirm("¿Desea eliminar la sección seleccionada?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.conf.isLoading = true
                    this.courseSectionSelectedIndex = null
                    setTimeout(() => {
                        this.formData.removeCourseSection(index)
                        this.$forceUpdate()
                        this.formData.updateCourseSectionPosition()
                        this.conf.isLoading = false
                    }, 400);
                })
                .catch(() => { 
                    this.courseSectionSelectedIndex = null
                });
        },
        onUpsertCourseSection(item) {
            setTimeout(() => {
                (this.courseSectionSelectedIndex != null)
                    ? this.formData.updateCourseSection(this.courseSectionSelectedIndex, item)
                    : this.formData.addCourseSection(item)
                this.$forceUpdate()
                this.formData.updateCourseSectionPosition()
                this.courseSectionSelectedIndex = null;
                this.modalItemCourseSection.isLoading = false;
                this.modalItemCourseSection.isVisible = false;
            }, 400);
        },
        onChangeItemOptions(option) {
            this.courseSectionSelectedIndex = option.index
            if (option.command === 'edit') this.onEditCourseSection(option.item);
            if (option.command === 'delete') this.onDeleteCourseSection(option.index);
        },
        getSubjectActivitiesTotal(collection) {
            return _.size(collection)
        },
        getSubjectActivitiesDurationTotal(totalHours, collection) {
            return (totalHours) ? totalHours : this.viewModel.getTimeFromMins(_.sumBy(collection, (item) => item.duration));
        },
        onRemoveCoverImage(data) {
            this.coverImageUrl = '/images/courses/add_img.jpg'
            this.coverImageFile = null;
            this.isCoverImageTemp = false;
            this.formData.coverImageUrl = null;
        },
        onSuccessUploadCoverImage(data) {
            return false;
        },
        async onBeforeUploadCoverImage(file) {
            let dataUrl = await this.viewModel.convertFileToBase64(file)
            this.coverImageUrl = (dataUrl) ? dataUrl : '/images/courses/add_img.jpg'
            this.coverImageFile = file;
            this.isCoverImageTemp = true;
            /* console.log('on before', file)
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('La imagen debe estar en formato JPG!');
            }
            if (!isLt2M) {
            this.$message.error('La imagen excede los 2MB!');
            }
            return isJPG && isLt2M; */
            return true;
        },
        handleTagClose(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },
        showTagInput() {
            this.inputTagsVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleTagConfirm() {
            let tagValue = this.inputTagsValue;
            if (tagValue) {
                this.dynamicTags.push(tagValue);
            }
            this.inputTagsVisible = false;
            this.inputTagsValue = '';
            return false;
        },
        onFindUniversities(data) {
            this.universities = []
            if (data) this.viewModel.findUniversities(`Name=${data}`)
        },
        onFindUniversitiesResponse(response) {
            this.universities = response.data;
        },
        onChangeUniversity(university) {
            this.$set(this.formData, 'knowledgeArea', '')
            if(university != null) this.viewModel.findKnowledgeAreas(`UniversityId=${university.id}`)
        },
        onFindKnowledgeAreasResponse(response) {
            this.knowledgeAreas = response.data
        },
        onSearchInstructors(data) {
            this.instructors = []
            if (data) {
                this.viewModel.updateFilters(data, 'FullName', '=');
                this.viewModel.findInstructors()
            }
        },
        onSearchInstructorsResponse(response) {
            this.instructors = this.viewModel.mapInstructors(response);
        },
        onSelectInstructor(instructor) {
            this.formData.addInstructor(instructor);
            this.instructorSelected = null;
        },
        onRemoveInstructor(index) {
            this.$confirm("¿Desea eliminar el instructor seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
                cancelButtonClass: "btn-cancel",
                confirmButtonClass: "btn-primary",
            })
                .then(() => {
                    this.formData.removeInstructor(index);
                    this.$forceUpdate()
                })
                .catch(() => { });

        },
        mapInstructorImage(userProfile) {
            return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
        },
        onChangeCourseSectionDrag() {
            this.formData.updateCourseSectionPosition()
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanCreateCourseSection(permissions) {
            return this.canCreate(permissions) || this.canUpdate(permissions)
        },
        onCanReadCourseSection(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSection(permissions) {
            return this.canDelete(permissions);
        },
    }
}